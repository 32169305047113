import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { DocumentType, IRequest, IUser, RequestStatus, AssignmentType, IMailContent, IUserDefinedCode, IDocument } from "@/models";
import { LocalDatePicker, LoaderSpinner, UserDefinedCodeSelect } from "@/components";
import { validations } from "./validations";
import { NotificationService, RequestService } from "@/services";
import vSelect from "vue-select";

@Component({
    name: "development-document-request",
    components: { LocalDatePicker, LoaderSpinner, "vue-select": vSelect, UserDefinedCodeSelect },
    validations,
})
export default class DevelopmentDocumentRequest extends Vue {
    @Prop({ required: true, type: Object })
    public model: IRequest;

    @Prop({ required: true, default: false })
    public expanded: boolean;

    public vm: IRequest;
    public pendingAction: boolean;
    public loadingData: boolean;
    public executionDocumentUpdated: boolean;

    private promises: Promise<any>[];

    public constructor() {
        super();

        this.vm = this.createViewModel();
        this.pendingAction = false;
        this.loadingData = false;
        this.executionDocumentUpdated = false;

        this.promises = [];
    }

    public get user(): IUser {
        return this.$store.getters["app/currentUser"];
    }

    public get readonly(): boolean {
        const userConstrain: boolean = this.vm.requesterId !== this.user?.userId;
        const statusConstraint: boolean = this.vm.requestStatus >= RequestStatus.DevelopmentDocument;
        const assignmentConstraint: boolean = !this.user?.userAssignments?.some(a =>
            a.environmentCode === this.activeEnvironmentCode &&
            (a.assignmentType === AssignmentType.Supply || a.assignmentType === AssignmentType.Development)) === true;
        return userConstrain || statusConstraint || assignmentConstraint;
    }

    public get supplierNotified(): boolean{
        return this.vm.devSteps?.some(ds => ds.supplierReproNotificationEmailDate) === true;
    }

    public get canBeSubmit(): boolean {
        return !this.readonly;
    }

    public get stepNumber(): string {
        return !this.vm.hasMarketingExecDoc ? "3" : "-";
    }

    public get suppliers(): IUserDefinedCode[] {
        return this.$store.getters["jde/supplierCodes"];
    }

    public get availableSuppliers(): IUserDefinedCode[] {
        // Exclude suppliers which do not have an application account.
        const applicationSupplierCodes = this.supplierUsers
            .map(u => u.userAssignments.filter(a => a.assignmentType === AssignmentType.Supplier).map(a => a.roleCode))
            .reduce((x, y) => x.concat(y), [])
            .filter((v,i,s) => s.indexOf(v) === i);

        return (this.suppliers)?.filter(s => this.devStepsValidatedSupplierCodes.indexOf(s) < 0 &&
            applicationSupplierCodes.some(c => c?.trimEquals(s.code)))
            ?? [];
    }

    public get devStepsPendingSupplierCodes(): IUserDefinedCode[] {
        return this.vm.devSteps.filter(s => !s.supplierReproNotificationEmailDate).map(ds => ({
            code: ds.supplierCode,
            label: ds.supplierLabel,
        })) ?? [];
    }

    public get devStepsValidatedSupplierCodes(): IUserDefinedCode[] {
        return this.vm.devSteps.filter(s => s.supplierReproNotificationEmailDate).map(ds => ({
            code: ds.supplierCode,
            label: ds.supplierLabel,
        })) ?? [];
    }

    public get needRepro(): boolean {
        return this.vm.needContactRepro;
    }

    public get fileExtensions(): string {
        return RequestService.documentFileExtensions;
    }

    private get activeEnvironmentCode(): string {
        return this.$store.getters["app/activeEnvironment"]?.code;
    }

    private get supplierUsers(): IUser[] {
        return this.$store.getters["app/suppliers"];
    }

    public async updateExecutionDocumentation(event: any): Promise<void> {
        if (event.target.files.length > 0) {
            this.executionDocumentUpdated = true;
            this.vm.executionDocument = {
                documentId: null,
                documentType: DocumentType.Execution,
                requestId: this.vm.requestId,
                requestDevStepId: null,
                controlId: null,
                fileKey: null,
                fileName: event.target.files[0].name,
                fileUri: null,
                uploadDate: null,
                uploaderId: null,
                uploaderName: null,
                uploaderEmail: null,
                file: event.target.files[0],
                thumbnail: null,
            };
        }
    }

    public updateSuppliers(values: IUserDefinedCode[]) {
        for (const supplier of values) {
            if (!this.vm.devSteps.some(ds => ds.supplierCode.localeCompare(supplier.code) === 0 )) {
                const devStep = RequestService.createDevStep(this.vm.requestId, supplier.code, supplier.label);
                devStep.requestDevStepStatus = RequestStatus.DevelopmentDocumentPending;
                this.vm.devSteps.push(devStep);
            }
        }

        this.vm.devSteps = this.vm.devSteps.filter(ds => ds.supplierReproNotificationEmailDate || values.findIndex(v => v.code.trimEquals(ds.supplierCode)) >= 0);
        if(this.vm.devSteps?.length > 0 && !this.vm.devSteps.some(ds=>ds.defaultSupplier)) {
            this.vm.devSteps[0].defaultSupplier = true;
        }
    }

    public async submitRequest(): Promise<void> {
        this.pendingAction = true;
        if (this.validateBeforeSubmit()) {
            const currentStatus = this.vm.requestStatus;
            let document: IDocument;
            if (this.executionDocumentUpdated && !this.vm.hasMarketingExecDoc && this.vm.executionDocument) {
                document = await RequestService.uploadDocument(this.vm.executionDocument);
            }

            let response: IRequest = null;
            if (!this.executionDocumentUpdated || document) {
                this.vm.requestStatus = RequestStatus.DevelopmentDocument;
                response = await this.$store.dispatch("requests/createOrUpdateRequest", this.vm);
            }

            if (response) {
                this.$router.push("/");
            } else {
                this.vm.requestStatus = currentStatus;
            }

        } else {
            NotificationService.showError("Le formulaire contient des erreurs");
        }

        this.pendingAction = false;
    }

    // Hook.
    public async created(): Promise<void> {
        this.loadingData = true;
        await this.loadData();
        this.loadingData = false;
    }

    public getDevelopmentDocumentMailLabel(mailCulture: string) {
        if (this.needRepro) {
            return `Contenu du mail Repro (${mailCulture})`;
        } else {
            return `Contenu du mail (${mailCulture})`;
        }
    }

    private async loadData(): Promise<void> {
        if (!this.readonly) {
            if (!this.$store.state.app.usersLoaded) {
                this.promises.push(this.$store.dispatch("app/getUsers"));
            }

            if (this.$store.state.jde.suppliers?.length < 1) {
                this.promises.push(this.$store.dispatch("jde/getSuppliers", this.activeEnvironmentCode));
            }

            this.promises.push(
                this.setDevelopmentDocumentMailContents(),
                this.setTechnicalDocumentMailContents());
        }

        await Promise.all(this.promises);
    }

    private async setDevelopmentDocumentMailContents(): Promise<void> {
        if (!this.readonly) {
            const developmentDocumentRequestMailContent: IMailContent = await RequestService.getNotificationMailContents(this.vm, RequestStatus.DevelopmentDocumentPending);
            if (developmentDocumentRequestMailContent && !this.vm.developmentDocumentMailBodyFr) {
                this.vm.developmentDocumentMailBodyFr = developmentDocumentRequestMailContent.frBody;
            }

            if (developmentDocumentRequestMailContent && !this.vm.developmentDocumentMailBodyEn) {
                this.vm.developmentDocumentMailBodyEn = developmentDocumentRequestMailContent.enBody;
            }
        }
    }

    private async setTechnicalDocumentMailContents(): Promise<void> {
        if (!this.readonly && this.needRepro) {
            const technicalDocumentRequestMailContent: IMailContent = await RequestService.getNotificationMailContents(this.vm, RequestStatus.DevelopmentDocumentValidated);
            if (technicalDocumentRequestMailContent && !this.vm.technicalDocumentMailBodyFr) {
                this.vm.technicalDocumentMailBodyFr = technicalDocumentRequestMailContent.frBody;
            }

            if (technicalDocumentRequestMailContent && !this.vm.technicalDocumentMailBodyEn) {
                this.vm.technicalDocumentMailBodyEn = technicalDocumentRequestMailContent.enBody;
            }
        }
    }

    private createViewModel(): IRequest {
        return {
            ...this.model,
            devSteps: [...this.model.devSteps.map(ds => ({ ...ds }))],
        };
    }

    private validateBeforeSubmit(): boolean {
        this.$v.vm.$touch();
        return !this.$v.vm.$invalid;
    }
}
