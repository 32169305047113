import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { DocumentType, IUser, IRequest, RequestStatus, AssignmentType, IDocument, IWorkflowValidation, WorkflowValidationResponse } from "@/models";
import { LocalDatePicker, LoaderSpinner } from "@/components";
import { validations } from "./validations";
import { NotificationService, RequestService } from "@/services";

@Component({
    name: "execution-documentation-validation",
    components: { LocalDatePicker, LoaderSpinner },
    validations,
})
export default class ExecutionDocumentationValidation extends Vue {
    @Prop({ required: true, type: Object })
    public model: IRequest;

    @Prop({ required: true, default: false })
    public expanded: boolean;

    public vm: IRequest;
    public pendingAction: boolean;
    public workflowValidations: IWorkflowValidation[] = [];

    public constructor() {
        super();
        this.vm = this.createViewModel();
        this.pendingAction = false;
    }

    public async mounted() {
        this.workflowValidations = await RequestService.getWorkflowValidations(this.vm,
            [
                RequestStatus.ExecutionDocumentRequestRejected,
                RequestStatus.ExecutionDocumentValidation,
                RequestStatus.ExecutionDocumentValidated,
            ]);
    }

    public get user(): IUser {
        return this.$store.getters["app/currentUser"];
    }

    public get readonly(): boolean {
        const userConstraint: boolean = this.vm?.marketingManagerId !== this.user?.userId;
        const statusConstraint: boolean = this.vm?.requestStatus > RequestStatus.ExecutionDocumentRequestRejected;
        const assignmentConstraint: boolean = !this.user?.userAssignments?.some(a =>
            a.environmentCode === this.activeEnvironmentCode &&
            a.assignmentType === AssignmentType.MarketingManager) === true;
        return userConstraint || statusConstraint || assignmentConstraint;
    }

    public get canBeSubmit(): boolean {
        return !this.readonly;
    }

    public get fileExtensions(): string {
        return RequestService.documentFileExtensions;
    }

    private get activeEnvironmentCode(): string {
        return this.$store.getters["app/activeEnvironment"]?.code;
    }

    public isExeDocValidationRejected(validationStatus: string): boolean {
        return validationStatus === RequestStatus.ExecutionDocumentRequestRejected.toString(); // When a validator reject Middleway set validationStatus = Workflow.RejectedStatusId
    }

    public async updateExecutionDocumentation(event: any): Promise<void> {
        if (event.target.files.length > 0) {
            this.vm.executionDocument = {
                documentId: null,
                documentType: DocumentType.Execution,
                requestId: this.vm?.requestId,
                requestDevStepId: null,
                controlId: null,
                fileKey: null,
                fileName: event.target.files[0].name,
                fileUri: null,
                uploadDate: null,
                uploaderId: null,
                uploaderName: null,
                uploaderEmail: null,
                file: event.target.files[0],
                thumbnail: null,
            };

            this.$v.vm.executionDocument.$touch();
        }
    }

    public async submitRequest(): Promise<void> {
        this.pendingAction = true;
        if (this.validateBeforeSubmit()) {
            const currentStatus = this.vm.requestStatus;
            const document: IDocument = await RequestService.uploadDocument(this.vm.executionDocument);
            let response: IRequest = null;
            if (document) {
                this.vm.requestStatus = RequestStatus.ExecutionDocumentValidation;
                response = await this.$store.dispatch("requests/createOrUpdateRequest", this.vm);
            }

            if (response) {
                this.$router.push("/");
            } else {
                this.vm.requestStatus = currentStatus;
            }

        } else {
            NotificationService.showError("Le formulaire contient des erreurs");
        }

        this.pendingAction = false;
    }

    public getCommentClass(workflowValidationResponse: WorkflowValidationResponse): string {
        switch (workflowValidationResponse) {
            case WorkflowValidationResponse.Rejected:
                return "comment-title-rejected";
            case WorkflowValidationResponse.Reassigned:
                return "comment-title-reassigned";
            case WorkflowValidationResponse.Validated:
            default:
                return "comment-title-approved";
        }
    }

    private createViewModel(): IRequest {
        return {
            ...this.model,
            executionDocumentationFirstSubmissionDate: this.model?.executionDocumentationFirstSubmissionDate ?? new Date(),
        };
    }

    private validateBeforeSubmit(): boolean {
        this.$v.vm.$touch();
        return !this.$v.vm.$invalid;
    }
}
