import { GetterTree } from "vuex";
import { IAppState } from "./state";
import { IUser, AssignmentType, IRequestStatus, IEnvironment } from "@/models";

const getters: GetterTree<IAppState, any> = {
    activeEnvironment(state: IAppState): IEnvironment {
        return state.environments?.find(e => e.environmentId === state.activeEnvironmentId) ?? null;
    },

    isAuthenticated(state: IAppState): boolean {
        return !!state.userAccount;
    },

    currentUser(state: IAppState): IUser {
        const aadUniqueId: string = state.userAccount?.accountIdentifier;
        return state.users?.find(u => u.uniqueId === aadUniqueId);
    },

    developers(state: IAppState): IUser[] {
        return state.users?.filter(u => u.userAssignments.some(a =>
            a.assignmentType === AssignmentType.Development &&
            a.environmentId === state.activeEnvironmentId)) ?? [];
    },

    projectNames(state: IAppState): string[] {
        return state.projectNames;
    },

    requesters(state: IAppState): IUser[] {
        return state.users?.filter(u => u.userAssignments.some(a =>
            a.environmentId === state.activeEnvironmentId &&
            (a.assignmentType === AssignmentType.Supply || a.assignmentType === AssignmentType.Development))) ?? [];
    },

    suppliers(state: IAppState): IUser[] {
        return state.users?.filter(u => u.userAssignments.some(a =>
            a.environmentId === state.activeEnvironmentId &&
            (a.assignmentType === AssignmentType.Supplier))) ?? [];
    },

    marketingManagers(state: IAppState): IUser[] {
        return state.users?.filter(u => u.userAssignments.some(a =>
            a.assignmentType === AssignmentType.MarketingManager &&
            a.environmentId === state.activeEnvironmentId)) ?? [];
    },

    archivedStatus(state: IAppState): IRequestStatus[] {
        return state.requestStatus?.filter(rs => rs.requestStatusId >= 190) ?? [];
    },

    homeStatus(state: IAppState): IRequestStatus[] {
        return state.requestStatus?.filter(rs => rs.requestStatusId < 190) ?? [];
    },
};

export { getters };
