import Vue from "vue";
import { Component } from "vue-property-decorator";
import * as icons from "@/components/icons";
import { IBasicDocument } from "@/models";
import { LoaderSpinner } from "@/components";

@Component({
    name: "documentation-view",
    components: {
        "doc-icon": icons.DocIcon,
        "download-icon": icons.DownloadIcon,
        LoaderSpinner,
    },
})
export default class DocumentationView extends Vue {
    public loadingData: boolean;

    public constructor() {
        super();

        this.loadingData = false;
    }

    public get documentations(): IBasicDocument[] {
        return this.$store.state.app.documentations;
    }

    // Hook.
    public async created(): Promise<void> {
        this.loadingData = true;
        await this.loadData();
        this.loadingData = false;
    }

    private async loadData(): Promise<void> {
        const promises: Promise<any>[] = [];

        if (this.$store.state.app.documentations?.length < 1) {
            promises.push(this.$store.dispatch("app/getDocumentations"));
        }

        await Promise.all(promises);
    }
}
