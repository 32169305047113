import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { LocalDatePicker, LoaderSpinner, UserDefinedCodeSelect } from "@/components";
import { RequestDevStepService, NotificationService } from "@/services";
import { IRequestDevStepExternal, RequestStatus, IUserAssignment, AssignmentType, DocumentType, IDocument } from "@/models";
import { validations } from "./validations";

@Component({
    name: "upload-technical-document",
    components: { LocalDatePicker, LoaderSpinner, UserDefinedCodeSelect },
    validations,
})
export default class UploadTechnicalDocument extends Vue {
    @Prop(Number)
    public requestId: number;

    @Prop(Number)
    public requestDevStepId: number;

    public vm: IRequestDevStepExternal;
    public loadingData: boolean;
    public pendingAction: boolean;

    public constructor() {
        super();

        this.vm = null;
        this.loadingData = false;
        this.pendingAction = false;
    }

    public get readonly(): boolean {
        const requestStatusConstraint: boolean = this.vm?.requestStatus < RequestStatus.DevelopmentDocument || this.vm?.requestStatus === RequestStatus.Canceled;
        const requestDevStepStatusConstraint: boolean = this.vm?.requestDevStepStatus !== RequestStatus.DevelopmentDocumentValidated;
        const supplierConstraint: boolean = this.userNotBelongToSupplier(this.vm?.supplierCode);
        const assignmentConstraint: boolean = this.userIsNot(AssignmentType.Supplier);
        return !this.vm?.needContactRepro || requestStatusConstraint || requestDevStepStatusConstraint || supplierConstraint || assignmentConstraint;
    }

    public get quality(): string {
        return this.getDisplayText(this.vm.qualityCode, this.vm.qualityLabel);
    }

    public get bottling(): string {
        return this.getDisplayText(this.vm.bottlingCode, this.vm.bottlingLabel);
    }

    public get packaging(): string {
        return this.getDisplayText(this.vm.packagingCode, this.vm.packagingLabel);
    }

    public get fileExtensions(): string {
        return RequestDevStepService.fileExtensions;
    }

    private get activeEnvironmentCode(): string {
        return this.$store.getters["app/activeEnvironment"]?.code;
    }

    public async updateTechnicalDocument(event: any): Promise<void> {
        if (event.target.files.length > 0) {
            if (!this.vm.technicalDocuments) {
                this.vm.technicalDocuments = [];
            }

            this.vm.technicalDocuments.push({
                documentId: null,
                documentType: DocumentType.Technical,
                requestId: this.vm?.requestId,
                requestDevStepId: this.vm?.requestDevStepId,
                controlId: null,
                fileKey: null,
                fileName: event.target.files[0].name,
                fileUri: null,
                uploadDate: null,
                uploaderId: null,
                uploaderName: null,
                uploaderEmail: null,
                file: event.target.files[0],
                thumbnail: null,
            });

            this.$v.vm.technicalDocuments.$touch();
        }
    }

    public async submitRequestDevStep(): Promise<void> {
        this.pendingAction = true;
        if (this.validateBeforeSubmit()) {
            const currentStatus = this.vm.requestDevStepStatus;

            let documentUploaded = true;
            for(const technicalDocument of this.vm.technicalDocuments){
                documentUploaded = documentUploaded && !!await RequestDevStepService.uploadDocument(technicalDocument);
            }

            if (documentUploaded) {
                this.vm.requestDevStepStatus = RequestStatus.TechnicalDocumentValidation;
                const response = await RequestDevStepService.updateExternalRequestDevStep(this.vm);

                // Do not redirect user. He should only have access to this page.
                if (response) {
                    NotificationService.showSuccess("Document uploaded");
                    this.vm = response;
                } else {
                    this.vm.requestDevStepStatus = currentStatus;
                }
            } else {
                NotificationService.showError("Could not upload documents");
            }
        } else {
            NotificationService.showError("Form contains errors, please review then try again");
        }

        this.pendingAction = false;
    }

    // Hook.
    public async created(): Promise<void> {
        this.loadingData = true;
        await this.getRequestDevStep();
        this.loadingData = false;
    }

    private async getRequestDevStep(): Promise<void> {
        this.vm = await RequestDevStepService.getSingleExternalRequestDevStep(this.requestId, this.requestDevStepId);
        if (this.vm?.environmentId &&
            this.vm.environmentId !== this.$store.getters["app/activeEnvironment"]?.environmentId) {
            await this.$store.dispatch("app/setActiveEnvironmentId", this.vm?.environmentId);
        }

        if (this.vm && this.vm.requestStatus >= RequestStatus.DevelopmentDocument ) {
            this.vm.developmentDocument = null;
            if (this.vm.requestDevStepStatus < RequestStatus.TechnicalDocumentValidation) {
                this.vm.technicalDocuments = [];
            }
        } else {
            this.$router.push({name: "not-found"});
        }
    }

    private userIsNot(value: AssignmentType): boolean{
        return !this.$store.getters["app/currentUser"]?.userAssignments?.some((a: IUserAssignment) =>
            a.environmentCode === this.activeEnvironmentCode &&
            a.assignmentType === value);
    }

    private userNotBelongToSupplier(supplierCode: string): boolean{
        if (supplierCode) {
            return !this.$store.getters["app/currentUser"]?.userAssignments?.some((a: IUserAssignment) =>
                a.environmentCode === this.activeEnvironmentCode &&
                a.assignmentType === AssignmentType.Supplier && a.roleCode?.trimEquals(supplierCode) === true);
        } else {
            return true;
        }
    }

    private validateBeforeSubmit(): boolean {
        this.$v.vm.$touch();
        return !this.$v.vm.$invalid;
    }

    private getDisplayText(code: string, label: string): string {
        return `${code?.trim() ?? ""} - ${label?.trim() ?? ""}`;
    }
}
