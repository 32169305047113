import { Component, Prop, Vue } from "vue-property-decorator";
import { IUser, AssignmentType, IEnvironment } from "@/models";
import { I18nService } from "@/services";
import vSelect from "vue-select";

@Component({
    name: "header-app",
    components: {
        "vue-select": vSelect,
    },
})
export default class HeaderApp extends Vue {
    @Prop({ required: true })
    public user: IUser;
    public loadingData: boolean;

    private i18nService: I18nService;

    public constructor() {
        super();

        this.loadingData = false;
        const lang = this.user?.userAssignments.some(a=>a.assignmentType === AssignmentType.Repro || a.assignmentType === AssignmentType.Supplier) ? "en" : "fr";
        this.i18nService = new I18nService(lang);
    }

    public get newRequestEnabled(): boolean {
        return this.user?.userAssignments.some(a => (a.assignmentType === AssignmentType.Supply || a.assignmentType === AssignmentType.Development) &&
            a.environmentId === this.activeEnvironment?.environmentId);
    }

    public get manageEnabled(): boolean {
        return this.user?.userAssignments.some(a => a.assignmentType === AssignmentType.Administrator
            && a.environmentId === this.activeEnvironment?.environmentId);
    }

    public get userIsSupplierOrRepro(): boolean{
        return this.user?.userAssignments.some(a => (a.assignmentType === AssignmentType.Repro || a.assignmentType === AssignmentType.Supplier) &&
            a.environmentId === this.activeEnvironment?.environmentId);
    }

    public get userName(): string {
        return this.user?.name ?? this.$store.state.app.userAccount?.name;
    }

    public get environments(): IEnvironment[] {
        return this.$store.state.app.environments;
    }

    public get activeEnvironment(): IEnvironment {
        return this.$store.getters["app/activeEnvironment"];
    }

    public set activeEnvironment(environment: IEnvironment) {
        this.$store.dispatch("app/setActiveEnvironmentId", environment?.environmentId);
    }

    public get version(): string {
        return process.env.VUE_APP_VERSION;
    }

    public get reportingUrl(): string {
        return process.env.VUE_APP_REPORTING_URL;
    }

    public get isIEBrowser(): boolean {
        return !!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g) || !!navigator.userAgent.match(/Edge/g);
    }

    public get welcomeText(): string {
        return this.i18nService.message.welcome;
    }

    public get logoutText(): string {
        return this.i18nService.message.logout;
    }

    public get browserWarningText(): string {
        return this.i18nService.message.browserWarning;
    }

    public async created(): Promise<void> {
        this.loadingData = true;
        await this.loadData();
        this.loadingData = false;
    }

    public logout(): void {
        this.$store.dispatch("app/logout");
    }

    public updateEnvironment(): void {
        this.$store.dispatch("jde/clearState");
        this.$store.dispatch("requests/clearState");
        this.$store.state.app.mailContents = [];
        if (this.$route.name !== "home") {
            this.$router.push("/");
        }
    }

    private async loadData(): Promise<void> {
        const promises: Promise<any>[] = [];
        if (this.user && this.$store.state.app.environments?.length < 2) {
            promises.push(this.$store.dispatch("app/getEnvironments"));
        }

        await Promise.all(promises);
    }
}
