/* eslint-disable id-blacklist */
interface String {
    trimEquals(value: string): boolean;
    trimContains(value: string): boolean;
}

String.prototype.trimEquals = function(value: string): boolean {
    return this.trim().localeCompare(value?.trim()) === 0;
};

String.prototype.trimContains = function(value: string): boolean {
    return this.trim().toLowerCase().indexOf(value?.trim().toLowerCase() ?? "") > -1;
};
