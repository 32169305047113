import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import App from "./App.vue";
import { router } from "@/router";
import { store } from "@/store";
import Toasted from "vue-toasted";
import Vuelidate from "vuelidate";
import VuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";
import VueMoment from "vue-moment";

import Moment from "moment";
import "moment/locale/fr";

import VModal from "vue-js-modal";
import VTooltip from "v-tooltip";
import { dateToStringFilter } from "@/filters";
import { NotificationService } from "./services";
import "./extensions/string.extensions";
import "./extensions/array.extensions";

Vue.config.productionTip = false;

const axiosWithInterceptors = axios.create();
axiosWithInterceptors.interceptors.response.use(
    r => r,
    e => {
        if (e.response?.data) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            let code = "";
            if (e.response.data.error) {
                code += " (" + e.response.data.error + ")";
            } else if (e.response.data.statusCode) {
                code += " (" + e.response.data.statusCode + ")";
            }

            const message = e.response.data.message ?? e.response.data.title ?? e.message;
            NotificationService.showError("Error: " + message + code);
        } else if (e.request?.status > 0) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            const text: string = e.request.statusText ?? e.request.title;
            NotificationService.showError("Error: " + text + " (" + e.request.status + ")");
        } else {
            // Do no use notification.
        }
        return Promise.reject(e);
    }
);

Moment.locale("fr");
Vue.use(VueAxios, axiosWithInterceptors);
Vue.use(Toasted, { router });
Vue.use(Vuelidate);
Vue.use(VuelidateErrorExtractor, {
    template: templates.singleErrorExtractor.foundation6,
    messages: {
        required: "{attribute} est requis",
        minValue: "La valeur de {attribute} doit être au moins {min}",
        maxValue: "La valeur de {attribute} doit être inférieure à {max}",
        minLength: "{attribute} doit faire au moins {min} caractères",
        maxLength: "{attribute} doit faire moins de {max} caractères",
        minArrayLength: "{attribute} doit avoir au moins {min} éléments",
        maxArrayLength: "{attribute} doit faire moins de {max} éléments",
        email: "Email invalide",
        integer: "{attribute} doit être un entier",
        singleDefault: "Il ne peut n'y avoir qu'un seul {attribute} de référence",
        decimal: "{attribute} doit être un nombre réel",
        unique: "{attribute} doit être unique",
        greaterThanZero: "La valeur de {attribute} doit être supérieure à 0",
        fileSizeValidation: "{attribute} ne doit pas excéder 15Mo",
        fileNameInvalidCharacters: "{attribute} contains invalid characters. \" * : < > ? / \\ are not allowed.",
        beforeThanControlDate: "La {attribute} doit être antérieure à la Date du contrôle"
    },
});

Vue.use(VueMoment, {moment: Moment});
Vue.use(VModal, { dialog: true });
Vue.use(VTooltip);

Vue.filter("dateToString", dateToStringFilter);

Vue.component("form-wrapper", templates.FormWrapper);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount("#app");
