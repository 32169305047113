import { MutationTree } from "vuex";
import {
    GET_ASSIGNMENT_TYPES,
    GET_DOCUMENTATIONS,
    GET_ENVIRONMENTS,
    GET_ITEM_ORIGINS,
    GET_GL_KEYS,
    GET_MAIL_CONTENTS,
    GET_PROJECT_NAMES,
    GET_REQUEST_STATUS,
    GET_SINGLE_USER,
    GET_USERS,
    LOGOUT,
    SET_ACTIVE_ENVIRONMENT_ID,
} from "./mutations-types";
import { IAppState } from "./state";
import { IUser, IItemOrigin, IMailContent, IAssignmentType, IRequestStatus, IEnvironment, IBasicDocument } from "@/models";

export const mutations: MutationTree<IAppState> = {
    [GET_ASSIGNMENT_TYPES](state, payload: IAssignmentType[]): void {
        state.assignmentTypes = payload;
    },

    [GET_DOCUMENTATIONS](state, payload: IBasicDocument[]): void {
        state.documentations = payload;
    },

    [GET_ENVIRONMENTS](state, payload: IEnvironment[]): void {
        if (payload) {
            if (!state.environments) {
                state.environments = [];
            }

            for(const environment of payload)
            {
                const index: number = state.environments.findIndex(e => e.environmentId === environment.environmentId);
                if (index < 0) {
                    state.environments.push(environment);
                } else {
                    state.environments[index] = environment;
                }
            }
        }

        state.environments = payload;
    },

    [GET_ITEM_ORIGINS](state, payload: IItemOrigin[]): void {
        state.itemOrigins = payload;
    },

    [GET_MAIL_CONTENTS](state, payload: IMailContent[]): void {
        state.mailContents = payload;
    },

    [GET_PROJECT_NAMES](state, payload: string[]): void {
        state.projectNames = payload;
    },

    [GET_REQUEST_STATUS](state, payload: IRequestStatus[]): void {
        state.requestStatus = payload;
    },

    [GET_SINGLE_USER](state, payload: IUser): void {
        if (payload && !state.users.some(u => u.uniqueId === payload.uniqueId)) {
            state.users.push(payload);
        }
    },

    [GET_USERS](state, payload: IUser[]): void {
        state.users = payload.sortByProperty(x => x.name);
        state.usersLoaded = true;
    },

    [LOGOUT](state): void {
        state.userAccount = null;
    },

    [SET_ACTIVE_ENVIRONMENT_ID](state, environmentId: number): void {
        state.activeEnvironmentId = environmentId;
    },
};
