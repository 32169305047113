import Vue from "vue";
import { ActionTree } from "vuex";
import * as Msal from "msal";
import { IAppState } from "./state";
import {
    GET_ASSIGNMENT_TYPES,
    GET_DOCUMENTATIONS,
    GET_ENVIRONMENTS,
    GET_GL_KEYS,
    GET_ITEM_ORIGINS,
    GET_MAIL_CONTENTS,
    GET_PROJECT_NAMES,
    GET_REQUEST_STATUS,
    GET_SINGLE_USER,
    GET_USERS,
    LOGOUT,
    SET_ACTIVE_ENVIRONMENT_ID,
} from "./mutations-types";
import { authService, AxiosService } from "@/services";
import { IUser, IItemOrigin, IMailContent, IAssignmentType, IRequestStatus, IEnvironment, IActionEnvironmentParameter, IBasicDocument } from "@/models";
import { AxiosRequestConfig } from "axios";

export const actions: ActionTree<IAppState, any> = {
    async setActiveEnvironmentId(context, environmentId): Promise<void> {
        context.commit(SET_ACTIVE_ENVIRONMENT_ID, environmentId);
    },

    async getSingleUser(context): Promise<void> {
        let user: IUser = null;

        // Exceptions are caught by the AuthGuard.
        try {
            context.state.appReady = false;
            const userAccount: Msal.Account = context.state.userAccount;
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get(`${process.env.VUE_APP_WEB_API_BASE_URL}/users/${userAccount?.accountIdentifier}`, config);
            user = response.data;

            context.commit(GET_ENVIRONMENTS, [{
                environmentId: user.defaultEnvironmentId,
                code: user.defaultEnvironmentCode,
                label: user.defaultEnvironmentDisplayName,
            }]);

            context.commit(SET_ACTIVE_ENVIRONMENT_ID, user.defaultEnvironmentId);
        } finally {
            context.state.appReady = true;
            context.commit(GET_SINGLE_USER, user);
        }
    },

    async getUsers(context): Promise<void> {
        let users: IUser[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IUser[]>(`${process.env.VUE_APP_WEB_API_BASE_URL}/users/`, config);
            users = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_USERS, users);
        }
    },

    async createOrUpdateUser(context, user: IUser): Promise<IUser> {
        let response: IUser = null;
        try {
            const url = `${process.env.VUE_APP_WEB_API_BASE_URL}/users/`;
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            response = await Vue.axios.put(url, user, config);
            return response;
        } catch {
            return response;
        } finally {
            context.dispatch("getUsers");
        }
    },


    async deleteUser(context, user: IUser): Promise<IUser> {
        let response: IUser = null;
        try {
            const url = `${process.env.VUE_APP_WEB_API_BASE_URL}/users/${user?.uniqueId}`;
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            response = await Vue.axios.delete(url, config);
            return response;
        } catch {
            return response;
        } finally {
            context.dispatch("getUsers");
        }
    },


    async getDocumentations(context): Promise<void> {
        let documentations: IBasicDocument[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IBasicDocument[]>(`${process.env.VUE_APP_WEB_API_BASE_URL}/documentations/`, config);
            documentations = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_DOCUMENTATIONS, documentations);
        }
    },

    async getEnvironments(context): Promise<void> {
        let environments: IEnvironment[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IEnvironment[]>(`${process.env.VUE_APP_WEB_API_BASE_URL}/environments/`, config);
            environments = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_ENVIRONMENTS, environments);
        }
    },

    async getItemOrigins(context): Promise<void> {
        let itemOrigins: IItemOrigin[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IItemOrigin[]>(`${process.env.VUE_APP_WEB_API_BASE_URL}/ItemOrigins/`, config);
            itemOrigins = response.data.sortByProperty(x => x.label);
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_ITEM_ORIGINS, itemOrigins);
        }
    },

    async getAssignmentTypes(context): Promise<void> {
        let assignmentTypes: IAssignmentType[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IAssignmentType[]>(`${process.env.VUE_APP_WEB_API_BASE_URL}/assignmentTypes/`, config);
            assignmentTypes = response.data.sortByProperty(x => x.label);
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_ASSIGNMENT_TYPES, assignmentTypes);
        }
    },

    async getMailContents(context, environmentCode: string): Promise<void> {
        let mailContents: IMailContent[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IMailContent[]>(
                `${process.env.VUE_APP_WEB_API_BASE_URL}/mailContents${getQuery(["environment", environmentCode])}`,
                config);
            mailContents = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_MAIL_CONTENTS, mailContents);
        }
    },

    async updateMailContent(context, parameter: IActionEnvironmentParameter<IMailContent>): Promise<IMailContent> {
        let response: IMailContent = null;
        try {
            const url = `${process.env.VUE_APP_WEB_API_BASE_URL}/mailContents/${parameter.value?.mailContentId}`;
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            response = await Vue.axios.put(url, parameter.value, config);
            return response;
        } catch {
            return response;
        } finally {
            context.dispatch("getMailContents", parameter.environmentCode);
        }
    },

    async getProjectNames(context): Promise<void> {
        let projectNames: string[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<string[]>(`${process.env.VUE_APP_WEB_API_BASE_URL}/Requests/ProjectNames`, config);
            projectNames = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_PROJECT_NAMES, projectNames);
        }
    },

    async getRequestStatus(context): Promise<void> {
        let status: IRequestStatus[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IRequestStatus[]>(`${process.env.VUE_APP_WEB_API_BASE_URL}/RequestStatuses/`, config);
            status = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_REQUEST_STATUS, status);
        }
    },

    async login(context): Promise<void> {
        if (!authService.getAccount()) {
            authService.login();
        }
    },

    async logout(context): Promise<void> {
        authService.logout();
        context.commit(LOGOUT);
    },
};

const getQuery = (...params: [string, string][]): string => {
    const parameters: [string, string][] = [];
    parameters.push(...params);
    return AxiosService.buildQueryParameters(parameters);
};
