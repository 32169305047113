import { Module } from "vuex";
import { IJdeState, getInitialState } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const jde: Module<IJdeState, any> = {
    namespaced: true,
    state: getInitialState(),
    getters,
    mutations,
    actions,
};

export { jde };
