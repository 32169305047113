import { RuleDecl } from "vue/types/options";
import { required, maxLength, minLength, requiredIf } from "vuelidate/lib/validators";
import { IRequest } from "@/models";

export const validations: RuleDecl = {
    vm: {
        developmentDocumentMailBodyFr: { required, maxLength: maxLength(3000) },
        developmentDocumentMailBodyEn: { required, maxLength: maxLength(3000) },
        technicalDocumentMailBodyFr: { required: requiredIf(hasRepro), maxLength: maxLength(3000) },
        technicalDocumentMailBodyEn: { required: requiredIf(hasRepro), maxLength: maxLength(3000) },
        devSteps: { required, minLength: minLength(1) }
    },
};

function hasRepro(value: IRequest): boolean {
    return value?.needContactRepro ?? false;
}
