import { IRequest, RequestStatus } from "@/models";

interface IRequestsState {
    amalgamCode: string;
    amalgamLabel: string;
    activeRequestId: number;
    requestsLoaded: boolean;
    requests: IRequest[];
    limit: number;
    page: number;
    projectName: string;
    requesterId: number;
    totalPages: number;
    archives: boolean;
    itemCode: string;
    requestStatus: RequestStatus;
    brandCode: string;
    marketingManagerId: number;
    qualityCode: string;
    bottlingCode: string;
    packagingCode: string;
}

const getInitialState = (): IRequestsState => ({
    amalgamCode: null,
    amalgamLabel: null,
    activeRequestId: null,
    requestsLoaded: false,
    requests: [],
    limit: 20,
    page: 1,
    projectName: null,
    requesterId: null,
    totalPages: 0,
    archives: false,
    itemCode: null,
    requestStatus: null,
    brandCode: null,
    marketingManagerId: null,
    qualityCode: null,
    bottlingCode: null,
    packagingCode: null
});

export { IRequestsState, getInitialState };
