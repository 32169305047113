export enum PurchaseItemType {
    Buyers = "buyers",
    CapsuleLengths = "capsuleLengths",
    CapsuleQualities = "capsuleQualities",
    CapsuleTypes = "capsuleTypes",
    CartonExternalBoards = "cartonExternalBoards",
    CartonPrintings = "cartonPrintings",
    CartonTypes = "cartonTypes",
    Certifications = "certifications",
    ClosureSizes = "closureSizes",
    DecorationTypes = "decorationTypes",
    GlassHues = "glassHues",
    LabelTypes = "labelTypes",
    LevelPremiumness  = "levelPremiumness",
    Materials = "materials",
    PvcFree = "pvcFree",
    Sourcings = "sourcings",
}
