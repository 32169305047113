import { RuleDecl } from "vue/types/options";
import { required, minLength, email, minValue, requiredIf } from "vuelidate/lib/validators";
import { IUserAssignment, AssignmentType } from "@/models";

export const validations: RuleDecl = {
    vm: {
        required,
        minLength: minLength(1),
        $each: {
            name: { required, minLength: minLength(1) },
            email: { required, minLength: minLength(1), email },
            defaultEnvironmentId: { required, minValue: minValue(1) },
            userAssignments: {
                $each: {
                    assignmentType: { required },
                    backUpUserId: { minValue: minValue(1) },
                    roleCode: { required: requiredIf(codeIsRequired) },
                    notifiedByFlow: { required },
                    environmentId: { required, minValue: minValue(1) },
                }
            }
        },
    },
};

function codeIsRequired(value: IUserAssignment): boolean {
    return value.assignmentType === AssignmentType.Supplier;
}
