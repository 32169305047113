import { RuleDecl } from "vue/types/options";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export const validations: RuleDecl = {
    vm: {
        required,

        $each: {
            frSubject: { maxLength: maxLength(300) },
            frBody: { maxLength: maxLength(3000) },
            enSubject: { maxLength: maxLength(300) },
            enBody: { maxLength: maxLength(3000) },
        },
    },
};
