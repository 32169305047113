import { RuleDecl } from "vue/types/options";
import { required, requiredIf, maxLength, minLength, decimal, integer, minValue, maxValue, helpers } from "vuelidate/lib/validators";
import { ICreationRequestViewModel } from "./ICreationRequestViewModel";
import { IRequestDevStep, ItemOrigin, RequestStatus } from "@/models";
import { Environments, jdeCategories } from "@/constants";
import CreationRequest from "./CreationRequest";

export const validations: RuleDecl = {
    vm: {
        requestDate: { required },
        requesterId: { required },
        developerViewModel: { required, userId: { required } },
        brandCode: { required: isNotNull },
        brandLabel: {},
        buyerCode: { required: isNotNull },
        buyerLabel: { },
        projectName: { required, maxLength: maxLength(300) },
        projectCode: { maxLength: maxLength(300) },
        itemOriginViewModel: { required, itemOriginId: { required } },
        needMarketValidation: { required },
        needContactRepro: { required },
        numberOfUnitsOnPalletReceipted: { integer, minValue: minValue(0), maxValue: maxValue(9999999) },
        numberOfUnitsPerPack: { integer, minValue: minValue(0), maxValue: maxValue(9999999) },
        itemMasterCode: {},
        isNewItemMaster: { required },
        itemCodeUpdate: { maxLength: maxLength(50) },
        oldItemDeadline: {},
        hasMarketingExecDoc: { required },
        marketingExecDocNotificationEmailDate: {},
        marketingManagerViewModel: { required: requiredIf(hasMarketingExecDoc) },
        marketingInfoText: { maxLength: maxLength(300) },
        requestDescription: { required, maxLength: maxLength(300) },
        qualityCode: { required: isNotNull, maxLength: maxLength(50) },
        qualityLabel: {},
        bottlingCode: { required: isNotNull, maxLength: maxLength(50) },
        bottlingLabel: {},
        vintageCode: { maxLength: maxLength(50) },
        vintageLabel: {},
        packagingCode: { required: requiredIf(packagingRequired), maxLength: maxLength(50) },
        packagingLabel: {},
        classificationLevel2Code: { required: isNotNull, maxLength: maxLength(50) },
        classificationLevel2Label: {},
        classificationLevel3Code: { required: isNotNull, maxLength: maxLength(50) },
        classificationLevel3Label: {},
        glassHueCode: { required: requiredIf(isGlassClassificationLevel3), maxLength: maxLength(50) },
        glassHueLabel: { },
        glassSize: { required: requiredIf(isGlassClassificationLevel3), decimal, minValue: minValue(0), maxValue: maxValue(9999999) },
        purchasingFamilyCode: { required: isNotNull },
        purchasingFamilyLabel: { },
        purchasingSubFamilyCode: { required: isNotNull },
        purchasingSubFamilyLabel: { },
        originCountryCode: { },
        originCountryLabel: { },
        devSteps: {
            minLength: minLength(1),
            singleDefault: (values: IRequestDevStep[]) => values?.filter(v=> v.defaultSupplier).length <= 1 ?? true,
            $each: {
                supplierCode: { required: isNotNull },
            },
        },
        marketValidators: {
            required: requiredIf(hasNeedMarketValidation),
            $each: { required: isNotNull },
        },
        capLengthCode: { required: requiredIf(isCreatedAndCapsuleClassificationLevel3), maxLength: maxLength(50) },
        capLengthLabel: {},
        capQualityCode: { required: requiredIf(isCreatedAndCapsuleClassification), maxLength: maxLength(50) },
        capQualityLabel: { },
        capTypeCode: { required: requiredIf(isCreatedAndCapsuleClassificationLevel3), maxLength: maxLength(50) },
        capTypeLabel: {},
        certificationCode: { required: requiredIf(isCreatedAndCertificationClassificationLevel3), maxLength: maxLength(50) },
        certificationLabel: {},
        cartonExternalBoardCode: { required: requiredIf(isCreatedAndCartonItemClassificationLevel3), maxLength: maxLength(50) },
        cartonExternalBoardLabel: { },
        cartonPrintingCode: { required: requiredIf(isCreatedAndCartonItemClassificationLevel3), maxLength: maxLength(50) },
        cartonPrintingLabel: { },
        cartonTypeCode: { required: requiredIf(isCreatedAndCartonItemClassificationLevel3), maxLength: maxLength(50) },
        cartonTypeLabel: { },
        cartonWeight: { required: requiredIf(isCreatedAndCartonItemClassificationLevel3), integer, minValue: minValue(1), maxValue: maxValue(9999999) },
        closureSizeCode: { required: requiredIf(isCreatedAndClassificationLevel3ClosureSize), maxLength: maxLength(50) },
        closureSizeLabel: { },
        co2EmissionFactor: { integer, minValue: minValue(0), maxValue: maxValue(9999999) },
        grossWeightCU: { required: requiredIf(needPalletizationPlan), decimal, minValue: minValue(0), maxValue: maxValue(9999999) },
        height: { required: requiredIf(isDevelopmentValidated), decimal, minValue: minValue(0), greaterThanZero: greaterThanZeroWhenCreated, maxValue: maxValue(9999999) },
        heightCU: { required: requiredIf(needPalletizationPlan), decimal, minValue: minValue(0), maxValue: maxValue(9999999) },
        length: { required: requiredIf(isDevelopmentValidated), decimal, minValue: minValue(0), greaterThanZero: greaterThanZeroWhenCreated, maxValue: maxValue(9999999) },
        lengthCU: { required: requiredIf(needPalletizationPlan), decimal, minValue: minValue(0), maxValue: maxValue(9999999) },
        levelPremiumnessCode: { required: requiredIf(isCreatedAndLevelPremiumnessClassificationLevel3), maxLength: maxLength(50) },
        levelPremiumnessLabel: {},
        mainMeasureUnitCode: { required: requiredIf(isCognacEnvironment) },
        mainMeasureUnitLabel: { },
        materialCode: { required: requiredIf(isCreatedAndMaterialClassificationLevel3), maxLength: maxLength(50) },
        materialLabel: {},
        needPalletizationPlan: { required: requiredIf(isCognacEnvironment) },
        needTechnicalDocuments: { required },
        purchaseMeasureUnitCode: { required: requiredIf(isCognacEnvironment) },
        purchaseMeasureUnitLabel: { },
        recycled: { required: requiredIf(isCreatedAndRecycledClassificationLevel3), maxLength: maxLength(50),
            regex: helpers.regex("digitsWithThreeDecimals", /^(?:0|[1-9]\d{0,45})(,\d{1,3})?$/) },
        skuCapacity: { decimal, minValue: minValue(0), maxValue: maxValue(9999999) },
        skuPcb: { integer, minValue: minValue(0), maxValue: maxValue(9999999) },
        sourcingCode: { required: requiredIf(isCreatedAndSourcingClassificationLevel3), maxLength: maxLength(50) },
        sourcingLabel: {},
        weight: { required: requiredIf(isDevelopmentValidated), decimal, minValue: minValue(0), greaterThanZero: greaterThanZeroWhenCreated, maxValue: maxValue(9999999) },
        weightCU: { required: requiredIf(needPalletizationPlan), decimal, minValue: minValue(0), maxValue: maxValue(9999999) },
        width: { required: requiredIf(isDevelopmentValidated), decimal, minValue: minValue(0), maxValue: maxValue(9999999) },
        widthCU: { required: requiredIf(needPalletizationPlan), decimal, minValue: minValue(0), maxValue: maxValue(9999999) },
        decorationTypeCode: { required: requiredIf(isCreatedAndDecorationClassificationLevel3), maxLength: maxLength(50) },
        decorationTypeLabel: {},
        customsTypeCode: { required: requiredIf(isCreatedAndCustomsClassificationLevel3), maxLength: maxLength(50) },
        customsTypeLabel: {},
        labelTypeCode: { required: requiredIf(isCreatedAndLabelTypeClassificationLevel3), maxLength: maxLength(50) },
        labelTypeLabel: {},
        pvcFreeCode: { required: requiredIf(isDevelopmentValidated), maxLength: maxLength(50) },
        pvcFreeLabel: {},
    },
};

function isNotNull(value: string): boolean {
    return value !== null && typeof value !== "undefined";
}

function hasMarketingExecDoc(value: ICreationRequestViewModel): boolean {
    return value?.hasMarketingExecDoc ?? true;
}

function isGlassClassificationLevel3(value: ICreationRequestViewModel): boolean {
    return value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.bulkGlass) === true;
}

function isCreatedAndLabelTypeClassificationLevel3(value: ICreationRequestViewModel): boolean {
    return isDevelopmentValidated(value) && value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.labelTypes) === true;
}

function isCreatedAndCartonItemClassificationLevel3(value: ICreationRequestViewModel): boolean {
    return isDevelopmentValidated(value) &&
        (value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.shippingCases) === true
        || value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.display) === true
        || value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.divisions) === true);
}

function isCreatedAndClassificationLevel3ClosureSize(value: ICreationRequestViewModel): boolean {
    return isDevelopmentValidated(value) && value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.aluminiumClosures) === true;
}

function isCreatedAndCapsuleClassification(value: ICreationRequestViewModel): boolean {
    return isDevelopmentValidated(value)
            && (value?.classificationLevel2Code?.trimEquals(jdeCategories.classificationLevel2.capsules) === true
                || value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.aggloCork) === true);

}

function isCreatedAndCapsuleClassificationLevel3(value: ICreationRequestViewModel): boolean {
    return isDevelopmentValidated(value) &&
        (value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.longShortAluminiumCapsules) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.longShortTinCapsules) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.longShortTinCapsules) === true);
}

function isCreatedAndCustomsClassificationLevel3(value: ICreationRequestViewModel): boolean {
    return isDevelopmentValidated(value) &&
        (value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.longShortAluminiumCapsules) === true
        || value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.longShortTinCapsules) === true);
}

function isCreatedAndMaterialClassificationLevel3(value: ICreationRequestViewModel): boolean {
    const options: string[] = [
        jdeCategories.classificationLevel3.adhesivesBackLabels,
        jdeCategories.classificationLevel3.adhesivesLabels,
        jdeCategories.classificationLevel3.adhesivesMedallionLabels,
        jdeCategories.classificationLevel3.adhesivesNeckLabels,
        jdeCategories.classificationLevel3.all,
        jdeCategories.classificationLevel3.boxAccessories,
        jdeCategories.classificationLevel3.bulkGlass,
        jdeCategories.classificationLevel3.cartonGiftBoxes,
        jdeCategories.classificationLevel3.headOfBarTop,
        jdeCategories.classificationLevel3.metalGiftBoxes,
        jdeCategories.classificationLevel3.mountedCartonGiftBoxes,
        jdeCategories.classificationLevel3.mountedOtherGiftBoxes,
        jdeCategories.classificationLevel3.mountedWoodenGiftBoxes,
        jdeCategories.classificationLevel3.palletLabels,
        jdeCategories.classificationLevel3.plasticClosures,
        jdeCategories.classificationLevel3.screwPlasticClosures,
        jdeCategories.classificationLevel3.sleeve,
        jdeCategories.classificationLevel3.wetGlueBackLabels,
        jdeCategories.classificationLevel3.wetGlueLabels,
        jdeCategories.classificationLevel3.wetGlueNeckLabels,
        jdeCategories.classificationLevel3.woodenGiftBoxes,
        jdeCategories.classificationLevel3.display,
        jdeCategories.classificationLevel3.shippingCases,
    ];

    return isDevelopmentValidated(value) && (value && options.some(v => v?.trimEquals(value?.classificationLevel3Code) === true));
}

function isCreatedAndCertificationClassificationLevel3(value: ICreationRequestViewModel): boolean {
    return isDevelopmentValidated(value) && (
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.aggloCork) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.cartonGiftBoxes) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.display) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.divisions) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.drySugar) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.headOfBarTop) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.mountedCartonGiftBoxes) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.mountedOtherGiftBoxes) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.mountedWoodenGiftBoxes) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.packAndClusters) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.prettyGiftBoxes) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.shippingCases) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.woodenGiftBoxes) === true);
}

function hasNeedMarketValidation(value: ICreationRequestViewModel): boolean {
    return value.needMarketValidation;
}

function isCreatedAndRecycledClassificationLevel3(value: ICreationRequestViewModel): boolean {
    return isDevelopmentValidated(value) && value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.bulkGlass) === true;
}

function isCreatedAndLevelPremiumnessClassificationLevel3(value: ICreationRequestViewModel): boolean {
    return isDevelopmentValidated(value) && value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.bulkGlass) === true;
}

function isCreatedAndSourcingClassificationLevel3(value: ICreationRequestViewModel): boolean {
    return isDevelopmentValidated(value) && (
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.headOfBarTop) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.plasticClosures) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.screwPlasticClosures) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.crownClosures) === true);
}

function isCreatedAndDecorationClassificationLevel3(this: CreationRequest, value: ICreationRequestViewModel): boolean {
    if (this) {
        return isDevelopmentValidated(this.vm) && this.isClassificationLevel3Decoration(value.classificationLevel3Code);
    } else {
        return true;
    }
}

function packagingRequired(value: ICreationRequestViewModel): boolean {
    return (isDeclination(value) || isBottleBackLabelClassificationLevel3(value));
}

function isBottleBackLabelClassificationLevel3(value: ICreationRequestViewModel): boolean {
    return value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.wetGlueBackLabels) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.adhesivesBackLabels) === true;
}

function isDevelopmentValidated(value: ICreationRequestViewModel): boolean {
    return value?.requestStatus > RequestStatus.DevelopmentDocument;
}

function isDeclination(value: ICreationRequestViewModel): boolean {
    return value?.itemOrigin === ItemOrigin.Declination;
}

function greaterThanZeroWhenCreated(this: CreationRequest, value: number) {
    if (this) {
        return !isDevelopmentValidated(this.vm) || (isDevelopmentValidated(this.vm) && value > 0);
    } else {
        return true;
    }
}

function isCognacEnvironment(value: ICreationRequestViewModel): boolean {
    return Environments.Cognac.trimContains(value.environmentDisplayName);
}

function needPalletizationPlan(value: ICreationRequestViewModel): boolean {
    return isDevelopmentValidated(value) && value.needPalletizationPlan;
}
