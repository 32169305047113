export enum SupplyChainItemType {
    AmalgamCode  = "amalgamCode",
    Brands = "brands",
    BrandOwners = "brandOwners",
    Bottlings = "bottlings",
    CustomsTypes = "customsTypes",
    ItemTypes = "itemTypes",
    OriginCountries = "originCountries",
    Qualities = "qualities",
    Level2Classifications = "level2Classifications",
    Level3Classifications = "level3Classifications",
    MasterPlanningFamily = "masterPlanningFamily",
    MeasureUnit = "measureUnit",
    Packagings = "packagings",
    ProductTypes = "productTypes",
    PurchasingFamily = "purchasingFamily",
    PurchasingSubFamily = "purchasingSubFamily",
    VapCode = "vapCode",
    Vintages = "vintages",
    WarehouseCodes = "warehouseCodes",
}
