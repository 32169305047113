import Vue from "vue";
import { ActionTree } from "vuex";
import { IJdeState } from "./state";
import {
    GET_ACCOUNTING_GROUPS,
    GET_GL_KEYS,
    GET_ITEM_CHARACTERISTICS,
    GET_ITEM_MASTERS,
    GET_ITEM_SUPPLEMENTAL_DATA,
    GET_MAIN_LOCATIONS,
    GET_MARKET_VALIDATORS,
    GET_PURCHASE_ORDERS,
    GET_SINGLE_ITEM_MASTER,
    GET_SUPPLIERS,
    GET_USER_DEFINED_CODES,
    GET_PROCUREMENT_OFFICERS,
    RESET_STATE,
} from "./mutations-types";
import { AxiosService } from "@/services";
import { IPurchaseOrder, IUserDefinedCode, PurchaseItemType, ISupplier, SupplyChainItemType, IItemMaster, IUserDefinedContact, IActionEnvironmentParameter } from "@/models";
import { AxiosRequestConfig } from "axios";

export const actions: ActionTree<IJdeState, any> = {
    // Shared.
    async getUserDefinedCodes(context, productUserDefinedCodes: [string, string]): Promise<void> {
        let userDefinedCodes: IUserDefinedCode = null;
        try {
            const parameters: [string, string][] = [];
            parameters.push(["productCode", productUserDefinedCodes[0]]);
            parameters.push(["userDefinedCode", productUserDefinedCodes[1]]);
            const query = AxiosService.buildQueryParameters(parameters);
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IUserDefinedCode>(`${process.env.VUE_APP_WEB_API_BASE_URL}/jde/shared/userDefinedCodes${query}`, config);
            userDefinedCodes = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_USER_DEFINED_CODES, userDefinedCodes);
        }
    },

    async getItemSupplementalData(context, userDefinedCode: string): Promise<void> {
        let userDefinedCodes: IUserDefinedCode = null;
        try {
            const parameters: [string, string][] = [];
            parameters.push(["userDefinedCode", userDefinedCode]);
            const query = AxiosService.buildQueryParameters(parameters);
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IUserDefinedCode>(`${process.env.VUE_APP_WEB_API_BASE_URL}/jde/shared/itemSupplementalData${query}`, config);
            userDefinedCodes = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_ITEM_SUPPLEMENTAL_DATA, userDefinedCodes);
        }
    },

    // Pour test, utiliser ["ITEM", "1337"].
    async getItemCharacteristics(context, catLabelAndProductCode: [string, string]): Promise<void> {
        let itemCharacteristics: IUserDefinedCode[] = [];
        try {
            const parameters: [string, string][] = [];
            parameters.push(["catLabel", catLabelAndProductCode[0]]);
            parameters.push(["productCode", catLabelAndProductCode[1]]);
            const query = AxiosService.buildQueryParameters(parameters);
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<any[]>(`${process.env.VUE_APP_WEB_API_BASE_URL}/jde/shared/itemCharacteristic${query}`, config);
            itemCharacteristics = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_ITEM_CHARACTERISTICS, itemCharacteristics);
        }
    },

    // Finance.
    async getAccountingGroups(context, environmentCode: string): Promise<void> {
        let accountingGroups: IUserDefinedCode[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IUserDefinedCode[]>(
                `${process.env.VUE_APP_WEB_API_BASE_URL}/jde/finance/accountingGroups${getQuery(["environment", environmentCode])}`,
                config);

            accountingGroups = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_ACCOUNTING_GROUPS, accountingGroups);
        }
    },

    async getGlKeys(context, environmentCode: string): Promise<void> {
        let glKeys: IUserDefinedCode[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IUserDefinedCode[]>(
                `${process.env.VUE_APP_WEB_API_BASE_URL}/jde/finance/GLKeys${getQuery(["environment", environmentCode])}`,
                config);

            glKeys = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_GL_KEYS, glKeys);
        }
    },

    // Purchase.
    async getPurchases(context, parameters: IActionEnvironmentParameter<PurchaseItemType>): Promise<void> {
        let jdeEntities: IUserDefinedCode[] = [];

        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();

            if (parameters.value === PurchaseItemType.Buyers) {
                const response = await Vue.axios.get<IUserDefinedCode[]>(
                    `${process.env.VUE_APP_WEB_API_BASE_URL}/jde/purchases/${parameters.value}${getQuery(["dryGoodsFilter", "O"])}`,
                    config);

                jdeEntities = response.data;
            }
            else {
                const response = await Vue.axios.get<IUserDefinedCode[]>(
                    `${process.env.VUE_APP_WEB_API_BASE_URL}/jde/purchases/${parameters.value}`,
                    config);

                jdeEntities = response.data;
            }
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(getMutationType(parameters.value), jdeEntities);
        }
    },

    async getPurchaseOrders(context, parameters: IActionEnvironmentParameter<string>): Promise<void> {
        let purchaseOrders: IPurchaseOrder[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IPurchaseOrder[]>(
                `${process.env.VUE_APP_WEB_API_BASE_URL}/jde/purchases/purchaseOrders${getQuery(["environment", parameters?.environmentCode], ["pItemCode", parameters?.value])}`,
                config);
            purchaseOrders = response.data.map(r=> ({
                ...r,
                itemCode: parameters?.value,
            }));
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_PURCHASE_ORDERS, purchaseOrders);
        }
    },

    async getSuppliers(context, environmentCode: string): Promise<void> {
        let suppliers: ISupplier[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<ISupplier[]>(
                `${process.env.VUE_APP_WEB_API_BASE_URL}/jde/purchases/suppliers/${getQuery(["environment", environmentCode], ["dryGoodsFilter", "O"])}`,
                config);
            suppliers = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_SUPPLIERS, suppliers);
        }
    },

    // Supply chain.
    async getSupplyChains(context, parameters: IActionEnvironmentParameter<SupplyChainItemType>): Promise<void> {
        let jdeEntities: IUserDefinedCode[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IUserDefinedCode[]>(
                `${process.env.VUE_APP_WEB_API_BASE_URL}/jde/supplychain/${parameters.value}`,
                config);

            jdeEntities = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(getMutationType(parameters.value), jdeEntities);
        }
    },

    async getProcurementOfficers(context, environmentCode: string): Promise<void> {
        let procurementOfficers: IUserDefinedContact[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IUserDefinedContact[]>(
                `${process.env.VUE_APP_WEB_API_BASE_URL}/jde/supplychain/procurementOfficers${getQuery(["environment", environmentCode])}`,
                config);
            procurementOfficers = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_PROCUREMENT_OFFICERS, procurementOfficers);
        }
    },

    async getItemMasters(context, environmentCode: string): Promise<void> {
        let itemMasters: IItemMaster[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IItemMaster[]>(
                `${process.env.VUE_APP_WEB_API_BASE_URL}/jde/supplychain/itemMasters${getQuery(["environment", environmentCode])}`,
                config);

            itemMasters = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_ITEM_MASTERS, itemMasters);
        }
    },

    async getSingleItemMaster(context, parameter: IActionEnvironmentParameter<string>): Promise<void> {
        let itemMaster: IItemMaster = null;
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IItemMaster>(
                `${process.env.VUE_APP_WEB_API_BASE_URL}/jde/supplychain/itemMaster${getQuery(["environment", parameter?.environmentCode], ["pCode", parameter?.value])}`,
                config);

            itemMaster = response.data[0];
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_SINGLE_ITEM_MASTER, itemMaster);
        }
    },

    async getMainLocations(context, parameter: IActionEnvironmentParameter<string>): Promise<void> {
        let locations: IUserDefinedCode[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IUserDefinedCode[]>(
                `${process.env.VUE_APP_WEB_API_BASE_URL}/jde/supplychain/mainLocations${getQuery(["environment", parameter?.environmentCode], ["pMcu", parameter?.value])}`,
                config);
            locations = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_MAIN_LOCATIONS, locations);
        }
    },

    async getMarketValidators(context, environmentCode: string): Promise<void> {
        let marketValidators: IUserDefinedCode[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IUserDefinedCode[]>(
                `${process.env.VUE_APP_WEB_API_BASE_URL}/jde/supplychain/marketValidators${getQuery(["environment", environmentCode])}`,
                config);
            marketValidators = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_MARKET_VALIDATORS, marketValidators);
        }
    },

    clearState(context): void {
        context.commit(RESET_STATE);
    },
};

const getMutationType = (entityType: PurchaseItemType | SupplyChainItemType): string =>
    `GET_${entityType
        ?.split(/(?=[A-Z])/)
        .join("_")
        .toUpperCase()}`;

const getQuery = (...params: [string, string][]): string => {
    const parameters: [string, string][] = [];
    parameters.push(...params);
    return AxiosService.buildQueryParameters(parameters);
};
