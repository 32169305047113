import { MutationTree } from "vuex";
import {
    GET_ACCOUNTING_GROUPS,
    GET_AMALGAM_CODE,
    GET_BOTTLINGS,
    GET_BRANDS,
    GET_BRAND_OWNERS,
    GET_BUYERS,
    GET_CAPSULE_LENGTHS,
    GET_CAPSULE_QUALITIES,
    GET_CAPSULE_TYPES,
    GET_CARTON_EXTERNAL_BOARDS,
    GET_CARTON_PRINTINGS,
    GET_CARTON_TYPES,
    GET_CLOSURE_SIZES,
    GET_CUSTOMS_TYPES,
    GET_DECORATION_TYPES,
    GET_ITEM_CHARACTERISTICS,
    GET_ITEM_MASTERS,
    GET_ITEM_SUPPLEMENTAL_DATA,
    GET_ITEM_TYPES,
    GET_GLASS_HUES,
    GET_LABEL_TYPES,
    GET_LEVEL2_CLASSIFICATIONS,
    GET_LEVEL3_CLASSIFICATIONS,
    GET_MAIN_LOCATIONS,
    GET_MARKET_VALIDATORS,
    GET_MASTER_PLANNING_FAMILY,
    GET_MATERIALS,
    GET_MEASURE_UNIT,
    GET_ORIGIN_COUNTRIES,
    GET_PACKAGINGS,
    GET_PROCUREMENT_OFFICERS,
    GET_PRODUCT_TYPES,
    GET_PURCHASE_ORDERS,
    GET_PURCHASING_FAMILY,
    GET_PURCHASING_SUB_FAMILY,
    GET_PVC_FREE,
    GET_QUALITIES,
    GET_SINGLE_ITEM_MASTER,
    GET_SUPPLIERS,
    GET_USER_DEFINED_CODES,
    GET_VAP_CODE,
    GET_VINTAGES,
    GET_WAREHOUSE_CODES,
    RESET_STATE,
    GET_CERTIFICATIONS,
    GET_LEVEL_PREMIUMNESS,
    GET_SOURCINGS,
    GET_GL_KEYS,
} from "./mutations-types";
import { IJdeState, getInitialState } from "./state";
import { IUserDefinedCode, IPurchaseOrder, SupplyChainItemType, IItemMaster, ISupplier, PurchaseItemType, IMarketValidator, IUserDefinedContact } from "@/models";

export const mutations: MutationTree<IJdeState> = {
    [GET_ACCOUNTING_GROUPS](state, payload: IUserDefinedCode[]): void {
        state.accountingGroups = payload.sortByProperty(p => p.code);
    },

    [GET_AMALGAM_CODE](state, payload: IUserDefinedCode[]): void {
        state.amalgams = payload.sortByProperty(p => p.code);
        state.loadedSupplyChains.push(SupplyChainItemType.AmalgamCode);
    },

    [GET_BOTTLINGS](state, payload: IUserDefinedCode[]): void {
        state.bottlings = payload.sortByProperty(p => p.code);
        state.loadedSupplyChains.push(SupplyChainItemType.Bottlings);
    },

    [GET_BRANDS](state, payload: IUserDefinedCode[]): void {
        state.brands = payload.sortByProperty(p => p.code);
        state.loadedSupplyChains.push(SupplyChainItemType.Brands);
    },

    [GET_BRAND_OWNERS](state, payload: IUserDefinedContact[]): void {
        state.brandOwners = payload.sortByProperty(p => p.code);
    },

    [GET_CUSTOMS_TYPES](state, payload: IUserDefinedCode[]): void {
        state.customsTypes = payload.sortByProperty(p => p.code);
        state.loadedSupplyChains.push(SupplyChainItemType.CustomsTypes);
    },

    [GET_ITEM_CHARACTERISTICS](state, payload: any[]): void {
        state.itemCharacteristics = payload;
    },

    [GET_ITEM_MASTERS](state, payload: IItemMaster[]): void {
        if (!state.itemMasters) {
            state.itemMasters = [];
        }

        if (payload) {
            for (const item of payload.sortByProperty(p => p.code)) {
                const index: number = state.itemMasters.findIndex(i => i.code.trimEquals(item.code));
                if (index < 0) {
                    state.itemMasters.push(item);
                } else {
                    const itemMaster = state.itemMasters[index];
                    state.itemMasters[index] = {
                        ...itemMaster,
                        code: item?.code,
                        description1: item?.description1,
                        description2: item?.description2,
                    };
                }
            }
        }
    },

    [GET_ITEM_SUPPLEMENTAL_DATA](state, payload: IUserDefinedCode[]): void {
        state.itemSupplementalData = payload;
    },

    [GET_ITEM_TYPES](state, payload: IUserDefinedCode[]): void {
        state.itemTypes = payload;
    },

    [GET_BUYERS](state, payload: IUserDefinedContact[]): void {
        state.buyers = payload.sortByProperty(p => p.code);
        state.loadedPurchases.push(PurchaseItemType.Buyers);
    },

    [GET_CAPSULE_LENGTHS](state, payload: IUserDefinedCode[]): void {
        state.capsuleLengths = payload.sortByProperty(p => p.code);
        state.loadedPurchases.push(PurchaseItemType.CapsuleLengths);
    },

    [GET_CAPSULE_QUALITIES](state, payload: IUserDefinedCode[]): void {
        state.capsuleQualities = payload.sortByProperty(p => p.code);
        state.loadedPurchases.push(PurchaseItemType.CapsuleQualities);
    },

    [GET_CAPSULE_TYPES](state, payload: IUserDefinedCode[]): void {
        state.capsuleTypes = payload.sortByProperty(p => p.code);
        state.loadedPurchases.push(PurchaseItemType.CartonTypes);
    },

    [GET_CARTON_EXTERNAL_BOARDS](state, payload: IUserDefinedCode[]): void {
        state.cartonExternalBoards = payload.sortByProperty(p => p.code);
        state.loadedPurchases.push(PurchaseItemType.CartonExternalBoards);
    },

    [GET_CARTON_PRINTINGS](state, payload: IUserDefinedCode[]): void {
        state.cartonPrintings = payload.sortByProperty(p => p.code);
        state.loadedPurchases.push(PurchaseItemType.CartonPrintings);
    },

    [GET_CARTON_TYPES](state, payload: IUserDefinedCode[]): void {
        state.cartonTypes = payload.sortByProperty(p => p.code);
        state.loadedPurchases.push(PurchaseItemType.CartonTypes);
    },

    [GET_CLOSURE_SIZES](state, payload: IUserDefinedCode[]): void {
        state.closureSizes = payload.sortByProperty(p => p.code);
        state.loadedPurchases.push(PurchaseItemType.ClosureSizes);
    },

    [GET_DECORATION_TYPES](state, payload: IUserDefinedCode[]): void {
        state.decorationTypes = payload.sortByProperty(p => p.code);
        state.loadedPurchases.push(PurchaseItemType.DecorationTypes);
    },

    [GET_GLASS_HUES](state, payload: IUserDefinedCode[]): void {
        state.glassHues = payload.sortByProperty(p => p.code);
        state.loadedPurchases.push(PurchaseItemType.GlassHues);
    },

    [GET_GL_KEYS](state, payload: IUserDefinedCode[]): void {
        state.glKeys = payload.sortByProperty(p => p.code);
    },

    [GET_LABEL_TYPES](state, payload: IUserDefinedCode[]): void {
        state.labelTypes = payload.sortByProperty(p => p.code);
        state.loadedPurchases.push(PurchaseItemType.LabelTypes);
    },

    [GET_LEVEL2_CLASSIFICATIONS](state, payload: IUserDefinedCode[]): void {
        state.level2Classifications = payload.sortByProperty(p => p.code);
        state.loadedSupplyChains.push(SupplyChainItemType.Level2Classifications);
    },

    [GET_LEVEL3_CLASSIFICATIONS](state, payload: IUserDefinedCode[]): void {
        state.level3Classifications = payload.sortByProperty(p => p.code);
        state.loadedSupplyChains.push(SupplyChainItemType.Level3Classifications);
    },

    [GET_MAIN_LOCATIONS](state, payload: IUserDefinedCode[]): void {
        if (!state.mainLocations) {
            state.mainLocations = [];
        }

        // Replace existing warehouse codes.
        if (payload?.length > 0) {
            const warehouseCodes: string[] = payload.map(p => p.label);
            let otherMainLocations: IUserDefinedCode[] = state.mainLocations.filter(m => !warehouseCodes.some(c => c === m.label));
            otherMainLocations = otherMainLocations.concat(payload);
            state.mainLocations = otherMainLocations.sortByProperty(p => p.code);
        }
    },

    [GET_MARKET_VALIDATORS](state, payload: IMarketValidator[]): void {
        state.marketValidators = payload.sortByProperty(p => p.mail);
    },

    [GET_MASTER_PLANNING_FAMILY](state, payload: IUserDefinedCode[]): void {
        state.masterPlanningFamilies = payload.sortByProperty(p => p.code);
        state.loadedSupplyChains.push(SupplyChainItemType.MasterPlanningFamily);
    },

    [GET_MATERIALS](state, payload: IUserDefinedCode[]): void {
        state.materials = payload.sortByProperty(p => p.code);
        state.loadedPurchases.push(PurchaseItemType.Materials);
    },

    [GET_MEASURE_UNIT](state, payload: IUserDefinedCode[]): void {
        state.measureUnits = payload.sortByProperty(p => p.code);
        state.loadedSupplyChains.push(SupplyChainItemType.MeasureUnit);
    },

    [GET_ORIGIN_COUNTRIES](state, payload: IUserDefinedCode[]): void {
        state.originCountries = payload.sortByProperty(p => p.code);
        state.loadedSupplyChains.push(SupplyChainItemType.OriginCountries);
    },

    [GET_PACKAGINGS](state, payload: IUserDefinedCode[]): void {
        state.packagings = payload.sortByProperty(p => p.code);
        state.loadedSupplyChains.push(SupplyChainItemType.Packagings);
    },

    [GET_PROCUREMENT_OFFICERS](state, payload: IUserDefinedContact[]): void {
        state.procurementOfficers = payload.sortByProperty(p => p.code);
    },

    [GET_PRODUCT_TYPES](state, payload: IUserDefinedContact[]): void {
        state.productTypes = payload.sortByProperty(p => p.code);
    },

    [GET_PURCHASE_ORDERS](state, payload: IPurchaseOrder[]): void {
        if (!state.purchaseOrders) {
            state.purchaseOrders = [];
        }

        // Replace existing purchase orders.
        if (payload?.length > 0) {
            const itemCodes: string[] = [... new Set(payload.map(p => p.itemCode))];
            const otherPurchaseOrders: IPurchaseOrder[] = state.purchaseOrders.filter(p => !itemCodes.some(c => c.trimEquals(p.itemCode)));
            state.purchaseOrders = otherPurchaseOrders.concat(payload);
        }
    },

    [GET_PURCHASING_FAMILY](state, payload: IUserDefinedCode[]): void {
        state.purchasingFamily = payload.sortByProperty(p => p.code);
        state.loadedSupplyChains.push(SupplyChainItemType.PurchasingFamily);
    },

    [GET_PURCHASING_SUB_FAMILY](state, payload: IUserDefinedCode[]): void {
        state.purchasingSubFamily = payload.sortByProperty(p => p.code);
        state.loadedSupplyChains.push(SupplyChainItemType.PurchasingSubFamily);
    },

    [GET_QUALITIES](state, payload: IUserDefinedCode[]): void {
        state.qualities = payload.sortByProperty(p => p.code);
        state.loadedSupplyChains.push(SupplyChainItemType.Qualities);
    },

    [GET_SINGLE_ITEM_MASTER](state, payload: IItemMaster): void {
        if (!state.itemMasters) {
            state.itemMasters = [];
        }
        if (payload) {
            const index: number = state.itemMasters.findIndex(i => i.code.trimEquals(payload.itemCode));
            if (index < 0) {
                const itemMaster: IItemMaster = {
                    ...payload,
                    code: payload.itemCode,
                    description1: payload.itemCode,
                    description2: null,
                };
                state.itemMasters.push(itemMaster);
            } else {
                state.itemMasters[index] = {
                    ...payload,
                    code: state.itemMasters[index].code,
                    description1: state.itemMasters[index].description1,
                    description2: state.itemMasters[index].description2,
                };
            }
        }
    },

    [GET_SUPPLIERS](state, payload: ISupplier[]): void {
        state.suppliers = payload.sortByProperty(p => p.code);
    },

    [GET_USER_DEFINED_CODES](state, payload: IUserDefinedCode[]): void {
        state.userDefinedCodes = payload.sortByProperty(p => p.code);
    },

    [GET_VAP_CODE](state, payload: IUserDefinedCode[]): void {
        state.vapCodes = payload.sortByProperty(p => p.code);
        state.loadedSupplyChains.push(SupplyChainItemType.VapCode);
    },

    [GET_VINTAGES](state, payload: IUserDefinedCode[]): void {
        state.vintages = payload.sortByProperty(p => p.code);
        state.loadedSupplyChains.push(SupplyChainItemType.Vintages);
    },

    [GET_WAREHOUSE_CODES](state, payload: IUserDefinedCode[]): void {
        state.warehouseCodes = payload.sortByProperty(p => p.code);
        state.loadedSupplyChains.push(SupplyChainItemType.WarehouseCodes);
    },

    [GET_CERTIFICATIONS](state, payload: IUserDefinedCode[]): void {
        state.certifications = payload.sortByProperty(p => p.code);
        state.loadedPurchases.push(PurchaseItemType.Certifications);
    },

    [GET_PVC_FREE](state, payload: IUserDefinedCode[]): void {
        state.pvcFree = payload.sortByProperty(p => p.code);
        state.loadedPurchases.push(PurchaseItemType.PvcFree);
    },

    [GET_LEVEL_PREMIUMNESS](state, payload: IUserDefinedCode[]): void {
        state.levelPremiumness = payload.sortByProperty(p => p.code);
        state.loadedPurchases.push(PurchaseItemType.LevelPremiumness);
    },

    [GET_SOURCINGS](state, payload: IUserDefinedCode[]): void {
        state.sourcings = payload.sortByProperty(p => p.code);
        state.loadedPurchases.push(PurchaseItemType.Sourcings);
    },

    [RESET_STATE](state): void {
        Object.assign(state, getInitialState());
    }
};
