import { RuleDecl } from "vue/types/options";
import { required, minValue, maxValue, decimal, integer, minLength, maxLength, requiredIf } from "vuelidate/lib/validators";
import { IControlViewModel } from "./IFirstReceptionViewModel";

export const validations: RuleDecl = {
    vm: {
        controls: {
            required,
            minLength: minLength(1),
            $each: {
                controlDate: { required },
                controllerId: { required },
                controlDuration: { required, decimal, minValue: minValue(0.1), maxValue: maxValue(9999999) },
                controlIsCorrect: { required },
                controlComment: { maxLength: maxLength(3000) },
                orderNumber: { required },
                deliveryDate: {
                    required,
                    beforeThanControlDate(val, { controlDate }) {
                        return new Date(val) <= new Date(controlDate);
                    }
                },
                deliveryQuantity: { required, integer, minValue: minValue(0), maxValue: maxValue(9999999) },
                controlledQuantity: { required, integer, minValue: minValue(0), maxValue: maxValue(9999999) },
                uncomplianceDescription: { required: requiredIf(isControlNotCorrect), maxLength: maxLength(3000) },
                defectBlockerVolume: { required: requiredIf(isControlNotCorrect), integer, minValue: minValue(0), maxValue: maxValue(9999999) },
                defectCriticalVolume: { required: requiredIf(isControlNotCorrect), integer, minValue: minValue(0), maxValue: maxValue(9999999) },
                defectMajorVolume: { required: requiredIf(isControlNotCorrect), integer, minValue: minValue(0), maxValue: maxValue(9999999) },
                defectMinorVolume: { required: requiredIf(isControlNotCorrect), integer, minValue: minValue(0), maxValue: maxValue(9999999) },
            }
        }
    },
};

function isControlNotCorrect(value: IControlViewModel): boolean {
    return value?.controlIsCorrect === false;
}
