import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { IUser, IAssignmentType, AssignmentType, IUserAssignment, IUserDefinedCode, IEnvironment } from "@/models";
import vSelect from "vue-select";
import { validations } from "./validations";
import { NotificationService } from "@/services/NotificationService";
import { UserDefinedCodeSelect, PeoplePicker, LoaderSpinner } from "@/components";
import { IManageUserViewModel, IUserAssignmentViewModel } from "./IManageUserViewModel";
import { v4 as uuidv4 } from "uuid";

@Component({
    name: "manage-users",
    components: { "vue-select": vSelect, UserDefinedCodeSelect, PeoplePicker, LoaderSpinner },
    validations,
})
export default class ManageUsers extends Vue {
    public vm: IManageUserViewModel[];
    public loadingData: boolean;
    public searchText: string;
    public newUser: IUser;

    public constructor() {
        super();

        this.vm = [];
        this.loadingData = false;
        this.searchText = "";
        this.newUser = null;
    }

    public get currentUser(): IUser {
        return this.$store.getters["app/currentUser"];
    }

    public get users(): IUser[] {
        return this.$store.state.app.users;
    }

    public get assignmentTypes(): IAssignmentType[] {
        return this.$store.state.app.assignmentTypes;
    }

    public get suppliers(): IUserDefinedCode[] {
        return this.$store.getters["jde/supplierCodes"];
    }

    public get environments(): IEnvironment[] {
        return this.$store.state.app.environments;
    }

    public get activeEnvironment(): IEnvironment {
        return this.$store.getters["app/activeEnvironment"];
    }

    private get activeEnvironmentCode(): string {
        return this.$store.getters["app/activeEnvironment"]?.code;
    }

    @Watch("users")
    public onUsersChanges(newVal: IUser[], oldVal: IUser[]): void {
        this.vm = this.createViewModel(newVal);
    }

    // Hook.
    public async created(): Promise<void> {
        this.loadingData = true;
        await this.loadData();
        this.vm = this.createViewModel(this.users);
        this.loadingData = false;
    }

    public async updateLocalUserName(user: IUser, event: any): Promise<void> {
        if (user.name.localeCompare(event.target.value) !== 0) {
            user.name = event.target.value;
            if (this.validateBeforeSubmit()) {
                const response: IUser = await this.$store.dispatch("app/createOrUpdateUser", user);
                if (response) {
                    NotificationService.showSuccess("Utilisateur sauvegardé");
                }
            } else {
                NotificationService.showError("Le formulaire contient des erreurs");
            }
        }
    }

    public async updateLocalUserEmail(user: IUser, event: any): Promise<void> {
        if (user.email.localeCompare(event.target.value) !== 0) {
            user.email = event.target.value;
            if (this.validateBeforeSubmit()) {
                const response: IUser = await this.$store.dispatch("app/createOrUpdateUser", user);
                if (response) {
                    NotificationService.showSuccess("Utilisateur sauvegardé");
                }
            } else {
                NotificationService.showError("Le formulaire contient des erreurs");
            }
        }
    }

    public async updateLocalUserEnvironment(user: IUser, environment: IEnvironment): Promise<void> {
        if (environment && user.defaultEnvironmentId !==  environment.environmentId) {
            user.defaultEnvironmentId = environment.environmentId;
            user.defaultEnvironmentCode = environment.code;
            user.defaultEnvironmentDisplayName = environment.label;
            if (this.validateBeforeSubmit()) {
                const response: IUser = await this.$store.dispatch("app/createOrUpdateUser", user);
                if (response) {
                    NotificationService.showSuccess("Utilisateur sauvegardé");
                }
            } else {
                NotificationService.showError("Le formulaire contient des erreurs");
            }
        }
    }

    public async updateAssignment(user: IUser, userAssignment: IUserAssignment, assignmentType: AssignmentType): Promise<void> {
        userAssignment.assignmentType = assignmentType;
        userAssignment.backUpUserId = null;
        userAssignment.roleCode = null;
        if (this.validateBeforeSubmit()) {
            const response: IUser = await this.$store.dispatch("app/createOrUpdateUser", user);
            if (response) {
                NotificationService.showSuccess("Affectation sauvegardée");
            }
        } else {
            NotificationService.showError("Le formulaire contient des erreurs");
        }
    }

    public async updateSupplier(user: IUser): Promise<void> {
        if (this.validateBeforeSubmit()) {
            const response: IUser = await this.$store.dispatch("app/createOrUpdateUser", user);
            if (response) {
                NotificationService.showSuccess("Affectation sauvegardée");
            }
        } else {
            NotificationService.showError("Le formulaire contient des erreurs");
        }
    }

    public async updateNotifications(user: IUser): Promise<void> {
        if (this.validateBeforeSubmit()) {
            const response: IUser = await this.$store.dispatch("app/createOrUpdateUser", user);
            if (response) {
                NotificationService.showSuccess("Affectation sauvegardée");
            }
        } else {
            NotificationService.showError("Le formulaire contient des erreurs");
        }
    }

    public async updateBackUp(user: IUser, userAssignment: IUserAssignment, backupId: number): Promise<void> {
        userAssignment.backUpUserId = backupId;
        if (this.validateBeforeSubmit()) {
            const response: IUser = await this.$store.dispatch("app/createOrUpdateUser", user);
            if (response) {
                NotificationService.showSuccess("Suppléant sauvegardé");
            }
        } else {
            NotificationService.showError("Le formulaire contient des erreurs");
        }
    }

    public async addNewUser(): Promise<void> {
        if (this.newUser) {
            this.newUser.defaultEnvironmentId = this.activeEnvironment?.environmentId;
            this.newUser.defaultEnvironmentCode = this.activeEnvironment?.code;
            this.newUser.defaultEnvironmentDisplayName = this.activeEnvironment?.label;

            if (this.validateBeforeSubmit()) {
                const response: IUser = await this.$store.dispatch("app/createOrUpdateUser", this.newUser);
                if (response) {
                    NotificationService.showSuccess("Utilisateur ajouté");
                }
            } else {
                NotificationService.showError("Le formulaire contient des erreurs");
            }

            (this.$refs.addUserComponent as any).clearOptions();
        }
    }

    public getAvailableAssignmentTypes(user: IUser, userAssignment: IUserAssignment): IAssignmentType[] {
        const assignedAssignments = user.userAssignments.filter(ua => ua.environmentCode === this.activeEnvironment?.code).map(ua => ua.assignmentType);
        return this.assignmentTypes.filter(at => !assignedAssignments.includes(at.assignmentTypeId) || at.assignmentTypeId === userAssignment?.assignmentType);
    }

    public getAvailableBackups(user: IUser, userAssignment: IUserAssignment): IUser[] {
        return this.users.filter(u => u.userId !== user.userId && u.userAssignments.some(ua=>ua.assignmentType === userAssignment.assignmentType));
    }

    public getUserAssignments(user: IUser): IUserAssignment[] {
        return user.userAssignments.filter(ua => ua.environmentId === this.activeEnvironment?.environmentId);
    }

    public userCanBeDeleted(user: IUser): boolean {
        return user && user.userId !== this.currentUser.userId;
    }

    public assignmentCanBeAdded(user: IUser): boolean {
        return user && user.userAssignments?.length < this.assignmentTypes.length;
    }

    public assignmentCanBeDeleted(user: IUser, userAssignment: IUserAssignment): boolean {
        return user.userId !== this.currentUser.userId || (user.userId === this.currentUser.userId && userAssignment?.assignmentType !== AssignmentType.Administrator);
    }

    public isAssignmentReadonly(user: IUser, userAssignment: IUserAssignment): boolean {
        return user.userId === this.currentUser.userId && userAssignment?.assignmentType === AssignmentType.Administrator;
    }

    public isSupplier(userAssignment: IUserAssignment): boolean {
        return userAssignment?.assignmentType === AssignmentType.Supplier;
    }

    public isDisplayed(user: IUser): boolean {
        return !user.locked && user.name.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0;
    }

    public addAssignment(user: IManageUserViewModel): void {
        user.userAssignments.unshift({
            userAssignmentId: null,
            backUpUserId: null,
            backUpUserName: null,
            backUpUserEmail: null,
            assignmentType: null,
            assignmentTypeDisplayName: null,
            roleCode: null,
            roleLabel: null,
            notifiedByFlow: false,
            uniqueKey: uuidv4(),
            environmentCode: this.activeEnvironment?.code,
            environmentDisplayName: this.activeEnvironment?.label,
            environmentId: this.activeEnvironment?.environmentId,
            environment: {
                code: this.activeEnvironment?.code,
                label: this.activeEnvironment?.label,
                environmentId: this.activeEnvironment?.environmentId,
            }
        });
    }

    public showDeleteUserModal(user: IUser): void {
        this.$modal.show("dialog", {
            title: "Supprimer l'utilisateur",
            text: "L'utilisateur n'aura plus accès à l'application. Cette action ne peut pas être annulée.",
            buttons: [
                {
                    title: "Supprimer",
                    handler: () => {
                        this.$modal.hide("dialog");
                        this.deleteUser(user);
                    }
                },
                {
                    title: "Annuler",
                    default: true,    // Will be triggered by default if "Enter" pressed.
                    handler: () => {
                        this.$modal.hide("dialog");
                    },
                },
            ]
        });
    }

    public async deleteAssignment(user: IManageUserViewModel, userAssignment: IUserAssignmentViewModel) {
        user.userAssignments = user.userAssignments.filter(ua => ua.uniqueKey !== userAssignment.uniqueKey);
        if (this.validateBeforeSubmit()) {
            const response: IUser = await this.$store.dispatch("app/createOrUpdateUser", user);
            if (response) {
                NotificationService.showSuccess("Affectation supprimée");
            }
        } else {
            NotificationService.showError("Le formulaire contient des erreurs");
        }
    }

    private async deleteUser(user: IUser): Promise<void> {
        const response: IUser = await this.$store.dispatch("app/deleteUser", user);
        if (response) {
            NotificationService.showSuccess("Utilisateur supprimé");
        }
    }

    private async loadData(): Promise<void> {
        const promises: Promise<any>[] = [];
        if (!this.$store.state.app.usersLoaded) {
            promises.push(this.$store.dispatch("app/getUsers"));
        }

        if (this.$store.state.app.environments?.length < 2) {
            promises.push(this.$store.dispatch("app/getEnvironments"));
        }

        if (this.$store.state.app.assignmentTypes?.length < 1) {
            promises.push(this.$store.dispatch("app/getAssignmentTypes"));
        }

        if (this.$store.state.jde.suppliers?.length < 1) {
            promises.push(this.$store.dispatch("jde/getSuppliers", this.activeEnvironmentCode));
        }

        await Promise.all(promises);
    }

    private validateBeforeSubmit(): boolean {
        this.$v.vm.$touch();
        return !this.$v.vm.$invalid;
    }

    private createViewModel(users: IUser[]): IManageUserViewModel[] {
        const vm = [
            ...users.map(u => ({
                ...u,
                userAssignments: [
                    ...u.userAssignments.sort((x, y) => x.assignmentTypeDisplayName
                        .localeCompare(y.assignmentTypeDisplayName))
                        .map(ua => (
                            {
                                ...ua,
                                uniqueKey: uuidv4(),
                                environment: {
                                    environmentId: ua.environmentId,
                                    code: ua.environmentCode,
                                    label: ua.environmentDisplayName,
                                },
                            })),
                ],
            })),
        ];

        // Sort users.
        this.vm.sort((x, y) => x.name.localeCompare(y.name));

        return vm;
    }
}
