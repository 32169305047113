import { ActionTree } from "vuex";
import Vue from "vue";
import { IRequestsState } from "./state";
import { GET_SINGLE_REQUEST, GET_REQUESTS, SET_ACTIVE_REQUEST_ID, RESET_STATE } from "./mutations-types";
import { NotificationService, RequestService, AxiosService } from "@/services";
import { IRequest, IRequestsPage, IUser, RequestStatus } from "@/models";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export const actions: ActionTree<IRequestsState, any> = {
    async getRequests(context, parameters: [string, string][]): Promise<void> {
        let requestsPage: IRequestsPage = null;
        try {
            const query = AxiosService.buildQueryParameters(parameters);
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IRequestsPage>(`${process.env.VUE_APP_WEB_API_BASE_URL}/requests${query}`, config);
            requestsPage = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_REQUESTS, requestsPage);
        }
    },

    async getSingleRequest(context, requestId: number): Promise<void> {
        // Force mutation when user wants to refresh the same request.
        if (requestId === context.state.activeRequestId) {
            context.commit(SET_ACTIVE_REQUEST_ID, null);
        }

        let request: IRequest = null;
        try {
            if (requestId > 0) {
                const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
                const response = await Vue.axios.get<IRequest>(`${process.env.VUE_APP_WEB_API_BASE_URL}/requests/${requestId}`, config);
                request = response.data;
            } else {
                const requester: IUser = context.rootGetters["app/currentUser"];
                request = RequestService.createRequest(requester, context.rootGetters["app/activeEnvironment"]);
            }

            if (request) {
                context.commit(GET_SINGLE_REQUEST, request);
            }
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(SET_ACTIVE_REQUEST_ID, request?.requestId);
        }
    },

    async createOrUpdateRequest(context, request: IRequest): Promise<IRequest> {
        let response: AxiosResponse<IRequest> = null;
        try {
            const url = `${process.env.VUE_APP_WEB_API_BASE_URL}/requests/`;
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            response = request?.requestId > 0 ? await Vue.axios.put(url + request.requestId, request, config) : await Vue.axios.post(url, request, config);
            if (request?.requestStatus === RequestStatus.Canceled) {
                NotificationService.showSuccess("Requête annulée");
            }
            else {
                NotificationService.showSuccess("Requête sauvegardée");
            }

            // Fetch requests to retrieve api updates.
            const query: [string, string][] = [];
            query.push(["environment", context.rootGetters["app/activeEnvironment"]?.code]);
            query.push(["limit", String(context.state.limit)]);
            query.push(["page", String(context.state.page)]);
            query.push(["archives", String(context.state.archives)]);
            if (context.state.itemCode?.length > 0) {
                query.push(["itemCode", context.state.itemCode]);
            }

            if (context.state.projectName?.length > 0) {
                query.push(["projectName", context.state.projectName]);
            }

            if (context.state.requesterId) {
                query.push(["requesterId", String(context.state.requesterId)]);
            }

            if (context.state.requestStatus) {
                query.push(["requestStatus", String(context.state.requestStatus)]);
            }

            if (context.state.brandCode) {
                query.push(["brandCode", String(context.state.brandCode)]);
            }

            if (context.state.qualityCode) {
                query.push(["qualityCode", String(context.state.qualityCode)]);
            }

            if (context.state.bottlingCode) {
                query.push(["bottlingCode", String(context.state.bottlingCode)]);
            }

            if (context.state.amalgamCode) {
                query.push(["amalgamCode", String(context.state.amalgamCode)]);
            }

            context.dispatch("getRequests", query);

            // Fetch updated project names.
            if (!context.rootState.app.projectNames?.some(pn => pn === request?.projectName)) {
                context.dispatch("app/getProjectNames", null, { root: true });
            }

            return response.data;
        } catch {
            return null;
        }
    },

    async updateRequestWithoutWorkflow(context, request: IRequest): Promise<IRequest> {
        let response: IRequest = null;
        try {
            const url = `${process.env.VUE_APP_WEB_API_BASE_URL}/requests/`;
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            response = await Vue.axios.put(url + request.requestId + "/updateWithoutWorkflow", request, config);
            if (request?.requestStatus === RequestStatus.Canceled) {
                NotificationService.showSuccess("Requête annulée");
            }
            else {
                NotificationService.showSuccess("Requête sauvegardée");
            }

            // Fetch requests to retrieve api updates.
            const query: [string, string][] = [];
            query.push(["environment", context.rootGetters["app/activeEnvironment"]?.code]);
            query.push(["limit", String(context.state.limit)]);
            query.push(["page", String(context.state.page)]);
            query.push(["archives", String(context.state.archives)]);
            if (context.state.itemCode?.length > 0) {
                query.push(["itemCode", context.state.itemCode]);
            }

            if (context.state.projectName?.length > 0) {
                query.push(["projectName", context.state.projectName]);
            }

            if (context.state.requesterId) {
                query.push(["requesterId", String(context.state.requesterId)]);
            }

            if (context.state.requestStatus) {
                query.push(["requestStatus", String(context.state.requestStatus)]);
            }

            if (context.state.brandCode) {
                query.push(["brandCode", String(context.state.brandCode)]);
            }

            if (context.state.qualityCode) {
                query.push(["qualityCode", String(context.state.qualityCode)]);
            }

            if (context.state.bottlingCode) {
                query.push(["bottlingCode", String(context.state.bottlingCode)]);
            }

            if (context.state.amalgamCode) {
                query.push(["amalgamCode", String(context.state.amalgamCode)]);
            }

            context.dispatch("getRequests", query);

            // Fetch updated project names.
            if (!context.rootState.app.projectNames?.some(pn => pn === request?.projectName)) {
                context.dispatch("app/getProjectNames", null, { root: true });
            }
            return response;
        } catch {
            return response;
        }
    },

    clearState(context): void {
        context.commit(RESET_STATE);
    },
};
