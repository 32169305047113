import * as Msal from "msal";
import { authService } from "@/services";
import { IUser, IItemOrigin, IMailContent, IAssignmentType, IRequestStatus, IEnvironment, IBasicDocument } from "@/models";

interface IAppState {
    activeEnvironmentId: number;
    appReady: boolean;
    assignmentTypes: IAssignmentType[];
    documentations: IBasicDocument[];
    environments: IEnvironment[];
    itemOrigins: IItemOrigin[];
    mailContents: IMailContent[];
    projectNames: string[];
    requestStatus: IRequestStatus[];
    userAccount: Msal.Account;
    users: IUser[];
    usersLoaded: boolean;
}

const initialState: IAppState = {
    activeEnvironmentId: null,
    appReady: true,
    assignmentTypes: [],
    documentations: [],
    environments: [],
    itemOrigins: [],
    mailContents:[],
    projectNames: [],
    requestStatus: [],
    userAccount: authService.getAccount(),
    users: [],
    usersLoaded: false,
};

export { IAppState, initialState };
