import Vue from "vue";
import Vuex from "vuex";
import { app } from "./app";
import { jde } from "./jde";
import { requests } from "./requests";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        app,
        jde,
        requests,
    },
});

export { store };
