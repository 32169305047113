import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import {
    IRequest,
    RequestStatus,
    IRequestDevStep,
    IRequestStatus,
    IEnvironment,
    IUser,
    IActionEnvironmentParameter,
    SupplyChainItemType,
    IUserDefinedCode,
    IControl,
    IDocument,
    IRequestStatusDropdown } from "@/models";
import { RequestService } from "@/services";
import Paginate from "vuejs-paginate";
import vSelect from "vue-select";
import { LoaderSpinner, UserDefinedCodeSelect } from "@/components";

@Component({
    name: "home-view",
    components: { Paginate, "vue-select": vSelect, LoaderSpinner, UserDefinedCodeSelect },
})
export default class HomeView extends Vue {
    @Prop(({ default: false }))
    public archives: boolean;

    public loadingData: boolean;
    public thumbnailDocument: IDocument;
    public packagingNameFilter = "";

    public constructor() {
        super();

        this.loadingData = false;
        this.thumbnailDocument = null;
    }

    public get title(): string {
        return this.archives ? "Archives" : "Tableau de bord";
    }

    public get requests(): IRequest[] {
        return this.$store.getters["requests/requests"];
    }

    public get requesters(): IUser[] {
        return this.$store.getters["app/requesters"];
    }

    public get requestStatus(): IRequestStatus[] {
        if (this.archives){
            return this.$store.getters["app/archivedStatus"];
        } else {
            return this.$store.getters["app/homeStatus"];
        }
    }

    public get requestStatusDropdown(): IRequestStatusDropdown[] {
        const dropdownValues: IRequestStatusDropdown[] = [];

        if (this.archives){
            dropdownValues.push({requestStatusId: 190, label: "Complétée"});
            dropdownValues.push({requestStatusId: 200, label: "Annulée"});
        } else {
            dropdownValues.push({requestStatusId: 1, label: "Brouillon"});
            dropdownValues.push({requestStatusId: 10, label: "Demandes en attente de validation"});
            dropdownValues.push({requestStatusId: 20, label: "Demandes de création rejetées"});
            dropdownValues.push({requestStatusId: 30, label: "Codes en attente de création"});
            dropdownValues.push({requestStatusId: 65, label: "Attente doc d'exe"});
            dropdownValues.push({requestStatusId: 80, label: "Doc d'exe validé"});
            dropdownValues.push({requestStatusId: 85, label: "Demande de BAT à envoyer"});
            dropdownValues.push({requestStatusId: 100, label: "Demande de BAT en cours"});
            dropdownValues.push({requestStatusId: 105, label: "Validation de BAT expirée"});
            dropdownValues.push({requestStatusId: 110, label: "Validation de BAT en cours"});
            dropdownValues.push({requestStatusId: 115, label: "BAT refusé"});
            dropdownValues.push({requestStatusId: 120, label: "BAT validé"});
            dropdownValues.push({requestStatusId: 122, label: "Validation marché en attente"});
            dropdownValues.push({requestStatusId: 124, label: "Validation marché expirée"});
            dropdownValues.push({requestStatusId: 126, label: "Validation marché rejetée"});
            dropdownValues.push({requestStatusId: 130, label: "Validation doc technique en cours"});
            dropdownValues.push({requestStatusId: 140, label: "Document validés"});
            dropdownValues.push({requestStatusId: 150, label: "Données techniques à compléter"});
            dropdownValues.push({requestStatusId: 170, label: "Contrôle à 1ère réception en attente"});
            dropdownValues.push({requestStatusId: 180, label: "1ère réception non conforme"});
        }

        return dropdownValues;
    }

    public get brands(): IUserDefinedCode[] {
        return this.$store.state.jde.brands;
    }

    public get marketingManagers(): IUser[] {
        return this.$store.getters["app/marketingManagers"];
    }

    public get qualities(): IUserDefinedCode[] {
        return this.$store.state.jde.qualities;
    }

    public get bottlings(): IUserDefinedCode[] {
        return this.$store.state.jde.bottlings;
    }

    public get packagings(): IUserDefinedCode[] {
        return this.$store.state.jde.packagings;
    }

    public get page(): number {
        return this.$store.state.requests.page;
    }

    public set page(value: number) {
        this.$store.commit("requests/SET_ACTIVE_PAGE", value);
    }

    public get totalPages(): number {
        return this.$store.state.requests.totalPages;
    }

    public get itemCodeFilter(): string {
        return this.$store.state.requests.itemCode;
    }

    public set itemCodeFilter(value: string) {
        this.$store.commit("requests/SET_ITEM_CODE", value);
    }

    public get projectNameFilter(): string {
        return this.$store.state.requests.projectName;
    }

    public set projectNameFilter(value: string) {
        this.$store.commit("requests/SET_PROJECT_NAME", value);
    }

    public get requesterFilter(): RequestStatus {
        return this.$store.state.requests.requesterId;
    }

    public set requesterFilter(value: RequestStatus) {
        this.$store.commit("requests/SET_REQUESTER_ID_FILTER", value);
    }

    public get requestStatusFilter(): RequestStatus {
        return this.$store.state.requests.requestStatus;
    }

    public set requestStatusFilter(value: RequestStatus) {
        this.$store.commit("requests/SET_REQUEST_STATUS_FILTER", value);
    }

    public get brandCodeFilter(): string {
        return this.$store.state.requests.brandCode;
    }

    public set brandCodeFilter(value: string) {
        this.$store.commit("requests/SET_BRAND_CODE_FILTER", value);
    }

    public get marketingManagerFilter(): RequestStatus {
        return this.$store.state.requests.marketingManagerId;
    }

    public set marketingManagerFilter(value: RequestStatus) {
        this.$store.commit("requests/SET_MARKETING_MANAGER_ID_FILTER", value);
    }

    public get qualityCodeFilter(): string {
        return this.$store.state.requests.qualityCode;
    }

    public set qualityCodeFilter(value: string) {
        this.$store.commit("requests/SET_QUALITY_CODE_FILTER", value);
    }

    public get bottlingCodeFilter(): string {
        return this.$store.state.requests.bottlingCode;
    }

    public set bottlingCodeFilter(value: string) {
        this.$store.commit("requests/SET_BOTTLING_CODE_FILTER", value);
    }

    public get packagingCodeFilter(): string {
        return this.$store.state.requests.packagingCode;
    }

    public set packagingCodeFilter(value: string) {
        this.$store.commit("requests/SET_PACKAGING_CODE_FILTER", value);
    }

    public get activeEnvironment(): IEnvironment {
        return this.$store.getters["app/activeEnvironment"];
    }

    public get projectNames(): string[] {
        return this.$store.getters["app/projectNames"];
    }

    // Hook.
    @Watch("archives")
    public async onArchivesChanges(newVal: boolean, oldVal: boolean): Promise<void> {
        this.loadingData = true;
        this.page = 1;
        this.clearFilters();

        // Set default status filter
        if(this.archives && this.$store.state.requests.requestStatus === null)
        {
            this.requestStatusFilter = RequestStatus.Completed;
        }

        await this.getRequests();
        this.loadingData = false;
    }

    @Watch("activeEnvironment")
    public async onEnvironmentChanges(newVal: IEnvironment, oldVal: IEnvironment): Promise<void> {
        if (newVal?.environmentId !== oldVal?.environmentId) {
            await this.loadData();
        }
    }

    public async created(): Promise<void> {
        // Set default status filter
        if(this.$store.state.requests.archives !== this.archives)
        {
            this.clearFilters();
        }

        if(this.archives && this.$store.state.requests.requestStatus === null)
        {
            this.requestStatusFilter = RequestStatus.Completed;
        }

        await this.loadData();
    }

    public getRequestNumber(request: IRequest): string {
        return request?.newItemCode ?? "---";
    }

    public getExecutionDocumentDate(request: IRequest): Date {
        if (request) {
            return request.executionDocumentDevelopmentValidationDate ??
                request.executionDocumentSupplyDocumentValidationDate ??
                request.executionDocumentLegalValidationDate ??
                request.executionDocumentationFirstSubmissionDate;
        } else {
            return null;
        }
    }

    public getDevelopmentDocumentDate(request: IRequest): Date {
        if (request) {
            const supplierReproNotificationEmailDates: Date[] = request.devSteps?.map(ds => ds.supplierReproNotificationEmailDate) ?? [];
            return supplierReproNotificationEmailDates.length > 0 ? supplierReproNotificationEmailDates.filter(d => d).reduce((x, y) => x < y ? x : y, null) : null;
        } else {
            return null;
        }
    }

    public getDevelopmentValidationDate(requestDevStep: IRequestDevStep): Date {
        if (requestDevStep) {
            return requestDevStep.technicalDevelopmentValidationDate ??
                requestDevStep.developmentDevelopmentValidationDate ??
                requestDevStep.developmentSupplyDocumentValidationDate ??
                requestDevStep.supplierReproNotificationEmailDate;
        } else {
            return null;
        }
    }

    public isExeDocRejected(request: IRequest): boolean {
        const rejectedStatusId: string = RequestStatus.ExecutionDocumentRequestRejected.toString(); // When a validator reject Middleway set validationStatus = Workflow.RejectedStatusId
        const rejectedMarketStatusId: string = RequestStatus.MarketValidationRejected.toString();

        const developmentHasRejected: boolean = request.executionDocumentDevelopmentValidationStatus === rejectedStatusId;
        const legalHasRejected: boolean = request.executionDocumentLegalValidationStatus === rejectedStatusId;
        const supplyHasRejected: boolean = request.executionDocumentSupplyDocumentValidationStatus === rejectedStatusId;
        const marketHasRejected: boolean = request.executionDocumentSupplyDocumentValidationStatus === rejectedMarketStatusId;

        return request.requestStatus === RequestStatus.ExecutionDocumentRequestRejected ||
            request.requestStatus === RequestStatus.MarketValidationRejected ||
            developmentHasRejected ||
            legalHasRejected ||
            supplyHasRejected ||
            marketHasRejected;
    }

    public isDevDocRejected(devStep: IRequestDevStep): boolean {
        const rejectedStatusId: string = RequestStatus.DevelopmentDocumentRejected.toString(); // When a validator reject Middleway set validationStatus = Workflow.RejectedStatusId
        if (devStep.requestDevStepStatus.toString() === rejectedStatusId) {
            return true;
        }

        const developmentHasRejected: boolean = devStep.developmentDevelopmentValidationStatus === rejectedStatusId;
        const marketHasRejected: boolean = devStep.developmentMarketValidationStatus === rejectedStatusId;
        const marketingHasRejected: boolean = devStep.developmentMarketingValidationStatus === rejectedStatusId;
        const legalHasRejected: boolean = devStep.developmentLegalValidationStatus === rejectedStatusId;
        const supplyDocumentHasRejected: boolean = devStep.developmentSupplyDocumentValidationStatus === rejectedStatusId;

        return developmentHasRejected || marketHasRejected || marketingHasRejected || legalHasRejected || supplyDocumentHasRejected;
    }

    public isRequestNotCreated(request: IRequest): boolean {
        if (request?.requestStatus === RequestStatus.CodeNotCreated) {
            return true;
        }
    }

    public isRequestRejected(request: IRequest): boolean {
        if (request?.requestStatus === RequestStatus.RequestRejected) {
            return true;
        }
    }

    public isDraft(request: IRequest): boolean {
        if (request?.requestStatus === RequestStatus.Draft) {
            return true;
        }
    }

    public isTechnicalDataValidation(request: IRequest): boolean {
        return request?.requestStatus === RequestStatus.TechnicalData;
    }

    public isRequestLate(request: IRequest): string {
        if (request?.statusTimeOutDate) {
            const statusOfRequest = new Date(request?.statusTimeOutDate);
            const today = new Date();

            if ( today > statusOfRequest  ) {
                return "late";
            }
        }
    }

    public isRequestSupplierLate(request: IRequestDevStep): string {
        if (request?.statusTimeOutDate) {
            const statusOfRequest = new Date(request?.statusTimeOutDate);
            const today = new Date();

            if ( today > statusOfRequest  ) {
                return "supplier-late";
            }
        }
    }

    public getRequestLineClass(request: IRequest): string {
        if (request?.requestStatus === RequestStatus.Draft) {
            return "draft";
        } else if (request?.requestStatus === RequestStatus.Canceled) {
            return "canceled";
        } else if (request?.requestStatus === RequestStatus.RequestRejected) {
            return "rejected";
        } else if (request?.requestStatus === RequestStatus.RequestValidation) {
            return "pending-step-1";
        } else if (request?.requestStatus === RequestStatus.RequestValidated) {
            return "valid-step-1";
        } else if (request?.requestStatus === RequestStatus.CodeCreation || request?.requestStatus === RequestStatus.CodeNotCreated) {
            return "pending-step-2";
        } else if (request?.requestStatus === RequestStatus.CodeCreated) {
            return "valid-step-2";
        } else if (request?.requestStatus >= RequestStatus.ExecutionDocumentRequest && request?.requestStatus <= RequestStatus.DevelopmentDocumentRequest) {
            return "pending-step-3";
        } else if (request?.requestStatus === RequestStatus.DevelopmentDocument) {
            return "pending-step-4";
        } else if (request?.requestStatus === RequestStatus.DevelopmentDocumentValidation) {
            return "valid-step-4";
        } else if (request?.requestStatus === RequestStatus.Development) {
            return "pending-step-5";
        } else if (request?.requestStatus === RequestStatus.ControlNotCorrect) {
            return "error-step-5";
        } else if (request?.requestStatus === RequestStatus.TechnicalData) {
            return "edit-step-1";
        } else if (request?.requestStatus === RequestStatus.Completed) {
            return "completed";
        } else {
            return "";
        }
    }

    public getRequestSupplierLineClass(devStep: IRequestDevStep): string {
        if (devStep?.requestDevStepStatus === RequestStatus.TechnicalDocumentValidated) {
            return "valid-supplier";
        } else if (devStep?.requestDevStepStatus === RequestStatus.DevelopmentDocumentPending) {
            return "pending-step-4";
        } else if (devStep?.requestDevStepStatus === RequestStatus.DevelopmentDocumentValidation) {
            return "pending-step-4";
        } else if (devStep?.requestDevStepStatus === RequestStatus.DevelopmentDocumentValidated) {
            return "pending-step-4";
        } else if (devStep?.requestDevStepStatus === RequestStatus.MarketValidationPending ||
            devStep?.requestDevStepStatus === RequestStatus.MarketValidationValidated) {
            return "pending-step-4";
        } else if (devStep?.requestDevStepStatus === RequestStatus.TechnicalDocumentValidation) {
            return "pending-step-4";
        } else {
            return "";
        }
    }

    public getRequestRepresentativeStatus(request: IRequest): string {
        if (request?.requestStatus){
            if (request.requestStatus < RequestStatus.DevelopmentDocument ||
                request.requestStatus >= RequestStatus.TechnicalData) {
                return request.requestStatusDisplayName;
            } else {
                return this.getDevStepRepresentativeStatus(request.devSteps);
            }
        } else {
            return "";
        }
    }

    public getDevStepRepresentativeStatus(devSteps: IRequestDevStep[]): string {
        if (devSteps?.length){
            const max = devSteps.reduce((p, c) => p.requestDevStepStatus > c.requestDevStepStatus ? p : c);
            return max.requestDevStepStatusDisplayName;
        } else {
            return "";
        }
    }

    public isCreationCodeDisplayed(request: IRequest): boolean {
        if(request.requestStatus === RequestStatus.Canceled && request.newItemCode === null)
        {
            return false;
        }

        return RequestService.creationCodeDisplayed(request);
    }

    public isExecutionDocumentValidationDisplayed(request: IRequest): boolean {
        if(request.requestStatus === RequestStatus.Canceled && request.executionDocumentVersion === 0)
        {
            return false;
        }

        return RequestService.executionDocumentValidationDisplayed(request);
    }

    public isDevelopmentDocumentRequestDisplayed(request: IRequest): boolean {
        if(request.requestStatus === RequestStatus.Canceled && request.devSteps.every(ds => ds.supplierReproNotificationEmailDate == null))
        {
            return false;
        }

        return RequestService.developmentDocumentRequestDisplayed(request);
    }

    public isDevelopmentValidationDisplayed(request: IRequest): boolean {
        if(request.requestStatus === RequestStatus.Canceled
            && request.devSteps.every(ds => ds.requestDevStepStatus < RequestStatus.DevelopmentDocumentValidation))
        {
            return false;
        }

        return RequestService.developmentValidationDisplayed(request);
    }

    public isFirstReceptionCheckDisplayed(request: IRequest): boolean {
        if(request.requestStatus === RequestStatus.Canceled
            && request.devSteps.every(ds => ds.requestDevStepStatus < RequestStatus.TechnicalDocumentValidated))
        {
            return false;
        }

        return RequestService.firstReceptionCheckDisplayed(request);
    }

    public isBATValidationExpired(request: IRequest): boolean {
        return request?.devSteps.some(x => x.requestDevStepStatus === RequestStatus.DevelopmentDocumentExpired || x.requestDevStepStatus === RequestStatus.MarketValidationExpired);
    }

    public isBATRejected(request: IRequest): boolean {
        return request?.devSteps.some(x => x.requestDevStepStatus === RequestStatus.MarketValidationRejected);
    }

    public isDevWaitingMarketRequest(request: IRequest): boolean {
        const isDevelopmentDocumentValidated: boolean = request.devSteps.some(step => step.requestDevStepStatus === RequestStatus.DevelopmentDocumentValidated);
        return request?.needMarketValidation && isDevelopmentDocumentValidated;
    }

    public isDevWaitingValidation(request: IRequest): boolean {
        const isTechnicalValidate: boolean = request.devSteps.some(step => step.requestDevStepStatus === RequestStatus.TechnicalDocumentValidated);
        const isStatusBeforeTechnical: boolean = request.requestStatus < RequestStatus.TechnicalData;

        return isTechnicalValidate && isStatusBeforeTechnical;
    }

    public async navigateToPage(page: number): Promise<void> {
        this.loadingData = true;
        this.page = page;
        await this.getRequests();
        this.loadingData = false;
    }

    public async filterRequests(): Promise<void> {
        this.loadingData = true;
        await this.getRequests();
        this.loadingData = false;
    }

    public async clearFiltersThenGetRequest(): Promise<void> {
        this.loadingData = true;
        this.clearFilters();
        await this.getRequests();
        this.loadingData = false;
    }

    public getLastControl(request: IRequest): IControl {
        if (request.controls && request.controls.length > 0 ){
            const validatedControl = request.controls.find(c => c.controlIsCorrect);
            if (validatedControl) {
                return validatedControl;
            } else {
                return request.controls.reduce((a, b) => (a.controlDate > b.controlDate ? a : b));
            }
        } else {
            const control: IControl = RequestService.createControl(0, null);
            control.controlDate = null;
            return control;
        }
    }

    public showThumbnailModal(value: IDocument): void {
        this.thumbnailDocument = value;
        this.$modal.show("thumbnailModal");
    }

    private clearFilters(): void {
        this.itemCodeFilter = null;
        this.projectNameFilter = null;
        this.requesterFilter = null;
        this.requestStatusFilter = null;
        this.brandCodeFilter = null;
        this.marketingManagerFilter = null;
        this.qualityCodeFilter = null;
        this.bottlingCodeFilter = null;
        this.packagingCodeFilter = null;
        this.packagingNameFilter = null;
    }

    private getRequests(): Promise<void> {
        const query: [string, string][] = [];
        query.push(["environment", this.activeEnvironment?.code]);
        query.push(["limit", String(this.$store.state.requests.limit)]);
        query.push(["page", String(this.page)]);
        query.push(["archives", String(this.archives)]);
        if (this.itemCodeFilter?.length > 0){
            query.push(["itemCode", this.itemCodeFilter]);
        }

        if (this.projectNameFilter?.length > 0){
            query.push(["projectName", this.projectNameFilter]);
        }

        if (this.requesterFilter){
            query.push(["requesterId", String(this.requesterFilter)]);
        }

        if (this.requestStatusFilter){
            query.push(["requestStatus", String(this.requestStatusFilter)]);
        }

        if (this.brandCodeFilter){
            query.push(["brandCode", String(this.brandCodeFilter)]);
        }

        if (this.marketingManagerFilter){
            query.push(["marketingManagerId", String(this.marketingManagerFilter)]);
        }

        if (this.qualityCodeFilter){
            query.push(["qualityCode", String(this.qualityCodeFilter)]);
        }

        if (this.bottlingCodeFilter){
            query.push(["bottlingCode", String(this.bottlingCodeFilter)]);
        }

        if (this.packagingCodeFilter){
            query.push(["packagingCode", String(this.packagingCodeFilter)]);
        }

        return this.$store.dispatch("requests/getRequests", query);
    }

    private async loadData(): Promise<void> {
        this.loadingData = true;
        const promises: Promise<any>[] = [];
        if (!this.$store.state.requests.requestsLoaded || this.archives !== this.$store.state.requests.archives) {
            promises.push(this.getRequests());
        }

        if (!this.$store.state.app.usersLoaded) {
            promises.push(this.$store.dispatch("app/getUsers"));
        }

        if (this.$store.state.app.requestStatus?.length < 1) {
            promises.push(this.$store.dispatch("app/getRequestStatus"));
        }

        if (this.$store.state.app.projectNames?.length < 1) {
            promises.push(this.$store.dispatch("app/getProjectNames"));
        }

        const supplyChainsToLoad = [
            SupplyChainItemType.Bottlings,
            SupplyChainItemType.Brands,
            SupplyChainItemType.Packagings,
            SupplyChainItemType.Qualities
        ];

        for (const s of supplyChainsToLoad) {
            if (!(this.$store.state.jde.loadedSupplyChains as any).find((x: SupplyChainItemType) => x === s)) {
                promises.push(this.$store.dispatch("jde/getSupplyChains", this.getJdeParameters(s)));
            }
        }

        await Promise.all(promises);
        this.loadingData = false;
    }

    private getJdeParameters<T>(value: T): IActionEnvironmentParameter<T> {
        return {
            environmentCode: this.activeEnvironment?.code,
            value,
        };
    }
}
