import { Environments } from "@/constants";
import { IRequest } from "@/models";
import { RuleDecl } from "vue/types/options";
import { maxLength, required, requiredIf, minValue, maxValue, integer, numeric } from "vuelidate/lib/validators";

export const validations: RuleDecl = {
    vm: {
        validationDate: { required },
        validatorId: { required },
        newItemCode: {
            required,
            maxLength: maxLength(50),
        },
        glKeyCode: { required: isNotNull },
        glKeyLabel: { },
        eanCode: { maxLength: maxLength(25) },
        skuCode: { maxLength: maxLength(25) },
        description1: { required, maxLength: maxLength(30) },
        description2: { maxLength: maxLength(30) },
        searchingText: { required, maxLength: maxLength(30) },
        itemTypeCode: { required: isNotNull },
        itemTypeLabel: { },
        masterPlanningFamilyCode: { required: isNotNull },
        masterPlanningFamilyLabel: { },
        supplyTimePeriod: { integer, minValue: minValue(0), maxValue: maxValue(9999999) },
        vapCode: { },
        vapLabel: { },
        procurementOfficerCode: { required: isNotNull },
        procurementOfficerLabel: { },
        warehouseCode: { required: isNotNull },
        warehouseLabel: { },
        mainLocationLabel: { required: isNotNull },
        accountingGroupCode: { required: isNotNull, maxLength: maxLength(50) },
        accountingGroupLabel: {},
        amalgamCode: { required: requiredIf(isCognacEnvironment) },
        amalgamLabel: { required: requiredIf(isCognacEnvironment) },
        brandOwnerCode: { required: isNotNull, maxLength: maxLength(50) },
        brandOwnerLabel: {},
        productTypeCode: { required: isNotNull, maxLength: maxLength(50) },
        productTypeLabel: {},
    },
};

function isNotNull(value: string): boolean {
    // Undefined is blacklisted by eslint.
    return value !== null && typeof value !== "undefined";
}

function isCognacEnvironment(value: IRequest): boolean {
    return Environments.Cognac.trimContains(value.environmentDisplayName);
}
