import Vue from "vue";
import { Component, Model, Prop } from "vue-property-decorator";
import vSelect from "vue-select";
import { AxiosRequestConfig } from "axios";
import { AxiosService } from "@/services";
import { IUser } from "@/models";

@Component({
    name: "people-picker",
    components: {
        "v-select": vSelect,
    },
})
export default class PeoplePicker extends Vue {
    public static readonly inputEvent = "input";

    @Model(PeoplePicker.inputEvent)
    public selectedUsers: IAadUserOption[];

    @Prop({ required: false, default: false })
    public multiple: boolean;

    public searchTimer = -1;

    public aadUsers: IAadUserOption[] = [];

    public get users(): IUser[] {
        return this.$store.state.app.users ?? [];
    }

    // Hook.
    public async created(): Promise<void> {
        const promises: Promise<any>[] = [];
        if (!this.$store.state.app.usersLoaded) {
            promises.push(this.$store.dispatch("app/getUsers"));
        }

        await Promise.all(promises);
    }


    public async searchUsers(search: string, loading: (loading: boolean) => void): Promise<void> {
        // Search with at least 3 characters
        if (search.length >= 3) {
            loading(true);
            clearTimeout(this.searchTimer);

            this.searchTimer = setTimeout(() => {
                this.onSubmitSearch(search, loading);
            }, 800);
        }
    }

    public onSelectUsers(users: IAadUserOption[]): void {
        this.$emit(PeoplePicker.inputEvent, users);
    }

    public getUserLabel(user: IAadUserOption): string {
        let label = user.name;

        if (user.email != null) {
            label += ` (${user.email})`;
        }

        return label;
    }

    public clearOptions(): void {
        this.aadUsers = [];
    }

    public get placeholder(): string {
        return "Entrez les 3 premières lettres de l'utilisateur à ajouter...";
    }

    private async onSubmitSearch(search: string, loading: (loading: boolean) => void): Promise<void> {
        const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
        const query = AxiosService.buildQueryParameters([["searchText", search]]);
        const response = await Vue.axios.get<IUser[]>(`${process.env.VUE_APP_WEB_API_BASE_URL}/users/graph${query}`, config);
        const graphUsers: IUser[] = response.data;
        this.aadUsers = graphUsers.filter(u => !this.users.some(x => x.uniqueId.localeCompare(u.uniqueId) === 0));
        loading(false);
    }
}

interface IAadUserOption {
    name: string;
    email: string;
    uniqueId: string;
}
