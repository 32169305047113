import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { IRequest, RequestStatus, IUser, AssignmentType } from "@/models";
import { LocalDatePicker, LoaderSpinner } from "@/components";
import CreationRequest from "./creationRequest/CreationRequest.vue";
import CreationCode from "./creationCode/CreationCode.vue";
import ExecutionDocumentationValidation from "./executionDocumentationValidation/ExecutionDocumentationValidation.vue";
import DevelopmentDocumentRequest from "./developmentDocumentRequest/DevelopmentDocumentRequest.vue";
import DevelopmentValidation from "./developmentValidation/DevelopmentValidation.vue";
import FirstReceptionCheck from "./firstReceptionCheck/FirstReceptionCheck.vue";

import { RequestService, NotificationService } from "@/services";

@Component({
    name: "edit-request",
    components: { CreationRequest, CreationCode, ExecutionDocumentationValidation, DevelopmentDocumentRequest, LocalDatePicker, LoaderSpinner, DevelopmentValidation, FirstReceptionCheck },
})
export default class EditRequestView extends Vue {
    @Prop(Number)
    public requestId: number;

    @Prop(String)
    public supplierToDisplay: string;

    public creationRequestExpanded: boolean;
    public creationCodeExpanded: boolean;
    public executionDocumentValidationExpanded: boolean;
    public developmentDocumentRequestExpanded: boolean;
    public developmentValidationExpanded: boolean;
    public firstReceptionCheckExpanded: boolean;
    public isEditButtonVisible: boolean;
    public dataLoaded: boolean;
    public pendingAction: boolean;
    public pendingWorkflowCancellation: boolean;

    public constructor() {
        super();

        this.creationCodeExpanded = false;
        this.creationRequestExpanded = false;
        this.dataLoaded = false;
        this.developmentDocumentRequestExpanded = false;
        this.developmentValidationExpanded = false;
        this.executionDocumentValidationExpanded = false;
        this.firstReceptionCheckExpanded = false;
        this.isEditButtonVisible = false;
        this.pendingAction = false;
        this.pendingWorkflowCancellation = false;
    }

    public get activeRequest(): IRequest {
        return this.$store.getters["requests/activeRequest"];
    }

    public get user(): IUser {
        return this.$store.getters["app/currentUser"];
    }

    private get activeEnvironmentCode(): string {
        return this.$store.getters["app/activeEnvironment"]?.code;
    }

    public get isKeyUser(): boolean
    {
        return this.user?.userAssignments?.some(
            a =>
                a.assignmentType === AssignmentType.Administrator
                && a.environmentCode === this.activeEnvironmentCode
        ) === true;
    }

    public get newRequest(): boolean {
        // Null request considered as new.
        return !(this.activeRequest?.requestId > 0);
    }

    public get hasActiveWorkflow(): boolean {
        return !(this.activeRequest?.workflowApprovalLink === null);
    }

    public get canBeCancelled(): boolean {
        const userConstraint: boolean = this.user?.userId === this.activeRequest?.requesterId;
        const statusConstraint: boolean = this.activeRequest?.requestStatus !== null && this.activeRequest?.requestStatus !== RequestStatus.Canceled;
        const devStepWorkflowConstraint = !(this.activeRequest?.devSteps.some(ds => ds.workflowApprovalLink !== null));

        return userConstraint && statusConstraint && devStepWorkflowConstraint;
    }

    public get title(): string {
        if(this.pendingWorkflowCancellation) {
            return "Attente de l'annulation de l'étape en cours";
        }
        else if (!this.newRequest) {
            return "Requête : " + (this.activeRequest?.newItemCode ?? "---");
        }
        else {
            return "Nouvelle requête";
        }
    }

    public get creationCodeDisplayed(): boolean {
        return RequestService.creationCodeDisplayed(this.$store.getters["requests/activeRequest"]);
    }

    public get executionDocumentValidationDisplayed(): boolean {
        return RequestService.executionDocumentValidationDisplayed(this.$store.getters["requests/activeRequest"]);
    }

    public get developmentDocumentRequestDisplayed(): boolean {
        return RequestService.developmentDocumentRequestDisplayed(this.$store.getters["requests/activeRequest"]);
    }

    public get developmentValidationDisplayed(): boolean {
        return RequestService.developmentValidationDisplayed(this.$store.getters["requests/activeRequest"]);
    }

    public get firstReceptionCheckDisplayed(): boolean {
        return RequestService.firstReceptionCheckDisplayed(this.$store.getters["requests/activeRequest"]);
    }

    @Watch("requestId")
    public onUpdateRequest(){
        this.getRequest();
    }

    public async cancelRequest(): Promise<void> {
        this.pendingAction = true;

        const currentStatus = this.activeRequest.requestStatus;
        this.activeRequest.requestStatus = RequestStatus.Canceled;
        const response: IRequest = await this.$store.dispatch("requests/createOrUpdateRequest", this.activeRequest);
        if (response) {
            this.$router.push("/");
        } else {
            this.activeRequest.requestStatus = currentStatus;
        }

        this.pendingAction = false;
    }

    public async cancelRequestWorkflow(): Promise<void> {
        this.pendingAction = true;
        this.pendingWorkflowCancellation = true;
        const currentStatus: string = this.activeRequest.requestStatusDisplayName;

        window.open(this.activeRequest.workflowApprovalLink,"_blank");

        while(this.activeRequest.workflowApprovalLink !== null)
        {
            await this.$store.dispatch("requests/getSingleRequest", this.requestId);

            await this.delay(2000);
        }

        NotificationService.showSuccess("Etape '"+ currentStatus +"' annulée.");

        this.pendingAction = false;
        this.pendingWorkflowCancellation = false;
    }

    // Hook.
    public created(): void {
        this.getRequest();
    }

    private async getRequest(): Promise<void> {
        this.dataLoaded = false;
        await this.$store.dispatch("requests/getSingleRequest", this.requestId);

        if (this.activeRequest?.environmentId &&
            this.activeRequest.environmentId !== this.$store.getters["app/activeEnvironment"]?.environmentId) {
            await this.$store.dispatch("app/setActiveEnvironmentId", this.activeRequest?.environmentId);
        }

        if (this.$store.getters["requests/activeRequest"] && this.$store.getters["app/activeEnvironment"]) {
            this.dataLoaded = true;
        } else {
            this.$router.push({name: "not-found"});
        }

        // Expand sections according hash.
        const hash = this.$route.hash;
        this.creationRequestExpanded = this.newRequest || hash === "#step1";
        this.creationCodeExpanded = hash === "#step2";
        this.executionDocumentValidationExpanded = hash === "#step3a";
        this.developmentDocumentRequestExpanded = hash === "#step3b";
        this.developmentValidationExpanded = hash === "#step4";
        this.firstReceptionCheckExpanded = hash === "#step5";

        this.isEditButtonVisible = this.isKeyUser && this.activeRequest?.requestStatus > RequestStatus.Draft && this.activeRequest?.requestStatus < RequestStatus.TechnicalData;
    }

    private delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }
}
