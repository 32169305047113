import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
    IRequest,
    RequestStatus,
    IUserAssignment,
    AssignmentType,
    IPurchaseOrder,
    SupplyChainItemType,
    IUserDefinedCode,
    IUser,
    IActionEnvironmentParameter,
    IControl,
    DocumentType,
    IDocument
} from "@/models";

import { LocalDatePicker, LoaderSpinner } from "@/components";
import { validations } from "./validations";
import { AxiosService, NotificationService, RequestService } from "@/services";
import vSelect from "vue-select";
import { IFirstReceptionViewModel, IControlViewModel } from "./IFirstReceptionViewModel";
import { AxiosRequestConfig } from "axios";

@Component({
    name: "first-reception-check",
    components: {
        "vue-select": vSelect, LocalDatePicker, LoaderSpinner
    },
    validations,
})
export default class FirstReceptionCheck extends Vue {
    @Prop({ required: true, type: Object })
    public model: IRequest;

    @Prop({ required: true, default: false })
    public expanded: boolean;

    public vm: IFirstReceptionViewModel;
    public pendingAction: boolean;
    public loadingData: boolean;

    public constructor() {
        super();

        this.vm = this.createViewModel();
        this.pendingAction = false;
        this.loadingData = false;
    }

    public get user(): IUser {
        return this.$store.getters["app/currentUser"];
    }

    public get readonly(): boolean {
        const statusConstraint: boolean = this.vm.requestStatus > RequestStatus.ControlNotCorrect;
        return statusConstraint || !this.userHasWriteAssignment;
    }

    public get canCreateNewControl(): boolean {
        return this.userHasWriteAssignment &&
            this.vm.requestStatus > RequestStatus.Development &&
            this.vm.controls.length > 0 &&
            this.vm.controls.every(c => c.controlIsCorrect === false && c.controlId > 0 );
    }

    public get purchaseOrders(): IPurchaseOrder[] {
        const purchaseOrders: IPurchaseOrder[] = this.$store.state.jde.purchaseOrders;
        if (purchaseOrders?.length > 0 && this.vm?.newItemCode) {
            return purchaseOrders.filter(p => p.itemCode.trimEquals(this.vm.newItemCode));
        } else {
            return [];
        }
    }

    public get warehouseCodes(): IUserDefinedCode[] {
        return this.$store.state.jde.warehouseCodes;
    }

    private get activeEnvironmentCode(): string {
        return this.$store.getters["app/activeEnvironment"]?.code;
    }

    private get userHasWriteAssignment(): boolean {
        return this.user?.userAssignments?.some((a: IUserAssignment) =>
            a.environmentCode === this.activeEnvironmentCode &&
            a.assignmentType === AssignmentType.Controller) === true;
    }

    public get fileExtensions(): string {
        return RequestService.imageFileExtensions;
    }

    public async submitRequest(): Promise<void> {
        this.pendingAction = true;
        if (this.validateBeforeSubmit()) {
            const currentStatus = this.vm.requestStatus;
            this.vm.requestStatus = this.vm.controls.some(c => c.controlIsCorrect) ?
                RequestStatus.Completed :
                RequestStatus.ControlNotCorrect;

            const response: IRequest = await this.$store.dispatch(
                "requests/createOrUpdateRequest",
                this.vm);

            if (response) {
                // mise à jour des fichiers pour le dernier contrôle
                const index = this.vm.controls.length - 1;
                for (const doc of this.vm.controls[index].imageDocuments) {
                    doc.controlId = response.controls[index].controlId;
                    await RequestService.uploadDocument(doc);
                }

                this.$router.push("/");
            } else {
                this.vm.requestStatus = currentStatus;
            }
        } else {
            NotificationService.showError("Le formulaire contient des erreurs");
        }
        this.pendingAction = false;
    }

    public createNewControl() {
        const control: IControl = RequestService.createControl(this.vm.requestId, this.user);
        this.vm.controls.push(this.createControlViewModel(control, this.vm.newItemCode));
        this.selectTab(control);
    }

    public setDefaultNumberToNull(event: Event & { target: HTMLInputElement }, control: IControlViewModel) {
        if (event.target.value === "" && control) {
            this.$set(control, event.target.id, null);
        }
    }

    // Hook.
    public async created(): Promise<void> {
        this.loadingData = true;
        if (this.vm.controls.length < 1) {
            const currentUser: IUser = this.$store.getters["app/currentUser"];
            const control: IControl = RequestService.createControl(this.vm.requestId, !this.readonly ? currentUser : null);
            this.vm.controls.push(this.createControlViewModel(control, this.vm.newItemCode));
        }

        // Select last tab.
        this.vm.controls[this.vm.controls.length - 1].displayed = true;

        await this.loadData();
        this.loadingData = false;
    }

    public async updatePurchaseOrder(
        control: IControl,
        value: IPurchaseOrder): Promise<void> {
        control.estimatedDeliveryDate = value?.estimatedDeliveryDate ?? null;
        control.firstOrderedQuantity = value?.firstOrderedQuantity ?? null;
        control.orderDate = value?.inputInJDEDate ?? null;
        control.orderNumber = value?.orderNumber ?? null;
        control.orderMainLocation = value?.receptionLocation ?? null;
        const code: string = value?.store?.trim() ?? null;
        control.orderWarehouseCode = code;
        control.orderWarehouseLabel = this.warehouseCodes.find(w => w.code.trimEquals(code))?.label
            ?? null;
    }

    public selectTab(tab: IControl) {
        this.vm.controls.forEach(c => c.displayed = c.controlId === tab.controlId);
    }

    public getOrderWarehouseCode(control: IControl): string {
        const code = control?.orderWarehouseCode?.trim();
        if (control.orderWarehouseLabel) {
            return `${code} - ${control.orderWarehouseLabel}`;
        } else {
            return code;
        }
    }

    public controlIsReadonly(control: IControl) {
        return this.readonly || control?.controlId > 0;
    }

    public updateControlIsCorrect(control: IControl) {
        if (control?.controlIsCorrect === true) {
            control.uncomplianceDescription = null;
            control.defectBlockerVolume = null;
            control.defectCriticalVolume = null;
            control.defectMajorVolume = null;
            control.defectMinorVolume = null;
        }
    }

    public async addImageDocument(control: IControl, event: any): Promise<void> {
        if (event.target.files.length > 0) {
            control.imageDocuments.push({
                documentId: 0,
                documentType: DocumentType.Image,
                requestId: control.requestId,
                requestDevStepId: null,
                controlId: control.controlId,
                fileKey: null,
                fileName: event.target.files[0].name,
                fileUri: null,
                uploadDate: new Date(),
                uploaderId: this.user.userId,
                uploaderName: this.user.name,
                uploaderEmail: this.user.email,
                file: event.target.files[0],
                thumbnail: null,
            });
        }
    }

    public async removeImageDocument(control: IControl, documentId: number): Promise<void> {
        const index = control.imageDocuments.findIndex(x => x.documentId === documentId);
        if (index !== -1) {
            control.imageDocuments.splice(index, 1);
        }
    }

    private async loadData(): Promise<void> {
        const promises: Promise<any>[] = [];
        if (!this.readonly) {
            promises.push(this.$store.dispatch(
                "jde/getPurchaseOrders",
                this.getJdeParameters(this.vm.newItemCode)));

            const supplyChainsToLoad = [SupplyChainItemType.WarehouseCodes];
            for (const s of supplyChainsToLoad) {
                if (!(this.$store.state.jde.loadedSupplyChains as any).find((x: SupplyChainItemType) => x === s)) {
                    promises.push(this.$store.dispatch("jde/getSupplyChains", this.getJdeParameters(s)));
                }
            }
        }

        await Promise.all(promises);
    }

    private getJdeParameters<T>(value: T): IActionEnvironmentParameter<T> {
        return {
            environmentCode: this.activeEnvironmentCode,
            value,
        };
    }

    private createViewModel(): IFirstReceptionViewModel {
        const controls: IControlViewModel[] =
            this.model.controls?.map(c => this.createControlViewModel(c, this.model.newItemCode))
            ?? [];

        const vm: IFirstReceptionViewModel = {
            ...this.model,
            controls
        };

        return vm;
    }

    private createControlViewModel(control: IControl, itemCode: string): IControlViewModel {
        return {
            ...control,
            displayed: false,
            purchaseOrder: {
                estimatedDeliveryDate: control.estimatedDeliveryDate,
                firstOrderedQuantity: control.firstOrderedQuantity,
                inputInJDEDate: control.orderDate,
                itemCode,
                orderNumber: control.orderNumber,
                receptionLocation: control.orderMainLocation,
                store: control.orderWarehouseCode,
            },
        };
    }

    private validateBeforeSubmit(): boolean {
        this.$v.vm.$touch();
        return !this.$v.vm.$invalid;
    }
}
