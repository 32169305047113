const GET_REQUESTS = "GET_REQUESTS";
const SET_AMALGAM_CODE = "SET_AMALGAM_CODE";
const GET_SINGLE_REQUEST = "GET_SINGLE_REQUEST";
const SET_ACTIVE_REQUEST_ID = "SET_ACTIVE_REQUEST_ID";
const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
const SET_PROJECT_NAME = "SET_PROJECT_NAME";
const SET_ITEM_CODE = "SET_ITEM_CODE";
const SET_REQUESTER_ID_FILTER = "SET_REQUESTER_ID_FILTER";
const SET_REQUEST_STATUS_FILTER = "SET_REQUEST_STATUS_FILTER";
const SET_BRAND_CODE_FILTER = "SET_BRAND_CODE_FILTER";
const SET_MARKETING_MANAGER_ID_FILTER = "SET_MARKETING_MANAGER_ID_FILTER";
const SET_QUALITY_CODE_FILTER = "SET_QUALITY_CODE_FILTER";
const SET_BOTTLING_CODE_FILTER = "SET_BOTTLING_CODE_FILTER";
const SET_PACKAGING_CODE_FILTER = "SET_PACKAGING_CODE_FILTER";
const RESET_STATE = "RESET_STATE";

export {
    SET_AMALGAM_CODE,
    GET_REQUESTS,
    GET_SINGLE_REQUEST,
    SET_ACTIVE_REQUEST_ID,
    SET_ACTIVE_PAGE,
    SET_ITEM_CODE,
    SET_PROJECT_NAME,
    SET_REQUESTER_ID_FILTER,
    SET_REQUEST_STATUS_FILTER,
    SET_BRAND_CODE_FILTER,
    SET_MARKETING_MANAGER_ID_FILTER,
    SET_QUALITY_CODE_FILTER,
    SET_BOTTLING_CODE_FILTER,
    SET_PACKAGING_CODE_FILTER,
    RESET_STATE
};
