
const GET_ASSIGNMENT_TYPES = "GET_ASSIGNMENT_TYPES";
const GET_DOCUMENTATIONS = "GET_DOCUMENTATIONS";
const GET_ENVIRONMENTS = "GET_ENVIRONMENTS";
const GET_ITEM_ORIGINS = "GET_ITEM_ORIGINS";
const GET_GL_KEYS = "GET_GL_KEYS";
const GET_MAIL_CONTENTS = "GET_MAIL_CONTENTS";
const GET_PROJECT_NAMES = "GET_PROJECT_NAMES";
const GET_REQUEST_STATUS = "GET_REQUEST_STATUS";
const GET_SINGLE_USER = "GET_SINGLE_USER";
const GET_USERS = "GET_USERS";
const LOGOUT = "LOGOUT";
const SET_ACTIVE_ENVIRONMENT_ID = "SET_ACTIVE_ENVIRONMENT_ID";

export {
    GET_ASSIGNMENT_TYPES,
    GET_DOCUMENTATIONS,
    GET_ENVIRONMENTS,
    GET_ITEM_ORIGINS,
    GET_GL_KEYS,
    GET_MAIL_CONTENTS,
    GET_PROJECT_NAMES,
    GET_REQUEST_STATUS,
    GET_SINGLE_USER,
    GET_USERS, LOGOUT,
    SET_ACTIVE_ENVIRONMENT_ID,
};
