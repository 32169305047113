import Vue from "vue";
import { Component } from "vue-property-decorator";
import { IManageViewModel, IManageTabViewModel, IManageTab } from "./IManageViewModel";
import ManageUsers from "./users/ManageUsers.vue";
import ManageEmails from "./emails/ManageEmails.vue";

@Component({
    name: "manage-view",
    components: { ManageUsers, ManageEmails },
})
export default class ManageView extends Vue {
    public vm: IManageViewModel;

    public constructor() {
        super();

        this.vm = this.createViewModel();
    }

    public changeTab(tab: IManageTabViewModel): void {
        this.vm.tabs.forEach(t => t.activeTab = false);
        tab.activeTab = true;
    }

    private createViewModel(): IManageViewModel {
        return {
            tabs: [...tabs.map((t: IManageTab, i: number) => ({
                ...t,
                activeTab: i === 0,
                key: i,
            }))]
        };
    }
}

const tabs: IManageTab[] = [
    {
        name: "Utilisateurs",
        component: "ManageUsers",
    },
    {
        name: "Emails",
        component: "ManageEmails",
    },
];
