import { RuleDecl } from "vue/types/options";
import { required, requiredIf } from "vuelidate/lib/validators";
import { IRequest } from "@/models";
import { FormValidations } from "@/constants";

const fileSizeValidation = (value, vm) => {
    if (value?.file?.size) {
        return value.file.size < FormValidations.FileMaxSize;
    } else {
        return true;
    }
};

const fileNameInvalidCharacters = (value, vm) => {
    if (value?.fileName) {
        const pattern = /^[^"*:<>?/\\]*$/;
        return pattern.test(value.fileName);
    } else {
        return true;
    }
};

export const validations: RuleDecl = {
    vm: {
        executionDocumentationFirstSubmissionDate: { required },
        marketingManagerId: { required },
        executionDocument: {
            required: requiredIf(hasMarketingExecDoc),
            fileSizeValidation,
            fileNameInvalidCharacters,
        }
    }
};

function hasMarketingExecDoc(value: IRequest): boolean {
    return value?.hasMarketingExecDoc ?? true;
}
