import Vue from "vue";
import Router from "vue-router";
import { authGuard } from "./AuthGuard";
import { assignmentGuard } from "./AssignmentGuard";
import { requestIdGuard } from "./RequestIdGuard";
import { requestDevStepIdGuard } from "./RequestDevStepIdGuard";
import { EditRequestView, ErrorView, HomeView, NotFoundView, UnauthorizedView, ManageView, DocumentationView, UploadDevelopmentDocument, UploadTechnicalDocument } from "@/views";
import { AssignmentType } from "@/models";

Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "home",
            component: HomeView,
            meta: {
                requiresAuth: true,
                excludedAssignments: [AssignmentType.Supplier, AssignmentType.Repro],
            },
        },
        {
            path: "/requests/new",
            name: "new-request",
            component: EditRequestView,
            meta: {
                requiresAuth: true,
                requiredAssignments: [AssignmentType.Supply, AssignmentType.Development],
            },
            props: { requestId: 0 },
        },
        {
            path: "/archives",
            name: "archives",
            component: HomeView,
            meta: {
                requiresAuth: true,
                excludedAssignments: [AssignmentType.Supplier, AssignmentType.Repro],
            },
            props: { archives: true },
        },
        {
            path: "/reporting",
            beforeEnter() { window.open(process.env.VUE_APP_REPORTING_URL); },
        },
        {
            path: "/documentation",
            name: "documentation",
            component: DocumentationView,
            meta: {
                requiresAuth: true,
                excludedAssignments: [AssignmentType.Supplier, AssignmentType.Repro],
            },
        },
        {
            path: "/requests/:requestId/",
            name: "edit-request",
            component: EditRequestView,
            meta: {
                requiresAuth: true,
                excludedAssignments: [AssignmentType.Supplier, AssignmentType.Repro],
            },
            props: getRequestEditProp,
            beforeEnter: requestIdGuard,
        },
        {
            path: "/requests/:requestId/developmentDocument/:requestDevStepId/",
            name: "UploadDevelopmentDocument",
            component: UploadDevelopmentDocument,
            meta: {
                requiresAuth: true,
                requiredAssignments: [AssignmentType.Administrator, AssignmentType.Supplier, AssignmentType.Repro],
            },
            props: getRequestDevStepIds,
            beforeEnter: requestDevStepIdGuard,
        },
        {
            path: "/requests/:requestId/technicalDocument/:requestDevStepId",
            name: "UploadTechnicalDocument",
            component: UploadTechnicalDocument,
            meta: {
                requiresAuth: true,
                requiredAssignments: [AssignmentType.Administrator, AssignmentType.Supplier],
            },
            props: getRequestDevStepIds,
            beforeEnter: requestDevStepIdGuard,
        },
        {
            path: "/manage/",
            name: "Manage",
            component: ManageView,
            meta: {
                requiresAuth: true,
                requiredAssignments: [AssignmentType.Administrator],
            },
        },
        {
            path: "/auth",
            redirect: "/",
        },
        {
            path: "/unauthorized",
            name: "unauthorized",
            component: UnauthorizedView,
        },
        {
            path: "/error",
            name: "error",
            component: ErrorView,
            props: true,
        },
        {
            path: "/404",
            name: "not-found",
            component: NotFoundView,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "*",
            redirect: "/404",
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (!to.hash) {
            return { x: 0, y: 0 };
        } else {
            return new Promise((resolve, reject) => {
                setTimeout(() => resolve({ selector: to.hash }), 500);
            });
        }
    },
});

function getRequestEditProp(route: any): object {
    return {
        requestId: Number(route.params.requestId),
        supplierToDisplay: route.params.supplierToDisplay,
    };
}

function getRequestDevStepIds(route: any): object {
    return {
        requestId: Number(route.params.requestId),
        requestDevStepId: Number(route.params.requestDevStepId),
    };
}

router.beforeEach(authGuard);
router.beforeEach(assignmentGuard);

export { router };
