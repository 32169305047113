import { Component, Vue, Prop } from "vue-property-decorator";
import { fr } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { mixins } from "vue-class-component";

@Component({
    name: "local-datepicker",
    components: {},
})
export default class LocalDatePicker extends mixins(Datepicker) {
    @Prop({ default: true })
    public calendarButton: boolean;

    @Prop({ default: "dd MMM yyyy" })
    public format: string;

    @Prop({ default: true })
    public fullMonthName: boolean;

    @Prop({ default: true })
    public mondayFirst: boolean;

    @Prop({ default: () => fr })
    public language: boolean;

    @Prop({ default: "calendar-picto" })
    public calendarButtonIcon: string;
}
