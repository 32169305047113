import { GetterTree } from "vuex";
import { IRequestsState } from "./state";
import { IRequest } from "@/models";

const getters: GetterTree<IRequestsState, any> = {
    activeRequest(state: IRequestsState): IRequest {
        return state.requests?.find(r => r.requestId === state.activeRequestId) ?? null;
    },

    requests(state: IRequestsState): IRequest[] {
        return state.requests?.filter(r => r.requestId > 0 && r.isArchived === state.archives) ?? [];
    },
};

export { getters };
