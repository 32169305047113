import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { IRequest, IRequestDevStep, IUser, ItemOrigin, IWorkflowValidation, WorkflowValidationResponse, IMailContent, RequestStatus, AssignmentType, IUserAssignment } from "@/models";
import { LocalDatePicker, LoaderSpinner, UserDefinedCodeSelect } from "@/components";
import vSelect from "vue-select";
import { validations } from "./validations";
import { IDevelopmentValidationViewModel } from "./IDevelopmentValidationViewModel";
import { NotificationService, RequestService } from "@/services";

@Component({
    name: "development-validation",
    components: { LocalDatePicker, LoaderSpinner, "vue-select": vSelect, UserDefinedCodeSelect },
    validations,
})
export default class DevelopmentValidation extends Vue {
    @Prop({ required: true, type: Object })
    public model: IRequest;

    @Prop({ required: true, default: false })
    public expanded: boolean;

    @Prop(String)
    public supplierToDisplay: string;

    public vm: IDevelopmentValidationViewModel;
    public pendingAction: boolean;
    public pendingStatus: boolean;
    public selectedDevStep: IRequestDevStep;
    public devWorkflowValidations: IWorkflowValidation[] = [];
    public techWorkflowValidations: IWorkflowValidation[] = [];

    public constructor() {
        super();

        this.vm = this.createViewModel();
        this.pendingAction = false;
        this.pendingStatus = false;
        this.selectedDevStep = null;

        // Select first tab.
        if (this.vm.devSteps && this.vm.devSteps.length > 0) {
            if (this.supplierToDisplay && this.vm.devSteps.some(ds => ds.supplierCode.trimEquals(this.supplierToDisplay))) {
                this.vm.devSteps.find(ds => ds.supplierCode.trimEquals(this.supplierToDisplay)).displayed = true;
            } else {
                this.vm.devSteps[0].displayed = true;
            }
        }
    }

    public async mounted() {
        const promises: Promise<any>[] = [];

        for (const ds of this.vm.devSteps) {
            this.setMarketValidationMailContents(ds);
        }

        this.devWorkflowValidations = await RequestService.getWorkflowValidations(this.vm,
            [
                RequestStatus.DevelopmentDocumentExpired,
                RequestStatus.DevelopmentDocumentValidation,
                RequestStatus.DevelopmentDocumentRejected,
                RequestStatus.DevelopmentDocumentValidated,
                RequestStatus.MarketValidationPending,
                RequestStatus.MarketValidationExpired,
                RequestStatus.MarketValidationRejected,
                RequestStatus.MarketValidationValidated,
            ]);

        this.techWorkflowValidations = await RequestService.getWorkflowValidations(this.vm,
            [
                RequestStatus.TechnicalDocumentValidation,
                RequestStatus.TechnicalDocumentValidated
            ]);
    }

    public get user(): IUser {
        return this.$store.getters["app/currentUser"];
    }

    public get readonly(): boolean {
        const userConstraint: boolean = this.user?.userId !== this.vm.requesterId;
        const statusConstraint: boolean = this.vm.requestStatus > RequestStatus.DevelopmentDocument;
        const assignmentConstraint: boolean = !this.user?.userAssignments?.some((a: IUserAssignment) =>
            a.environmentCode === this.activeEnvironmentCode &&
            (a.assignmentType === AssignmentType.Supply || a.assignmentType === AssignmentType.Development)) === true;

        return userConstraint || assignmentConstraint || statusConstraint;
    }

    public get developmentDevelopmentValidationDateDisplayed(): boolean {
        return this.vm.itemOrigin !== ItemOrigin.Declination;
    }

    public get developmentLegalValidationDateDisplayed(): boolean {
        return this.vm.itemOrigin !== ItemOrigin.Declination && this.vm.hasMarketingExecDoc;
    }

    public get developmentMarketingValidationDateDisplayed(): boolean {
        return this.vm.itemOrigin !== ItemOrigin.Declination && this.vm.hasMarketingExecDoc;
    }

    public get developmentMarketValidationDateDisplayed(): boolean {
        return this.vm.needMarketValidation;
    }

    public get technicalDocumentDisplayed(): boolean {
        return this.vm.needTechnicalDocuments;
    }

    public getCommentClass(workflowValidationResponse: WorkflowValidationResponse): string {
        switch (workflowValidationResponse) {
            case WorkflowValidationResponse.Rejected:
                return "comment-title-rejected";
            case WorkflowValidationResponse.Reassigned:
                return "comment-title-reassigned";
            case WorkflowValidationResponse.Validated:
            default:
                return "comment-title-approved";
        }
    }

    private get activeEnvironmentCode(): string {
        return this.$store.getters["app/activeEnvironment"]?.code;
    }

    public get contactReproSupplierText(): string {
        return this.vm.needContactRepro ?
            "Relancer le Repro" :
            "Relancer le fournisseur";
    }

    public get activeRequest(): IRequest {
        return this.$store.getters["requests/activeRequest"];
    }

    public selectTab(tab: IRequestDevStep) {
        this.vm.devSteps.forEach(ds => ds.displayed = ds.supplierCode === tab.supplierCode);
    }

    public contactReproSupplier(selectedDevStep: IRequestDevStep) {
        this.selectedDevStep = selectedDevStep;
        this.selectedDevStep.developmentDocumentRejectedMailBodyFr = null;
        this.selectedDevStep.developmentDocumentRejectedMailBodyEn = null;
        this.$modal.show("contactReproSupplier");
    }

    public async setAsDefault(requestDevStep: IRequestDevStep): Promise<void> {
        this.pendingAction = true;
        const currentDefaultDevStep = this.vm.devSteps.find(ds => ds.defaultSupplier);
        this.vm.devSteps.forEach(ds=>ds.defaultSupplier = false);
        requestDevStep.defaultSupplier = true;
        const response: IRequest = await this.$store.dispatch("requests/createOrUpdateRequest", this.vm);
        if (response) {
            this.$router.push("/");
        } else {
            requestDevStep.defaultSupplier = false;
            if (currentDefaultDevStep) {
                currentDefaultDevStep.defaultSupplier = true;
            }
        }

        this.pendingAction = false;
    }

    public async submitRequest(): Promise<void> {
        this.pendingAction = true;
        const currentStatus = this.vm.requestStatus;
        this.vm.requestStatus = this.getRequestStatusOnSubmit();
        const response: IRequest = await this.$store.dispatch("requests/createOrUpdateRequest", this.vm);
        if (response) {
            if (this.vm.requestStatus === RequestStatus.TechnicalData) {
                this.showTechnicalDataModal();
            } else {
                this.$router.push("/");
            }
        } else {
            this.vm.requestStatus = currentStatus;
        }

        this.pendingAction = false;
    }

    public async restartDevelopmentValidation(requestDevStep: IRequestDevStep): Promise<void>{
        this.pendingAction = true;
        const currentStatus = this.vm.requestStatus;
        this.pendingStatus = true; // Avoid display of rejected status message before response
        requestDevStep.requestDevStepStatus = RequestStatus.DevelopmentDocumentValidation;
        const response: IRequest = await this.$store.dispatch("requests/createOrUpdateRequest", this.vm);
        if (response) {
            this.pendingStatus = false;
            this.$router.push("/");
        } else {
            this.vm.requestStatus = currentStatus;
            this.pendingStatus = false;
        }

        this.pendingAction = false;
    }

    public async restartMarketValidation(requestDevStep: IRequestDevStep): Promise<void>{
        this.pendingAction = true;
        const currentStatus = this.vm.requestStatus;
        this.pendingStatus = true; // Avoid display of rejected status message before response
        requestDevStep.requestDevStepStatus = RequestStatus.MarketValidationPending;
        const response: IRequest = await this.$store.dispatch("requests/createOrUpdateRequest", this.vm);
        if (response) {
            this.pendingStatus = false;
            this.$router.push("/");
        } else {
            this.vm.requestStatus = currentStatus;
            this.pendingStatus = false;
        }

        this.pendingAction = false;
    }

    public async requestMarketValidation(selectedDevStep: IRequestDevStep): Promise<void>{
        this.selectedDevStep = selectedDevStep;
        this.$modal.show("requestMarketValidation");
    }

    public async sendRequestMarketValidation(requestDevStep: IRequestDevStep): Promise<void>{
        this.pendingAction = true;

        this.$v.selectedDevStep.$touch();
        if(!this.$v.selectedDevStep.$invalid) {
            this.$modal.hide("requestMarketValidation");
            const currentStatus = this.vm.requestStatus;
            requestDevStep.requestDevStepStatus = RequestStatus.MarketValidationPending;
            const response: IRequest = await this.$store.dispatch("requests/createOrUpdateRequest", this.vm);
            if (response) {
                this.$router.push("/");
            } else {
                this.vm.requestStatus = currentStatus;
            }
        } else {
            NotificationService.showError("Le formulaire contient des erreurs");
        }

        this.pendingAction = false;
    }

    public async refuseDevelopment(requestDevStep: IRequestDevStep): Promise<void>{
        this.pendingAction = true;
        const currentStatus = this.vm.requestStatus;
        this.pendingStatus = true; // Avoid display of rejected status message before response
        requestDevStep.requestDevStepStatus = RequestStatus.DevelopmentDocumentRejected;
        const response: IRequest = await this.$store.dispatch("requests/createOrUpdateRequest", this.vm);
        if (response) {
            this.pendingStatus = false;
            this.$router.push("/");
        } else {
            this.vm.requestStatus = currentStatus;
            this.pendingStatus = false;
        }

        this.pendingAction = false;
    }

    public async cancelDevStepWorkflow(requestDevStep: IRequestDevStep): Promise<void> {
        this.pendingAction = true;
        const currentStatus: string = requestDevStep.requestDevStepStatusDisplayName;

        window.open(requestDevStep.workflowApprovalLink,"_blank");

        while(this.activeRequest.devSteps.find(ds => ds.requestDevStepId === requestDevStep.requestDevStepId).workflowApprovalLink !== null)
        {
            await this.$store.dispatch("requests/getSingleRequest", requestDevStep.requestId);

            await this.delay(2000);
        }

        this.$router.push("/");

        NotificationService.showSuccess("Etape '"+ currentStatus +"' annulée.");

        this.pendingAction = false;
    }

    public async sendMailToReproSupplier(requestDevStep: IRequestDevStep): Promise<void> {
        this.pendingAction = true;
        this.$v.selectedDevStep.$touch();
        if(!this.$v.selectedDevStep.$invalid) {
            this.$modal.hide("contactReproSupplier");
            const currentStatus = this.vm.requestStatus;
            requestDevStep.requestDevStepStatus = RequestStatus.DevelopmentDocumentPending;
            const response: IRequest = await this.$store.dispatch("requests/createOrUpdateRequest", this.vm);
            if (response) {
                this.$router.push("/");
            } else {
                this.vm.requestStatus = currentStatus;
            }
        } else {
            NotificationService.showError("Le formulaire contient des erreurs");
        }

        this.pendingAction = false;
    }

    public showTechnicalDataModal(): void {
        this.$modal.show("dialog", {
            title: "Données techniques",
            text: "Les données techniques sont incomplètes. L'étape 1 vous permettra de compléter ces informations.",
            buttons: [
                {
                    title: "Fermer",
                    default: true,    // Will be triggered by default if "Enter" pressed.
                    handler: () => {
                        this.$modal.hide("dialog");
                        this.$router.push("/");
                    }
                },
            ]
        });
    }

    public canContactReproSupplier(requestDevStep: IRequestDevStep): boolean {
        return !this.readonly &&
            this.vm.requestStatus === RequestStatus.DevelopmentDocument &&
            (requestDevStep?.requestDevStepStatus === RequestStatus.DevelopmentDocumentRejected ||
                requestDevStep?.requestDevStepStatus === RequestStatus.MarketValidationRejected);
    }

    public canRestartDevelopmentValidation(requestDevStep: IRequestDevStep): boolean {
        return !this.readonly &&
            this.vm.requestStatus === RequestStatus.DevelopmentDocument &&
            requestDevStep?.requestDevStepStatus === RequestStatus.DevelopmentDocumentExpired;
    }

    public canRestartMarketValidation(requestDevStep: IRequestDevStep): boolean {
        return !this.readonly &&
            this.vm.requestStatus === RequestStatus.DevelopmentDocument &&
            requestDevStep?.requestDevStepStatus === RequestStatus.MarketValidationExpired;
    }

    public canRequestMarketValidation(requestDevStep: IRequestDevStep): boolean {
        return !this.readonly &&
            this.vm.requestStatus === RequestStatus.DevelopmentDocument &&
            requestDevStep?.requestDevStepStatus === RequestStatus.DevelopmentDocumentValidated &&
            this.vm.needMarketValidation;
    }

    public canBeSetAsDefault(requestDevStep: IRequestDevStep): boolean {
        return !this.readonly && !requestDevStep.defaultSupplier;
    }

    public canBeSubmit(requestDevStep: IRequestDevStep): boolean {
        return !this.readonly && requestDevStep.defaultSupplier && requestDevStep.requestDevStepStatus >= RequestStatus.TechnicalDocumentValidated;
    }

    public canBeCanceled(requestDevStep: IRequestDevStep): boolean {
        return !(requestDevStep.workflowApprovalLink === null);
    }

    public isDevDocRejected(devStep: IRequestDevStep): boolean {
        return this.pendingStatus === false &&
        (devStep?.requestDevStepStatus === RequestStatus.DevelopmentDocumentRejected || devStep?.requestDevStepStatus === RequestStatus.MarketValidationRejected);
    }

    public isDevDocValidationRejected(validationStatus: string): boolean {
        // When a validator reject Middleway set validationStatus = Workflow.RejectedStatusId or Workflow.CancelledStatusId
        return validationStatus === RequestStatus.DevelopmentDocumentRejected.toString() ||
            validationStatus === RequestStatus.DevelopmentDocumentExpired.toString() ||
            validationStatus === RequestStatus.MarketValidationExpired.toString() ||
            validationStatus === RequestStatus.MarketValidationRejected.toString();
    }

    public isTechDocValidationRejected(validationStatus: string): boolean {
        return validationStatus === RequestStatus.DevelopmentDocumentValidated.toString(); // When a validator reject Middleway set validationStatus = Workflow.RejectedStatusId
    }

    private createViewModel(): IDevelopmentValidationViewModel {
        return {
            ...this.model,
            devSteps: [...this.model.devSteps.map(ds => ({ ...ds, displayed: false }))],
        };
    }

    private async setMarketValidationMailContents(devStep: IRequestDevStep): Promise<void> {
        if (!this.readonly && devStep) {
            const marketValidationRequestMailContent: IMailContent = await RequestService.getApprovalMailContents(
                devStep,
                RequestStatus.MarketValidationPending);

            if (marketValidationRequestMailContent && !devStep.marketValidationMailBodyFr) {
                devStep.marketValidationMailBodyFr = marketValidationRequestMailContent.frBody;
            }

            if (marketValidationRequestMailContent && !devStep.marketValidationMailBodyEn) {
                devStep.marketValidationMailBodyEn = marketValidationRequestMailContent.enBody;
            }
        }
    }

    private getRequestStatusOnSubmit(): RequestStatus {
        const technicalDataCompleted = this.validateRequest();
        return technicalDataCompleted ? RequestStatus.Development : RequestStatus.TechnicalData;
    }


    private validateRequest(): boolean {
        this.$v.vm.$touch();
        return !this.$v.vm.$invalid;
    }

    private delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }
}
