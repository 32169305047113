import { MutationTree } from "vuex";
import {
    SET_AMALGAM_CODE,
    GET_SINGLE_REQUEST,
    GET_REQUESTS,
    SET_ACTIVE_REQUEST_ID,
    SET_ACTIVE_PAGE,
    SET_ITEM_CODE,
    SET_REQUEST_STATUS_FILTER,
    RESET_STATE,
    SET_PROJECT_NAME,
    SET_REQUESTER_ID_FILTER,
    SET_BRAND_CODE_FILTER,
    SET_MARKETING_MANAGER_ID_FILTER,
    SET_QUALITY_CODE_FILTER,
    SET_BOTTLING_CODE_FILTER,
    SET_PACKAGING_CODE_FILTER
} from "./mutations-types";
import { IRequestsState, getInitialState } from "./state";
import { IRequest, IRequestsPage, RequestStatus } from "@/models";

export const mutations: MutationTree<IRequestsState> = {
    [GET_SINGLE_REQUEST](state, payload: IRequest): void {
        if (payload) {
            if (!state.requests) {
                state.requests = [];
            }

            const index: number = state.requests.findIndex(r => r.requestId === payload.requestId);
            if (index < 0) {
                state.requests.push(payload);
            } else {
                state.requests[index] = payload;
            }
        }
    },

    [GET_REQUESTS](state, payload: IRequestsPage): void {
        state.requestsLoaded = payload?.requests?.length > 0 ?? false;
        state.limit = payload?.limit ?? 10;
        state.page = payload?.page ?? 0;
        state.totalPages = payload?.totalPages ?? 0;
        state.archives = payload?.archives ?? false;
        state.itemCode = payload?.itemCode;

        // Remove old requests of the same types.
        state.requests = state.requests?.filter(r => r.isArchived !== payload.archives) ?? [];
        state.requests = state.requests.concat(payload?.requests ?? []);
    },

    [SET_ACTIVE_REQUEST_ID](state, requestId: number): void {
        state.activeRequestId = requestId;
    },

    [SET_ACTIVE_PAGE](state, page: number): void {
        state.page = page;
    },

    [SET_PROJECT_NAME](state, projectName: string): void {
        state.projectName = projectName;
    },

    [SET_AMALGAM_CODE](state, amalgamCode: string): void {
        state.amalgamCode = amalgamCode;
    },

    [SET_ITEM_CODE](state, itemCode: string): void {
        state.itemCode = itemCode;
    },

    [SET_REQUESTER_ID_FILTER](state, userId: number): void {
        state.requesterId = userId;
    },

    [SET_REQUEST_STATUS_FILTER](state, requestStatus: RequestStatus): void {
        state.requestStatus = requestStatus;
    },

    [SET_BRAND_CODE_FILTER](state, code: string): void {
        state.brandCode = code;
    },

    [SET_MARKETING_MANAGER_ID_FILTER](state, userId: number): void {
        state.marketingManagerId = userId;
    },

    [SET_QUALITY_CODE_FILTER](state, code: string): void {
        state.qualityCode = code;
    },

    [SET_BOTTLING_CODE_FILTER](state, code: string): void {
        state.bottlingCode = code;
    },

    [SET_PACKAGING_CODE_FILTER](state, code: string): void {
        state.packagingCode = code;
    },

    [RESET_STATE](state): void{
        Object.assign(state, getInitialState());
    },
};
