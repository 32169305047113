import { GetterTree } from "vuex";
import { IJdeState } from "./state";
import { IUserDefinedCode, ISupplier } from "@/models";

const getters: GetterTree<IJdeState, any> = {
    supplierCodes(state: IJdeState): IUserDefinedCode[] {
        const suppliers: ISupplier[] = state.suppliers;

        // Distinct on code members.
        if(suppliers)
        {
            const result: IUserDefinedCode[] = [];
            const map = new Map();
            for (const item of suppliers) {
                if (!map.has(item.code)) {
                    map.set(item.code, true);
                    result.push({
                        code: item.code,
                        label: item.label,
                    });
                }
            }

            return result;
        } else {
            return [];
        }
    },
};

export { getters };
