import { IPurchaseOrder, IUserDefinedCode, SupplyChainItemType, IItemMaster, ISupplier, PurchaseItemType, IMarketValidator, IUserDefinedContact, IUser } from "@/models";

interface IJdeState {
    accountingGroups: IUserDefinedCode[];
    amalgams: IUserDefinedCode[];
    bottlings: IUserDefinedCode[];
    brands: IUserDefinedCode[];
    brandOwners: IUserDefinedCode[];
    buyers: IUserDefinedContact[];
    capsuleLengths: IUserDefinedCode[];
    capsuleQualities: IUserDefinedCode[];
    capsuleTypes: IUserDefinedCode[];
    cartonExternalBoards: IUserDefinedCode[];
    cartonPrintings: IUserDefinedCode[];
    cartonTypes: IUserDefinedCode[];
    certifications: IUserDefinedCode[];
    closureSizes: IUserDefinedCode[];
    customsTypes: IUserDefinedCode[];
    decorationTypes: IUserDefinedCode[];
    glassHues: IUserDefinedCode[];
    glKeys: IUserDefinedCode[];
    itemCharacteristics: any[];
    itemMasters: IItemMaster[];
    itemSupplementalData: IUserDefinedCode[];
    itemTypes: IUserDefinedCode[];
    labelTypes: IUserDefinedCode[];
    level2Classifications: IUserDefinedCode[];
    level3Classifications: IUserDefinedCode[];
    levelPremiumness: IUserDefinedCode[];
    loadedPurchases: PurchaseItemType[];
    loadedSupplyChains: SupplyChainItemType[];
    mainLocations: IUserDefinedCode[];
    marketValidators: IMarketValidator[];
    masterPlanningFamilies: IUserDefinedCode[];
    materials: IUserDefinedCode[];
    measureUnits: IUserDefinedCode[];
    originCountries: IUserDefinedCode[];
    packagings: IUserDefinedCode[];
    procurementOfficers: IUserDefinedContact[];
    purchaseOrders: IPurchaseOrder[];
    purchasingFamily: IUserDefinedCode[];
    purchasingSubFamily: IUserDefinedCode[];
    qualities: IUserDefinedCode[];
    productTypes: IUserDefinedCode[];
    pvcFree: IUserDefinedCode[];
    sourcings: IUserDefinedCode[];
    suppliers: ISupplier[];
    userDefinedCodes: IUserDefinedCode[];
    vapCodes: IUserDefinedCode[];
    vintages: IUserDefinedCode[];
    warehouseCodes: IUserDefinedCode[];
}

const getInitialState = (): IJdeState => ({
    accountingGroups: [],
    amalgams: [],
    bottlings: [],
    brands: [],
    brandOwners: [],
    buyers: [],
    capsuleLengths: [],
    capsuleQualities: [],
    capsuleTypes: [],
    cartonExternalBoards: [],
    cartonPrintings: [],
    cartonTypes: [],
    certifications: [],
    closureSizes: [],
    customsTypes: [],
    decorationTypes: [],
    glassHues: [],
    glKeys: [],
    itemCharacteristics: [],
    itemMasters: [],
    itemSupplementalData: [],
    itemTypes: [],
    labelTypes: [],
    level2Classifications: [],
    level3Classifications: [],
    levelPremiumness: [],
    loadedPurchases: [],
    loadedSupplyChains: [],
    mainLocations: [],
    marketValidators: [],
    masterPlanningFamilies: [],
    materials: [],
    measureUnits: [],
    originCountries: [],
    packagings: [],
    procurementOfficers: [],
    productTypes: [],
    purchaseOrders: [],
    purchasingFamily: [],
    purchasingSubFamily: [],
    pvcFree: [],
    qualities: [],
    sourcings: [],
    suppliers: [],
    userDefinedCodes: [],
    vapCodes: [],
    vintages:[],
    warehouseCodes: [],
});

export { IJdeState, getInitialState };
