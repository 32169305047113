import { RuleDecl } from "vue/types/options";
import { required, requiredIf, maxLength, minLength, minValue, maxValue, decimal, integer, helpers } from "vuelidate/lib/validators";
import { IRequestDevStep, ItemOrigin, IRequest, RequestStatus } from "@/models";
import { Environments, jdeCategories } from "@/constants";
import DevelopmentValidation from "./DevelopmentValidation";

export const validations: RuleDecl = {
    vm: {
        devSteps: {
            required,
            minLength: minLength(1),
            singleDefault: (values: IRequestDevStep[]) => values?.filter(v=> v.defaultSupplier).length <= 1 ?? true,
            $each: {
                supplierCode: { required: isNotNull },
            },
        },
        packagingCode: { required: requiredIf(packagingRequired), maxLength: maxLength(50) },
        height: { required, decimal, minValue: minValue(0) },
        length: { required, decimal, minValue: minValue(0.1) },
        weight: { required, decimal, minValue: minValue(0.1) },
        width: { required, decimal, minValue: minValue(0.1) },
        glassHueCode: { required: requiredIf(isBulkGlass), maxLength: maxLength(50) },
        glassSize: { required: requiredIf(isBulkGlass), decimal, minValue: minValue(0), maxValue: maxValue(9999999) },
        certificationCode: { required: requiredIf(isCartonItem), maxLength: maxLength(50) },
        cartonExternalBoardCode: { required: requiredIf(isCartonItem), maxLength: maxLength(50) },
        cartonPrintingCode: { required: requiredIf(isCartonItem), maxLength: maxLength(50) },
        cartonTypeCode: { required: requiredIf(isCartonItem), maxLength: maxLength(50) },
        cartonWeight: { required: requiredIf(isCartonItem), integer, minValue: minValue(1) },
        capLengthCode: { required: requiredIf(isLongShortCapsules), maxLength: maxLength(50) },
        capQualityCode: { required: requiredIf(isCapsules), maxLength: maxLength(50) },
        capTypeCode: { required: requiredIf(isLongShortCapsules), maxLength: maxLength(50) },
        co2EmissionFactor: { required: requiredIf(hasCO2EmissionFactor), integer, minValue: minValue(1) },
        materialCode: { required: requiredIf(isMaterial), maxLength: maxLength(10) },
        sourcingCode: { required: requiredIf(isSourcing), maxLength: maxLength(50) },
        recycled: { required: requiredIf(isRecycled), maxLength: maxLength(50),
            regex: helpers.regex("decimalWithThreeDecimals", /^(?:0|[1-9]\d{0,45})(,\d{1,3})?$/) },
        levelPremiumnessCode: { required: requiredIf(isLevelPremiumness), maxLength: maxLength(50) },
        needPalletizationPlan: { required: requiredIf(isCognacEnvironment) },
        lengthCU: { required: requiredIf(needPalletizationPlan), decimal, minValue: minValue(0), maxValue: maxValue(9999999) },
        widthCU: { required: requiredIf(needPalletizationPlan), decimal, minValue: minValue(0), maxValue: maxValue(9999999) },
        heightCU: { required: requiredIf(needPalletizationPlan), decimal, minValue: minValue(0), maxValue: maxValue(9999999) },
        weightCU: { required: requiredIf(needPalletizationPlan), decimal, minValue: minValue(0), maxValue: maxValue(9999999) },
        grossWeightCU: { required: requiredIf(needPalletizationPlan), decimal, minValue: minValue(0), maxValue: maxValue(9999999) },
        decorationTypeCode: { required: requiredIf(isDecoration), maxLength: maxLength(50) },
        customsTypeCode: { required: requiredIf(isCustomsType), maxLength: maxLength(50) },
        numberOfUnitsOnPalletReceipted: { integer, minValue: minValue(1), maxValue: maxValue(9999999) },
        numberOfUnitsPerPack: { integer, minValue: minValue(1), maxValue: maxValue(9999999) },
    },
    selectedDevStep: {
        developmentDocumentRejectedMailBodyFr: { required: requiredIf(needRejectedEmail), minLength: minLength(1), maxLength: maxLength(3000) },
        developmentDocumentRejectedMailBodyEn: { required: requiredIf(needRejectedEmail), minLength: minLength(1), maxLength: maxLength(3000) },
        marketValidationMailBodyEn: { required: requiredIf(needMarketValidation), minLength: minLength(1), maxLength: maxLength(3000) },
        marketValidationMailBodyFr: { required: requiredIf(needMarketValidation), minLength: minLength(1), maxLength: maxLength(3000) },
    }
};

function isNotNull(value: string): boolean {
    return value !== null && typeof value !== "undefined";
}

function isBulkGlass(value: IRequest): boolean {
    return value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.bulkGlass) === true;
}

function isLongShortCapsules(value: IRequest): boolean {
    return value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.longShortAluminiumCapsules) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.longShortTinCapsules) === true;
}

function isCapsules(value: IRequest): boolean {
    return value?.classificationLevel2Code?.trimEquals(jdeCategories.classificationLevel2.capsules) === true
        || value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.aggloCork) === true;
}

function packagingRequired(value: IRequest): boolean {
    return (isDeclination(value) || isWetOrAdhesiveLabel(value));
}

function isWetOrAdhesiveLabel(value: IRequest): boolean {
    return value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.wetGlueBackLabels) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.adhesivesBackLabels) === true;
}

function isCartonItem(value: IRequest): boolean {
    return value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.shippingCases) === true
        || value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.display) === true
        || value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.divisions) === true;
}

function isCustomsType(value: IRequest): boolean {
    return value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.longShortAluminiumCapsules) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.longShortTinCapsules) === true;
}

function isDeclination(value: IRequest): boolean {
    return value?.itemOrigin === ItemOrigin.Declination;
}

function hasCO2EmissionFactor(value: IRequest): boolean {
    return value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.bulkGlass) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.longShortAluminiumCapsules) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.longShortTinCapsules) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.longShortHeatShrinkCapsules) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.plasticClosures) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.screwPlasticClosures) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.crownClosures) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.shippingCases) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.display) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.divisions) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.packAndClusters) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.woodenGiftBoxes) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.prettyGiftBoxes) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.cartonGiftBoxes) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.metalGiftBoxes) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.mountedWoodenGiftBoxes) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.mountedCartonGiftBoxes) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.mountedOtherGiftBoxes) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.boxAccessories) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.drySugar) === true;
}

function isMaterial(value: IRequest): boolean {
    const options: string[] = [
        jdeCategories.classificationLevel3.adhesivesBackLabels,
        jdeCategories.classificationLevel3.adhesivesLabels,
        jdeCategories.classificationLevel3.adhesivesMedallionLabels,
        jdeCategories.classificationLevel3.adhesivesNeckLabels,
        jdeCategories.classificationLevel3.all,
        jdeCategories.classificationLevel3.boxAccessories,
        jdeCategories.classificationLevel3.bulkGlass,
        jdeCategories.classificationLevel3.cartonGiftBoxes,
        jdeCategories.classificationLevel3.headOfBarTop,
        jdeCategories.classificationLevel3.metalGiftBoxes,
        jdeCategories.classificationLevel3.mountedCartonGiftBoxes,
        jdeCategories.classificationLevel3.mountedOtherGiftBoxes,
        jdeCategories.classificationLevel3.mountedWoodenGiftBoxes,
        jdeCategories.classificationLevel3.palletLabels,
        jdeCategories.classificationLevel3.plasticClosures,
        jdeCategories.classificationLevel3.screwPlasticClosures,
        jdeCategories.classificationLevel3.sleeve,
        jdeCategories.classificationLevel3.wetGlueBackLabels,
        jdeCategories.classificationLevel3.wetGlueLabels,
        jdeCategories.classificationLevel3.wetGlueNeckLabels,
        jdeCategories.classificationLevel3.woodenGiftBoxes,
    ];

    return value?.classificationLevel3Code && options.some(v => v?.trimEquals(value?.classificationLevel3Code) === true);
}

function isSourcing(value: IRequest): boolean {
    return value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.headOfBarTop) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.plasticClosures) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.screwPlasticClosures) === true ||
        value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.crownClosures) === true;
}

function isRecycled(value: IRequest): boolean {
    return value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.bulkGlass) === true;
}

function isLevelPremiumness(value: IRequest): boolean {
    return value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.bulkGlass) === true;
}

function isDecoration(value: IRequest): boolean {
    return value?.classificationLevel3Code?.trimEquals(jdeCategories.classificationLevel3.decoratedBottle) === true;
}

function isCognacEnvironment(value: IRequest): boolean {
    return Environments.Cognac.trimContains(value.environmentDisplayName);
}

function needPalletizationPlan(value: IRequest): boolean {
    return isCognacEnvironment(value) && value.needPalletizationPlan;
}

function needRejectedEmail(value: IRequestDevStep): boolean {
    return value.requestDevStepStatus === RequestStatus.DevelopmentDocumentRejected;
}

function needMarketValidation(this: DevelopmentValidation, value: IRequestDevStep): boolean {
    if (this) {
        return this.vm.needMarketValidation && value.requestDevStepStatus >= RequestStatus.DevelopmentDocumentValidated;
    } else {
        return false;
    }
}
