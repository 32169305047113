import { IDocument, IUser, IRequest, IRequestDevStep, RequestStatus, IMailContent, IEnvironment, IControl, IWorkflowValidation } from "@/models";
import { AxiosService } from "@/services";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import Vue from "vue";

export class RequestService {
    public static get documentFileExtensions(): string {
        return ".pdf,.doc,.docx,.xls,.xlsx,.zip";
    }

    public static get imageFileExtensions(): string {
        return ".png,.jpeg,.jpg,.jif,.jfif";
    }

    public static createRequest(user: IUser, environment: IEnvironment): IRequest {
        return {
            accountingGroupCode: null,
            accountingGroupLabel: null,
            amalgamCode: null,
            amalgamLabel: null,
            bottlingCode: null,
            bottlingLabel: null,
            brandCode: null,
            brandLabel: null,
            brandOwnerCode: null,
            brandOwnerLabel: null,
            buyerCode: null,
            buyerEmail: null,
            buyerLabel: null,
            capLengthCode: null,
            capLengthLabel: null,
            capQualityCode: null,
            capQualityLabel: null,
            capTypeCode: null,
            capTypeLabel: null,
            cartonExternalBoardCode: null,
            cartonExternalBoardLabel: null,
            cartonPrintingCode: null,
            cartonPrintingLabel: null,
            cartonTypeCode: null,
            cartonTypeLabel: null,
            cartonWeight: null,
            certificationCode: null,
            certificationLabel: null,
            classificationLevel2Code: null,
            classificationLevel2Label: null,
            classificationLevel3Code: null,
            classificationLevel3Label: null,
            closureSizeCode: null,
            closureSizeLabel: null,
            co2EmissionFactor: null,
            controls: [],
            customsTypeCode: null,
            customsTypeLabel: null,
            decorationTypeCode: null,
            decorationTypeLabel: null,
            description1: null,
            description2: null,
            developerEmail: null,
            developerId: null,
            developerName: null,
            developmentDocumentMailBodyEn: null,
            developmentDocumentMailBodyFr: null,
            devSteps: [],
            eanCode: null,
            environmentCode: environment?.code,
            environmentDisplayName: environment?.label,
            environmentId: environment?.environmentId,
            executionDocument: null,
            executionDocumentationFirstSubmissionDate: null,
            executionDocumentLegalValidationDate: null,
            executionDocumentLegalValidator: null,
            executionDocumentLegalValidationStatus: null,
            executionDocumentSupplyDocumentValidationDate: null,
            executionDocumentSupplyDocumentValidator: null,
            executionDocumentSupplyDocumentValidationStatus: null,
            executionDocumentDevelopmentValidationDate: null,
            executionDocumentDevelopmentValidator: null,
            executionDocumentDevelopmentValidationStatus: null,
            executionDocumentVersion: null,
            glassHueCode: null,
            glassHueLabel: null,
            glassSize: null,
            glKeyCode: null,
            glKeyLabel: null,
            grossWeightCU: null,
            hasMarketingExecDoc: null,
            marketingExecDocNotificationEmailDate: null,
            height: 0,
            heightCU: null,
            isArchived: false,
            isNewItemMaster: null,
            itemCodeUpdate: null,
            itemMasterCode: null,
            itemOrigin: null,
            itemOriginDisplayName: null,
            itemTypeCode: null,
            itemTypeLabel: null,
            labelTypeCode: null,
            labelTypeLabel: null,
            lastRequestUpdate: null,
            length: 0,
            lengthCU: null,
            levelPremiumnessCode: null,
            levelPremiumnessLabel: null,
            mainLocationLabel: null,
            mainMeasureUnitCode: null,
            mainMeasureUnitLabel: null,
            marketingInfoText: null,
            marketingManagerEmail: null,
            marketingManagerId: null,
            marketingManagerName: null,
            marketValidators: [],
            masterPlanningFamilyCode: null,
            masterPlanningFamilyLabel: null,
            materialCode: null,
            materialLabel: null,
            needContactRepro: null,
            needMarketValidation: false,
            needPalletizationPlan: null,
            needTechnicalDocuments: null,
            newItemCode: null,
            numberOfUnitsOnPalletReceipted: 0,
            numberOfUnitsPerPack: 0,
            oldItemDeadline: null,
            originCountryCode: null,
            originCountryLabel: null,
            packagingCode: null,
            packagingLabel: null,
            procurementOfficerCode: null,
            procurementOfficerEmail: null,
            procurementOfficerLabel: null,
            projectCode: null,
            projectName: null,
            purchaseMeasureUnitCode: null,
            purchaseMeasureUnitLabel: null,
            purchasingFamilyCode: null,
            purchasingFamilyLabel: null,
            purchasingSubFamilyCode: null,
            purchasingSubFamilyLabel: null,
            pvcFreeCode: null,
            pvcFreeLabel: null,
            productTypeCode: null,
            productTypeLabel: null,
            qualityCode: null,
            qualityLabel: null,
            recycled: null,
            requestDate: new Date(),
            requestDescription: null,
            requesterEmail: user?.email,
            requesterId: user?.userId,
            requesterName: user?.name,
            requestId: null,
            requestStatus: null,
            requestStatusDisplayName: null,
            searchingText: null,
            skuCapacity: 0,
            skuCode: null,
            skuPcb: 0,
            sourcingCode: null,
            sourcingLabel: null,
            statusTimeOutDate: null,
            supplyTimePeriod: null,
            technicalDocumentMailBodyEn: null,
            technicalDocumentMailBodyFr: null,
            validationDate: null,
            validatorEmail: null,
            validatorId: null,
            validatorName: null,
            vapCode: null,
            vapLabel: null,
            vintageCode: null,
            vintageLabel: null,
            warehouseCode: null,
            warehouseLabel: null,
            warningMessageItem: null,
            weight: 0,
            weightCU: null,
            width: 0,
            widthCU: null,
            workflowAdaptiveCard: null,
            workflowApprovalLink: null,
        };
    }

    public static createDevStep(requestId: number, supplierCode: string, supplierLabel: string): IRequestDevStep {
        return {
            developmentDocumentVersion: null,
            defaultSupplier: false,
            developmentDevelopmentValidationDate: null,
            developmentDevelopmentValidationStatus: null,
            developmentDevelopmentValidator: null,
            developmentDocument: null,
            developmentDocumentRejectedMailBodyEn: null,
            developmentDocumentRejectedMailBodyFr: null,
            developmentLegalValidationDate: null,
            developmentLegalValidationStatus: null,
            developmentLegalValidator: null,
            developmentMarketingValidationDate: null,
            developmentMarketingValidationStatus: null,
            developmentMarketingValidator: null,
            developmentMarketValidationDate: null,
            developmentMarketValidationStatus: null,
            developmentMarketValidator: null,
            developmentSupplyDocumentValidationDate: null,
            developmentSupplyDocumentValidationStatus: null,
            developmentSupplyDocumentValidator: null,
            firstBatReceptionDate: null,
            firstTechnicalDocumentReceptionDate: null,
            marketValidationMailBodyEn: null,
            marketValidationMailBodyFr: null,
            requestDevStepId: null,
            requestDevStepStatus: null,
            requestDevStepStatusDisplayName: null,
            requestId,
            statusTimeOutDate: null,
            supplierCode,
            supplierLabel,
            supplierReproNotificationEmailDate: null,
            technicalDevelopmentValidationDate: null,
            technicalDevelopmentValidationStatus: null,
            technicalDevelopmentValidator: null,
            technicalDocuments: [],
            technicalDocumentRequestDate: null,
            technicalDocumentVersion: null,
            workflowAdaptiveCard: null,
            workflowApprovalLink: null,
        };
    }

    public static createControl(requestId: number, controller: IUser): IControl {
        return {
            controlComment: null,
            controlDate: new Date(),
            controlDuration: null,
            controlId: null,
            controlIsCorrect: null,
            controlledQuantity: null,
            controllerEmail: controller?.email,
            controllerId: controller?.userId,
            controllerName: controller?.name,
            defectBlockerVolume: null,
            defectCriticalVolume: null,
            defectMajorVolume: null,
            defectMinorVolume: null,
            deliveryDate: null,
            deliveryQuantity: null,
            estimatedDeliveryDate: null,
            firstOrderedQuantity: null,
            imageDocuments: [],
            orderDate: null,
            orderMainLocation: null,
            orderNumber: null,
            orderWarehouseCode: null,
            orderWarehouseLabel: null,
            requestId,
            uncomplianceDescription: null,
        };
    }

    public static async uploadDocument(document: IDocument): Promise<IDocument> {
        try {
            const formData: FormData = new FormData();
            formData.append("document", document?.file);
            formData.append("documentType", document?.documentType?.toString());

            if (document?.controlId) {
                formData.append("controlId", document?.controlId?.toString());
            }
            const url = `${process.env.VUE_APP_WEB_API_BASE_URL}/requests/${document?.requestId}/documents`;
            const config: AxiosRequestConfig = await AxiosService.getAxiosFormDataConfiguration();
            return await Vue.axios.post(url, formData, config);
        } catch {
            return null;
        }
    }

    public static async getNotificationMailContents(request: IRequest, targetStatus: RequestStatus): Promise<IMailContent> {
        try {
            const url = `${process.env.VUE_APP_WEB_API_BASE_URL}/requests/${request?.requestId}/notificationMailContents?requestStatusId=${targetStatus}`;
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response: AxiosResponse<IMailContent> = await Vue.axios.get(url, config);
            return response.data;
        } catch {
            return null;
        }
    }

    public static async getApprovalMailContents(requestDevStep: IRequestDevStep, targetStatus: RequestStatus): Promise<IMailContent> {
        try {
            const url =
                `${process.env.VUE_APP_WEB_API_BASE_URL}/requests/${requestDevStep?.requestId}/approvalMailContents?requestStatusId=${targetStatus}&devStepId=${requestDevStep.requestDevStepId}`;
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response: AxiosResponse<IMailContent> = await Vue.axios.get(url, config);
            return response.data;
        } catch {
            return null;
        }
    }

    public static creationCodeDisplayed(request: IRequest): boolean {
        return this.requestStatusIsAtLeast(request, RequestStatus.RequestValidated);
    }

    public static executionDocumentValidationDisplayed(request: IRequest): boolean {
        return (
            this.requestStatusIsAtLeast(request, RequestStatus.CodeCreated) &&
            request?.hasMarketingExecDoc === true
        );
    }

    public static developmentDocumentRequestDisplayed(request: IRequest): boolean {
        return (
            this.requestStatusIsAtLeast(request, RequestStatus.ExecutionDocumentValidated) ||
            (this.requestStatusIsAtLeast(request, RequestStatus.CodeCreated) && request?.hasMarketingExecDoc === false)
        );
    }

    public static developmentValidationDisplayed(request: IRequest): boolean {
        return (this.requestStatusIsAtLeast(request, RequestStatus.DevelopmentDocument));
    }

    public static firstReceptionCheckDisplayed(request: IRequest): boolean {
        return (this.requestStatusIsAtLeast(request, RequestStatus.Development));
    }

    public static async getWorkflowValidations(request: IRequest, status: RequestStatus[]): Promise<IWorkflowValidation[]> {
        try {
            if(request != null) {
                const parameters: [string, string][] = [];
                status.forEach(element => {
                    parameters.push(["requestStatus", element.toString()]);
                });
                const query = AxiosService.buildQueryParameters(parameters);
                const url = `${process.env.VUE_APP_WEB_API_BASE_URL}/requests/${request?.requestId}/comments${query}`;
                const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
                const response: AxiosResponse<IWorkflowValidation[]> = await Vue.axios.get(url, config);
                return response.data;
            }
            else {
                return null;
            }
        } catch {
            return null;
        }
    }

    private static requestStatusIsAtLeast(request: IRequest, requestStatus: RequestStatus): boolean {
        return request?.requestStatus >= requestStatus;
    }
}
