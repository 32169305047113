import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import {
    IUser,
    AssignmentType,
    SupplyChainItemType,
    IUserDefinedCode,
    IRequest,
    RequestStatus,
    IItemMaster,
    IUserAssignment,
    IUserDefinedContact,
    IActionEnvironmentParameter,
} from "@/models";
import vSelect from "vue-select";
import { LocalDatePicker, LoaderSpinner, UserDefinedCodeSelect } from "@/components";
import { validations } from "./validations";
import { NotificationService } from "@/services";
import { Environments } from "@/constants";

@Component({
    name: "creation-code",
    components: { "vue-select": vSelect, LocalDatePicker, LoaderSpinner, UserDefinedCodeSelect },
    validations,
})
export default class CreationCode extends Vue {
    @Prop({ required: true, type: Object })
    public model: IRequest;

    @Prop({ required: true, default: false })
    public expanded: boolean;

    @Prop({ required: true })
    public isEditButtonVisible: boolean;

    public vm: IRequest;
    public pendingAction: boolean;
    public loadingData: boolean;
    public isEditable: boolean;

    public constructor() {
        super();
        this.vm = this.createViewModel();
        this.pendingAction = false;
        this.loadingData = false;
        this.isEditable = false;
    }

    public get readonly(): boolean {
        const statusConstraint: boolean = this.vm?.requestStatus > RequestStatus.RequestValidated && this.vm?.requestStatus !== RequestStatus.CodeNotCreated;
        const assignmentConstraint: boolean =
            !this.$store.getters["app/currentUser"]?.userAssignments?.some((a: IUserAssignment) =>
                a.environmentCode === this.activeEnvironmentCode &&
                a.assignmentType === AssignmentType.SupplyCreationValidator) === true;

        if (this.isKeyUser && this.isEditable) {
            return false;
        }
        return statusConstraint || assignmentConstraint;
    }

    public get user(): IUser {
        return this.$store.getters["app/currentUser"];
    }

    public get isKeyUser(): boolean {
        return this.user?.userAssignments?.some(
            a =>
                a.assignmentType === AssignmentType.Administrator
                && a.environmentCode === this.activeEnvironmentCode
        ) === true;
    }

    public get canBeSubmit(): boolean {
        return !this.readonly && this.vm?.requestStatus <= RequestStatus.CodeNotCreated;
    }

    public get canBeUpdate(): boolean {
        return !this.readonly && this.vm?.requestStatus > RequestStatus.CodeNotCreated;
    }

    public get glKeys(): IUserDefinedCode[] {
        return this.$store.state.jde.glKeys;
    }

    public get itemTypes(): IUserDefinedCode[] {
        return this.$store.state.jde.itemTypes;
    }

    public get masterPlanningFamilies(): IUserDefinedCode[] {
        return this.$store.state.jde.masterPlanningFamilies;
    }

    public get vapCodes(): IUserDefinedCode[] {
        return this.$store.state.jde.vapCodes;
    }

    public get procurementOfficers(): IUserDefinedContact[] {
        return this.$store.state.jde.procurementOfficers;
    }

    public get warehouseCodes(): IUserDefinedCode[] {
        return this.$store.state.jde.warehouseCodes;
    }

    public get amalgams(): IUserDefinedCode[] {
        return this.$store.state.jde.amalgams;
    }

    public get productTypes(): IUserDefinedCode[] {
        return this.$store.state.jde.productTypes;
    }

    public get mainLocations(): IUserDefinedCode[] {
        const locations: IUserDefinedCode[] = this.$store.state.jde.mainLocations;
        if (locations?.length > 0 && this.vm?.warehouseCode) {
            return locations.filter(m => m.code.trimEquals(this.vm.warehouseCode));
        } else {
            return [];
        }
    }

    public get accountingGroups(): IUserDefinedCode[] {
        return this.$store.state.jde.accountingGroups;
    }

    public get brandOwners(): IUserDefinedCode[] {
        return this.$store.state.jde.brandOwners;
    }

    public get isCognacEnvironment(): boolean {
        return Environments.Cognac.trimContains(this.vm?.environmentDisplayName);
    }

    private get activeEnvironmentCode(): string {
        return this.$store.getters["app/activeEnvironment"]?.code;
    }

    @Watch("model")
    public createViewModel(): IRequest {
        const user: IUser = this.$store.getters["app/currentUser"];
        const validator: IUser = user.userAssignments.some(ua =>
            ua.environmentCode === this.$store.getters["app/activeEnvironment"]?.code &&
            ua.assignmentType === AssignmentType.SupplyCreationValidator)
            ? user : null;
        const vm = {
            ...this.model,
            validatorId: this.model?.validatorId ?? validator?.userId,
            validatorName: this.model?.validatorName ?? validator?.name,
            validatorEmail: this.model?.validatorEmail ?? validator?.email,
            validationDate: this.model?.validationDate ?? (validator ? new Date() : null),
        };
        this.vm = vm;
        return vm;
    }

    public async submitRequest(): Promise<void> {
        this.pendingAction = true;
        if (this.validateBeforeSubmit()) {
            const currentStatus = this.vm.requestStatus;
            this.vm.requestStatus = RequestStatus.CodeCreation;
            const response: IRequest = await this.$store.dispatch("requests/createOrUpdateRequest", this.vm);
            if (response) {
                this.$router.push("/");
            } else {
                this.vm.requestStatus = currentStatus;
            }
        } else {
            NotificationService.showError("Le formulaire contient des erreurs");
        }

        this.pendingAction = false;
    }

    public async updateRequest(): Promise<void> {
        this.pendingAction = true;
        if (this.validateBeforeSubmit()) {
            const response: any = await this.$store.dispatch("requests/updateRequestWithoutWorkflow", this.vm);
            if (response) {
                const request: IRequest = response.data;
                this.isEditable = false;
                this.$emit("update:isEditButtonVisible", true);
                this.$emit("updateRequest");
                this.vm.lastRequestUpdate = request.lastRequestUpdate;
            }
        } else {
            NotificationService.showError("Le formulaire contient des erreurs");
        }

        this.pendingAction = false;
    }

    public updateProcurementOfficer(value: IUserDefinedContact) {
        this.vm.procurementOfficerEmail = value.mail;
    }

    public updateWarehouse(value: string | IUserDefinedCode) {
        this.vm.mainLocationLabel = null;
        const code: string = this.isUserDefinedCode(value)? value.code : value;
        if (code && !this.mainLocations.some(m => m.code.trimEquals(code.trim()))) {
            this.$store.dispatch("jde/getMainLocations", this.getJdeParameters(code.trim()));
        }
    }

    public setDefaultNumberToNull(event: Event & { target: HTMLInputElement }) {
        if (event.target.value === "") {
            this.$set(this.vm, event.target.id, "");
        }
    }

    public async editRequest() {
        this.isEditable = true;
        this.$emit("update:isEditButtonVisible", false);
        await this.loadData();
    }

    // Hook.
    public async created(): Promise<void> {
        this.loadingData = true;
        await this.loadData();
        this.setItemMasterValues();
        this.loadingData = false;
    }

    private isUserDefinedCode(value: IUserDefinedCode | string): value is IUserDefinedCode {
        return typeof ((value as IUserDefinedCode)?.code) !== "undefined";
    }

    private async loadData(): Promise<void> {
        const promises: Promise<any>[] = [];
        if (!this.readonly) {
            const supplyChainsToLoad = [
                SupplyChainItemType.MasterPlanningFamily,
                SupplyChainItemType.VapCode,
                SupplyChainItemType.ItemTypes,
                SupplyChainItemType.WarehouseCodes,
                SupplyChainItemType.AmalgamCode,
                SupplyChainItemType.BrandOwners,
                SupplyChainItemType.ProductTypes,
            ];
            for (const s of supplyChainsToLoad) {
                if (!(this.$store.state.jde.loadedSupplyChains as any).find((x: SupplyChainItemType) => x === s)) {
                    promises.push(this.$store.dispatch("jde/getSupplyChains", this.getJdeParameters(s)));
                }
            }

            if (this.$store.state.jde.procurementOfficers?.length < 1) {
                promises.push(this.$store.dispatch("jde/getProcurementOfficers", this.activeEnvironmentCode));
            }

            if (this.$store.state.jde.accountingGroups?.length < 1) {
                promises.push(this.$store.dispatch("jde/getAccountingGroups", this.activeEnvironmentCode));
            }

            if (this.$store.state.jde.glKeys?.length < 1) {
                promises.push(this.$store.dispatch("jde/getGlKeys", this.activeEnvironmentCode));
            }

            // Load main location.
            if (this.vm?.itemMasterCode && !this.$store.state.jde.itemMasters?.some((i: IItemMaster) => i.itemCode?.trimEquals(this.vm.itemMasterCode))) {
                await this.$store.dispatch("jde/getSingleItemMaster", this.getJdeParameters<string>(this.vm.itemMasterCode.trim()));
            }

            const itemMaster: IItemMaster = this.$store.state.jde.itemMasters.find((i: IItemMaster) => i.code.trimEquals(this.vm.itemMasterCode));
            if (itemMaster?.warehouse && itemMaster.warehouse.trim().length > 0) {
                promises.push(this.$store.dispatch("jde/getMainLocations", this.getJdeParameters(itemMaster.warehouse.trim())));
            }
        }
        await Promise.all(promises);
    }

    private getJdeParameters<T>(value: T): IActionEnvironmentParameter<T> {
        return {
            environmentCode: this.activeEnvironmentCode,
            value,
        };
    }

    private setItemMasterValues(): void {
        if (!this.readonly && this.vm?.itemMasterCode && this.vm?.requestStatus <= RequestStatus.RequestValidated) {
            const itemMaster: IItemMaster = this.$store.state.jde.itemMasters.find((i: IItemMaster) => i.code.trimEquals(this.vm.itemMasterCode));
            const isFirstStepIteration = !this.vm.itemCodeUpdate;
            if (isFirstStepIteration && itemMaster) {
                // masterPlanningFamily.
                if (itemMaster.masterPlanningFamily) {
                    const masterPlanningFamily: IUserDefinedCode = this.masterPlanningFamilies.find((i: IUserDefinedCode) => i.code.trimEquals(itemMaster.masterPlanningFamily));
                    this.vm.masterPlanningFamilyCode = masterPlanningFamily?.code;
                    this.vm.masterPlanningFamilyLabel = masterPlanningFamily?.label;
                }

                // vap.
                if (itemMaster.VAP) {
                    const vap: IUserDefinedCode = this.vapCodes.find((i: IUserDefinedCode) => i.code.trimEquals(itemMaster.VAP));
                    this.vm.vapCode = vap?.code;
                    this.vm.vapLabel = vap?.label;
                }

                // procurementOfficer.
                if (itemMaster.procurementOfficer) {
                    const procurementOfficer: IUserDefinedContact = this.procurementOfficers.find((i: IUserDefinedContact) => i.code.trimEquals(itemMaster.procurementOfficer));
                    this.vm.procurementOfficerCode = procurementOfficer?.code;
                    this.vm.procurementOfficerLabel = procurementOfficer?.label;
                    this.vm.procurementOfficerEmail = procurementOfficer?.mail;
                }

                // warehouse.
                if (itemMaster.warehouse) {
                    const warehouse: IUserDefinedCode = this.warehouseCodes.find((i: IUserDefinedCode) => i.code.trimEquals(itemMaster.warehouse));
                    this.vm.warehouseCode = warehouse?.code;
                    this.vm.warehouseLabel = warehouse?.label;

                    // load main Locations of the current warehouse.
                    this.updateWarehouse(this.vm.warehouseCode);
                }

                // mainLocation.
                if (itemMaster.mainLocation) {
                    const mainLocation: IUserDefinedCode = this.mainLocations.find((i: IUserDefinedCode) => i.label.trimEquals(itemMaster.mainLocation));
                    this.vm.mainLocationLabel = mainLocation?.label;
                }

                // amalgamCode.
                if (itemMaster.amalgamCode && itemMaster.amalgamCode.trim().length > 0) {
                    const amalgam: IUserDefinedCode = this.$store.state.jde.amalgams.find((a: IUserDefinedCode) => a.code.trimEquals(itemMaster.amalgamCode));
                    this.vm.amalgamCode = amalgam?.code;
                    this.vm.amalgamLabel = amalgam?.label;
                }

                // accountingGroupCode.
                if (itemMaster.accountingGroup) {
                    const accountingGroup: IUserDefinedCode = this.$store.state.jde.accountingGroups.find((i: IUserDefinedCode) => i.code.trimEquals(itemMaster.accountingGroup));
                    this.vm.accountingGroupCode = accountingGroup?.code;
                    this.vm.accountingGroupLabel = accountingGroup?.label;
                }
            }
        }
    }

    private validateBeforeSubmit(): boolean {
        this.$v.vm.$touch();
        return !this.$v.vm.$invalid;
    }
}
