export const jdeCategories = {
    classificationLevel2: {
        capsules: "03",
    },
    classificationLevel3: {
        adhesivesBackLabels: "305",
        adhesivesLabels: "304",
        adhesivesMedallionLabels: "307",
        adhesivesNeckLabels: "306",
        aluminiumClosures: "201",
        aggloCork: "207",
        all: "610",
        boxAccessories: "506",
        bulkGlass: "101",
        cartonGiftBoxes: "501",
        crownClosures: "212",
        decoratedBottle: "105",
        display: "402",
        divisions: "403",
        drySugar: "702",
        headOfBarTop: "205",
        labelTypes: "308",
        longShortAluminiumCapsules: "202",
        longShortHeatShrinkCapsules: "204",
        longShortTinCapsules: "203",
        metalGiftBoxes: "502",
        mountedCartonGiftBoxes: "504",
        mountedOtherGiftBoxes: "505",
        mountedWoodenGiftBoxes: "503",
        packAndClusters: "404",
        palletLabels: "609",
        plasticClosures: "209",
        prettyGiftBoxes: "406",
        screwPlasticClosures: "210",
        shippingCases: "401",
        sleeve: "110",
        wetGlueBackLabels: "302",
        wetGlueLabels: "301",
        wetGlueNeckLabels: "303",
        woodenGiftBoxes: "405",
    },
};
