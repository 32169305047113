import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import {
    IUserDefinedCode,
    IUser,
    SupplyChainItemType,
    IItemOrigin,
    IItemMaster,
    AssignmentType,
    ItemOrigin,
    RequestStatus,
    IRequest,
    IRequestDevStep,
    PurchaseItemType,
    IMarketValidator,
    IActionEnvironmentParameter,
    IUserDefinedContact
} from "@/models";
import vSelect from "vue-select";
import {
    LocalDatePicker,
    LoaderSpinner,
    UserDefinedCodeSelect
} from "@/components";
import { ICreationRequestViewModel } from "./ICreationRequestViewModel";
import { validations } from "./validations";
import { NotificationService, RequestService } from "@/services";
import { Environments, jdeCategories } from "@/constants";

@Component({
    name: "creation-request",
    components: {
        "vue-select": vSelect,
        LocalDatePicker,
        LoaderSpinner,
        UserDefinedCodeSelect
    },
    validations
})
export default class CreationRequest extends Vue {
    @Prop({ required: true, type: Object })
    public model: IRequest;

    @Prop({ required: true, default: false })
    public expanded: boolean;

    @Prop({required: true})
    public isEditButtonVisible: boolean;

    public vm: ICreationRequestViewModel;
    public pendingAction: boolean;
    public loadingData: boolean;
    public isEditable: boolean;

    public constructor() {
        super();
        this.vm = this.createViewModel();
        this.pendingAction = false;
        this.loadingData = false;
        this.isEditable = false;
    }

    public get user(): IUser {
        return this.$store.getters["app/currentUser"];
    }

    public get readonly(): boolean {
        if (this.isKeyUser && this.isEditable)
        {
            return false;
        }

        const userConstraint: boolean =
            this.user?.userId !== this.vm?.requesterId;
        const statusConstraint: boolean =
            this.vm?.requestStatus > RequestStatus.Draft &&
            this.vm?.requestStatus !== RequestStatus.RequestRejected;
        const assignmentConstraint: boolean =
            !this.user?.userAssignments?.some(
                a =>
                    a.environmentCode === this.activeEnvironmentCode &&
                    (a.assignmentType === AssignmentType.Supply ||
                        a.assignmentType === AssignmentType.Development)
            ) === true;

        return userConstraint || statusConstraint || assignmentConstraint;
    }

    public get isKeyUser(): boolean
    {
        return this.user?.userAssignments?.some(
            a =>
                a.assignmentType === AssignmentType.Administrator
                && a.environmentCode === this.activeEnvironmentCode
        ) === true;
    }

    public get readonlyTechnicalData(): boolean {
        const userConstraint: boolean =
            this.user?.userId !== this.vm?.requesterId;

        const statusConstraint: boolean =
            this.vm?.requestStatus >= RequestStatus.RequestValidation &&
            this.vm?.requestStatus !== RequestStatus.RequestRejected &&
            this.vm?.requestStatus !== RequestStatus.TechnicalData;

        const assignmentConstraint: boolean =
            !this.user?.userAssignments?.some(
                a =>
                    a.environmentCode === this.activeEnvironmentCode &&
                    (a.assignmentType === AssignmentType.Supply ||
                        a.assignmentType === AssignmentType.Development)
            ) === true;

        if(this.isKeyUser && this.isEditable)
        {
            return false;
        }

        return userConstraint || statusConstraint || assignmentConstraint;
    }

    public get newRequest(): boolean {
        // Null request considered as new.
        return !(this.vm?.requestId > 0);
    }

    public get canBeCanceled(): boolean {
        const userConstraint: boolean =
            this.user && this.user?.userId === this.vm?.requesterId;
        const statusConstraint: boolean =
            this.vm?.requestStatus < RequestStatus.RequestValidation;
        const assignmentConstraint: boolean =
            this.user?.userAssignments?.some(
                a =>
                    a.environmentCode === this.activeEnvironmentCode &&
                    (a.assignmentType === AssignmentType.Supply ||
                        a.assignmentType === AssignmentType.Development)
            ) === true;
        return userConstraint && statusConstraint && assignmentConstraint;
    }

    public get canBeSaveAsDraft(): boolean {
        return !this.readonly && this.vm?.requestStatus <= RequestStatus.Draft;
    }

    public get canBeSubmit(): boolean {
        return (!this.readonly || !this.readonlyTechnicalData) &&
        (this.vm?.requestStatus <= RequestStatus.Draft ||
            this.vm?.requestStatus === RequestStatus.RequestRejected ||
            this.vm?.requestStatus === RequestStatus.TechnicalData);
    }

    public get canBeUpdate(): boolean {
        return !this.canBeSubmit && this.vm?.requestStatus > RequestStatus.Draft;
    }

    public get bottlings(): IUserDefinedCode[] {
        return this.$store.state.jde.bottlings;
    }

    public get brands(): IUserDefinedCode[] {
        return this.$store.state.jde.brands;
    }

    public get buyers(): IUserDefinedContact[] {
        return this.$store.state.jde.buyers;
    }

    public get developers(): IUser[] {
        return this.$store.getters["app/developers"];
    }

    public get marketingManagers(): IUser[] {
        return this.$store.getters["app/marketingManagers"];
    }

    public get itemOrigins(): IItemOrigin[] {
        return this.$store.state.app.itemOrigins;
    }

    public get isDeclination(): boolean {
        return this.vm.itemOrigin === ItemOrigin.Declination;
    }

    public get classificationIsCapsuleQuality(): boolean {
        return (
            this.vm.classificationLevel2Code?.trimEquals(
                jdeCategories.classificationLevel2.capsules
            ) === true ||
            this.vm.classificationLevel3Code?.trimEquals(
                jdeCategories.classificationLevel3.aggloCork
            ) === true
        );
    }

    public get classificationLevel3IsClosure(): boolean {
        return (
            this.vm.classificationLevel3Code?.trimEquals(
                jdeCategories.classificationLevel3.aluminiumClosures
            ) === true
        );
    }

    public get classificationLevel3IsGlass(): boolean {
        return (
            this.vm.classificationLevel3Code?.trimEquals(
                jdeCategories.classificationLevel3.bulkGlass
            ) === true
        );
    }

    public get classificationLevel3IsCapsule(): boolean {
        const options: string[] = [
            jdeCategories.classificationLevel3.longShortAluminiumCapsules,
            jdeCategories.classificationLevel3.longShortTinCapsules,
            jdeCategories.classificationLevel3.longShortHeatShrinkCapsules
        ];

        return options.some(
            v => v?.trimEquals(this.vm.classificationLevel3Code) === true
        );
    }

    public get isNewItemMaster(): boolean {
        return !!this.vm.itemMasterCode;
    }

    public get itemMasters(): IItemMaster[] {
        return this.$store.state.jde.itemMasters;
    }

    public get suppliers(): IUserDefinedCode[] {
        const applicationSupplierCodes = this.supplierUsers
            .map(u =>
                u.userAssignments
                    .filter(a => a.assignmentType === AssignmentType.Supplier)
                    .map(a => a.roleCode)
            )
            .reduce((x, y) => x.concat(y), [])
            .filter((v, i, s) => s.indexOf(v) === i);

        return (
            this.$store.getters["jde/supplierCodes"]?.filter(s =>
                applicationSupplierCodes.some(c => c?.trimEquals(s.code))
            ) ?? []
        );
    }

    public get level2Classifications(): IUserDefinedCode[] {
        return this.$store.state.jde.level2Classifications;
    }

    public get level3Classifications(): IUserDefinedCode[] {
        return this.$store.state.jde.level3Classifications;
    }

    public get qualities(): IUserDefinedCode[] {
        return this.$store.state.jde.qualities;
    }

    public get packagings(): IUserDefinedCode[] {
        return this.$store.state.jde.packagings;
    }

    public get glassHues(): IUserDefinedCode[] {
        return this.$store.state.jde.glassHues;
    }

    public get materials(): IUserDefinedCode[] {
        return this.$store.state.jde.materials;
    }

    public get cartonPrintings(): IUserDefinedCode[] {
        return this.$store.state.jde.cartonPrintings;
    }

    public get cartonExternalBoards(): IUserDefinedCode[] {
        return this.$store.state.jde.cartonExternalBoards;
    }

    public get cartonTypes(): IUserDefinedCode[] {
        return this.$store.state.jde.cartonTypes;
    }

    public get capsuleQualities(): IUserDefinedCode[] {
        return this.$store.state.jde.capsuleQualities;
    }

    public get capsuleTypes(): IUserDefinedCode[] {
        return this.$store.state.jde.capsuleTypes;
    }

    public get capsuleLengths(): IUserDefinedCode[] {
        return this.$store.state.jde.capsuleLengths;
    }

    public get customsTypes(): IUserDefinedCode[] {
        return this.$store.state.jde.customsTypes;
    }

    public get defaultSupplier(): IUserDefinedCode {
        const defaultDevStep: IRequestDevStep = this.vm.devSteps?.find(
            ds => ds.defaultSupplier
        );
        return {
            code: defaultDevStep?.supplierCode ?? null,
            label: defaultDevStep?.supplierLabel ?? null
        };
    }

    public get vintages(): IUserDefinedCode[] {
        return this.$store.state.jde.vintages;
    }

    public get marketValidators(): IMarketValidator[] {
        return this.$store.state.jde.marketValidators;
    }

    public get sourcings(): IUserDefinedCode[] {
        return this.$store.state.jde.sourcings;
    }

    public get levelPremiumness(): IUserDefinedCode[] {
        return this.$store.state.jde.levelPremiumness;
    }

    public get certifications(): IUserDefinedCode[] {
        return this.$store.state.jde.certifications;
    }

    public get packaginIds(): IUserDefinedCode[] {
        return this.$store.state.jde.packaginIds;
    }

    public get closureSizes(): IUserDefinedCode[] {
        return this.$store.state.jde.closureSizes;
    }

    public get availableMarketValidators(): IMarketValidator[] {
        if (this.vm.packagingCode) {
            return (
                this.marketValidators?.filter(x =>
                    this.vm.packagingCode.trimEquals(x.code)
                ) ?? []
            );
        } else {
            return [];
        }
    }

    public get purchasingFamily(): IUserDefinedCode[] {
        return this.$store.state.jde.purchasingFamily;
    }

    public get purchasingSubFamily(): IUserDefinedCode[] {
        return this.$store.state.jde.purchasingSubFamily;
    }

    public get isCognacEnvironment(): boolean {
        return Environments.Cognac.trimContains(this.vm.environmentDisplayName);
    }

    public get originCountries(): IUserDefinedCode[] {
        return this.$store.state.jde.originCountries;
    }

    private get activeEnvironmentCode(): string {
        return this.$store.getters["app/activeEnvironment"]?.code;
    }

    private get supplierUsers(): IUser[] {
        return this.$store.getters["app/suppliers"];
    }

    public get measureUnits(): IUserDefinedCode[] {
        return this.$store.state.jde.measureUnits;
    }

    public get projectNames(): string[] {
        return this.$store.getters["app/projectNames"];
    }

    public get decorationTypes(): IUserDefinedCode[] {
        return this.$store.state.jde.decorationTypes;
    }

    public get labelTypes(): IUserDefinedCode[] {
        return this.$store.state.jde.labelTypes;
    }

    public get pvcFrees(): IUserDefinedCode[] {
        return this.$store.state.jde.pvcFree;
    }

    // Hook.
    @Watch("model")
    public createViewModel(): ICreationRequestViewModel {
        const vm = {
            ...this.model,
            devSteps: [...this.model.devSteps.map(ds => ({ ...ds }))],
            developerViewModel: this.getDeveloper(this.model),
            itemOriginViewModel: this.getItemOrigin(this.model),
            marketingManagerViewModel: this.getMarketingManager(this.model)
        };

        this.vm = vm;
        return vm;
    }

    public async created(): Promise<void> {
        this.loadingData = true;
        await this.loadData();
        this.loadingData = false;
    }

    public isClassificationLevel3CO2EmissionFactor(value: string): boolean {
        const options: string[] = [
            jdeCategories.classificationLevel3.boxAccessories,
            jdeCategories.classificationLevel3.bulkGlass,
            jdeCategories.classificationLevel3.cartonGiftBoxes,
            jdeCategories.classificationLevel3.crownClosures,
            jdeCategories.classificationLevel3.display,
            jdeCategories.classificationLevel3.divisions,
            jdeCategories.classificationLevel3.drySugar,
            jdeCategories.classificationLevel3.longShortAluminiumCapsules,
            jdeCategories.classificationLevel3.longShortHeatShrinkCapsules,
            jdeCategories.classificationLevel3.longShortTinCapsules,
            jdeCategories.classificationLevel3.metalGiftBoxes,
            jdeCategories.classificationLevel3.mountedCartonGiftBoxes,
            jdeCategories.classificationLevel3.mountedOtherGiftBoxes,
            jdeCategories.classificationLevel3.mountedWoodenGiftBoxes,
            jdeCategories.classificationLevel3.packAndClusters,
            jdeCategories.classificationLevel3.plasticClosures,
            jdeCategories.classificationLevel3.prettyGiftBoxes,
            jdeCategories.classificationLevel3.screwPlasticClosures,
            jdeCategories.classificationLevel3.shippingCases,
            jdeCategories.classificationLevel3.woodenGiftBoxes
        ];

        return value && options.some(v => v?.trimEquals(value) === true);
    }

    public isClassificationLevel3Material(value: string): boolean {
        const options: string[] = [
            jdeCategories.classificationLevel3.adhesivesBackLabels,
            jdeCategories.classificationLevel3.adhesivesLabels,
            jdeCategories.classificationLevel3.adhesivesMedallionLabels,
            jdeCategories.classificationLevel3.adhesivesNeckLabels,
            jdeCategories.classificationLevel3.all,
            jdeCategories.classificationLevel3.boxAccessories,
            jdeCategories.classificationLevel3.bulkGlass,
            jdeCategories.classificationLevel3.cartonGiftBoxes,
            jdeCategories.classificationLevel3.headOfBarTop,
            jdeCategories.classificationLevel3.metalGiftBoxes,
            jdeCategories.classificationLevel3.mountedCartonGiftBoxes,
            jdeCategories.classificationLevel3.mountedOtherGiftBoxes,
            jdeCategories.classificationLevel3.mountedWoodenGiftBoxes,
            jdeCategories.classificationLevel3.palletLabels,
            jdeCategories.classificationLevel3.plasticClosures,
            jdeCategories.classificationLevel3.screwPlasticClosures,
            jdeCategories.classificationLevel3.sleeve,
            jdeCategories.classificationLevel3.wetGlueBackLabels,
            jdeCategories.classificationLevel3.wetGlueLabels,
            jdeCategories.classificationLevel3.wetGlueNeckLabels,
            jdeCategories.classificationLevel3.woodenGiftBoxes,
            jdeCategories.classificationLevel3.display,
            jdeCategories.classificationLevel3.shippingCases
        ];

        return value && options.some(v => v?.trimEquals(value) === true);
    }

    public isClassificationLevel3Sourcing(value: string): boolean {
        const options: string[] = [
            jdeCategories.classificationLevel3.crownClosures,
            jdeCategories.classificationLevel3.headOfBarTop,
            jdeCategories.classificationLevel3.plasticClosures,
            jdeCategories.classificationLevel3.screwPlasticClosures
        ];

        return value && options.some(v => v?.trimEquals(value) === true);
    }

    public isClassificationLevel3CartonItem(value: string): boolean {
        const options: string[] = [
            jdeCategories.classificationLevel3.shippingCases,
            jdeCategories.classificationLevel3.display,
            jdeCategories.classificationLevel3.divisions
        ];

        return value && options.some(v => v?.trimEquals(value) === true);
    }

    public isClassificationLevel3ClosureSize(value: string): boolean {
        return (
            value &&
            value?.trimEquals(
                jdeCategories.classificationLevel3.aluminiumClosures
            ) === true
        );
    }

    public isClassificationLevel3CustomsType(value: string): boolean {
        const options: string[] = [
            jdeCategories.classificationLevel3.longShortAluminiumCapsules,
            jdeCategories.classificationLevel3.longShortTinCapsules
        ];

        return value && options.some(v => v?.trimEquals(value) === true);
    }

    public isClassificationLevel3Recycled(value: string): boolean {
        return (
            value &&
            value?.trimEquals(jdeCategories.classificationLevel3.bulkGlass) ===
                true
        );
    }

    public isClassificationLevel3LevelPremiumness(value: string): boolean {
        return (
            value &&
            value?.trimEquals(jdeCategories.classificationLevel3.bulkGlass) ===
                true
        );
    }

    public isClassificationLevel3Certification(value: string): boolean {
        const options: string[] = [
            jdeCategories.classificationLevel3.aggloCork,
            jdeCategories.classificationLevel3.cartonGiftBoxes,
            jdeCategories.classificationLevel3.display,
            jdeCategories.classificationLevel3.divisions,
            jdeCategories.classificationLevel3.drySugar,
            jdeCategories.classificationLevel3.headOfBarTop,
            jdeCategories.classificationLevel3.mountedCartonGiftBoxes,
            jdeCategories.classificationLevel3.mountedOtherGiftBoxes,
            jdeCategories.classificationLevel3.mountedWoodenGiftBoxes,
            jdeCategories.classificationLevel3.packAndClusters,
            jdeCategories.classificationLevel3.prettyGiftBoxes,
            jdeCategories.classificationLevel3.shippingCases,
            jdeCategories.classificationLevel3.woodenGiftBoxes
        ];

        return value && options.some(v => v?.trimEquals(value) === true);
    }

    public isClassificationLevel3Decoration(value: string): boolean {
        return (
            value &&
            value?.trimEquals(
                jdeCategories.classificationLevel3.decoratedBottle
            ) === true
        );
    }

    public isClassificationLevel3LabelType(value: string): boolean {
        return (
            value &&
            value?.trimEquals(
                jdeCategories.classificationLevel3.labelTypes
            ) === true
        );
    }

    public isShippingCasesOrWoodenGiftBoxes(value: string): boolean {
        const options: string[] = [
            jdeCategories.classificationLevel3.shippingCases,
            jdeCategories.classificationLevel3.woodenGiftBoxes
        ];

        return value && options.some(v => v?.trimEquals(value) === true);
    }

    public updateBuyer(value: IUserDefinedContact) {
        this.vm.buyerEmail = value.mail;
    }

    public updateDeveloper(value: IUser) {
        this.vm.developerId = value?.userId;
        this.vm.developerName = value?.name;
        this.vm.developerEmail = value?.email;
    }

    public updateItemOrigin(value: IItemOrigin) {
        this.vm.itemOrigin = value?.itemOriginId;
        this.vm.itemOriginDisplayName = value?.label;

        this.vm.needMarketValidation =
            value?.itemOriginId === ItemOrigin.Declination;
    }

    public updateHasMarketingExecDoc(value: boolean) {
        if (!value) {
            this.vm.marketingManagerViewModel = null;
            this.vm.marketingManagerId = null;
            this.vm.marketingManagerName = null;
            this.vm.marketingManagerEmail = null;
        }
    }

    public updateMarketingManager(value: IUser) {
        this.vm.marketingManagerId = value?.userId;
        this.vm.marketingManagerName = value?.name;
        this.vm.marketingManagerEmail = value?.email;
    }

    public updatePackaging(value: IUserDefinedCode) {
        this.vm.marketValidators = [];
    }

    // remarks: packaging values are not retrieved.
    public async updateItemMasterCode(value: string): Promise<void> {
        if (this.vm) {
            this.vm.capLengthCode = null;
            this.vm.capLengthLabel = null;
            this.vm.capQualityCode = null;
            this.vm.capQualityLabel = null;
            this.vm.capTypeCode = null;
            this.vm.capTypeLabel = null;
            this.vm.classificationLevel2Code = null;
            this.vm.classificationLevel2Label = null;
            this.vm.classificationLevel3Code = null;
            this.vm.classificationLevel3Label = null;
            this.vm.closureSizeCode = null;
            this.vm.closureSizeLabel = null;
            this.vm.co2EmissionFactor = null;
            this.vm.customsTypeCode = null;
            this.vm.customsTypeLabel = null;
            this.vm.decorationTypeCode = null;
            this.vm.decorationTypeLabel = null;
            this.vm.glassHueCode = null;
            this.vm.glassHueLabel = null;
            this.vm.glassSize = null;
            this.vm.height = null;
            this.vm.length = null;
            this.vm.levelPremiumnessCode = null;
            this.vm.levelPremiumnessLabel = null;
            this.vm.marketValidators = [];
            this.vm.materialCode = null;
            this.vm.materialLabel = null;
            this.vm.recycled = null;
            this.vm.sourcingCode = null;
            this.vm.sourcingLabel = null;
            this.vm.supplyTimePeriod = null;
            this.vm.weight = null;
            this.vm.width = null;
            this.vm.purchasingFamilyCode = null;
            this.vm.purchasingFamilyLabel = null;
            this.vm.purchasingSubFamilyCode = null;
            this.vm.purchasingSubFamilyLabel = null;
            this.vm.originCountryCode = null;
            this.vm.originCountryLabel = null;
            this.vm.mainMeasureUnitCode = null;
            this.vm.purchaseMeasureUnitCode = null;
        }

        // Cancel new item master flag.
        if (value) {
            this.vm.isNewItemMaster = false;
        }

        if (
            value &&
            !this.$store.state.jde.itemMasters?.some((i: IItemMaster) =>
                i.itemCode?.trimEquals(value)
            )
        ) {
            await this.$store.dispatch(
                "jde/getSingleItemMaster",
                this.getJdeParameters<string>(value.trim())
            );
        }

        // Overwrite request values.
        if (!this.readonly && value && this.vm) {
            const itemMaster: IItemMaster = this.$store.state.jde.itemMasters.find(
                (i: IItemMaster) => i.code.trimEquals(value)
            );

            // classificationLevel2.
            if (itemMaster.classificationLevel2) {
                const classificationLevel2: IUserDefinedCode = this.$store.state.jde.level2Classifications.find(
                    (i: IUserDefinedCode) =>
                        i.code.trimEquals(itemMaster.classificationLevel2)
                );
                this.vm.classificationLevel2Code = classificationLevel2?.code;
                this.vm.classificationLevel2Label = classificationLevel2?.label;
                if (
                    classificationLevel2?.code.trimEquals(
                        jdeCategories.classificationLevel2.capsules
                    )
                ) {
                    await Promise.all(this.loadCapsuleQualitiesData());
                    const capsuleQuality: IUserDefinedCode = this.$store.state.jde.capsuleQualities.find(
                        (i: IUserDefinedCode) =>
                            i.code.trimEquals(itemMaster.capQuality)
                    );
                    this.vm.capQualityCode = capsuleQuality?.code;
                    this.vm.capQualityLabel = capsuleQuality?.label;
                }
            }

            // classificationLevel3.
            if (itemMaster.classificationLevel3) {
                const classificationLevel3: IUserDefinedCode = this.$store.state.jde.level3Classifications.find(
                    (i: IUserDefinedCode) =>
                        i.code.trimEquals(itemMaster.classificationLevel3)
                );
                this.vm.classificationLevel3Code = classificationLevel3?.code;
                this.vm.classificationLevel3Label = classificationLevel3?.label;
                if (
                    classificationLevel3?.code.trimEquals(
                        jdeCategories.classificationLevel3.bulkGlass
                    )
                ) {
                    await Promise.all(this.loadGlassData());
                    const glassHue: IUserDefinedCode = this.$store.state.jde.glassHues.find(
                        (i: IUserDefinedCode) =>
                            i.code.trimEquals(itemMaster.glassHue)
                    );
                    this.vm.glassHueCode = glassHue?.code;
                    this.vm.glassHueLabel = glassHue?.label;
                    this.vm.glassSize = itemMaster.glassSize;
                }

                if (
                    classificationLevel3?.code.trimEquals(
                        jdeCategories.classificationLevel3.aluminiumClosures
                    )
                ) {
                    await Promise.all(this.loadClosureSize());
                    const closureSize: IUserDefinedCode = this.$store.state.jde.closureSizes.find(
                        (i: IUserDefinedCode) =>
                            i.code.trimEquals(itemMaster.closureSize)
                    );
                    this.vm.closureSizeCode = closureSize?.code;
                    this.vm.closureSizeLabel = closureSize?.label;
                }

                if (
                    this.isClassificationLevel3CartonItem(
                        classificationLevel3?.code
                    )
                ) {
                    await Promise.all(this.loadCartonItemData());
                    const cartonPrinting: IUserDefinedCode = this.$store.state.jde.cartonPrintings.find(
                        (i: IUserDefinedCode) =>
                            i.code.trimEquals(itemMaster.cartonPrinting)
                    );
                    this.vm.cartonPrintingCode = cartonPrinting?.code;
                    this.vm.cartonPrintingLabel = cartonPrinting?.label;
                    const cartonExternalBoard: IUserDefinedCode = this.$store.state.jde.cartonExternalBoards.find(
                        (i: IUserDefinedCode) =>
                            i.code.trimEquals(itemMaster.cartonExternalBoard)
                    );
                    this.vm.cartonExternalBoardCode = cartonExternalBoard?.code;
                    this.vm.cartonExternalBoardLabel =
                        cartonExternalBoard?.label;
                    if (
                        itemMaster.cartonWeight !== null &&
                        !isNaN(Number(itemMaster.cartonWeight))
                    ) {
                        this.vm.cartonWeight = Number(itemMaster.cartonWeight);
                    }

                    const cartonType: IUserDefinedCode = this.$store.state.jde.cartonTypes.find(
                        (i: IUserDefinedCode) =>
                            i.code.trimEquals(itemMaster.cartonType)
                    );
                    this.vm.cartonTypeCode = cartonType?.code;
                    this.vm.cartonTypeLabel = cartonType?.label;
                }

                if (
                    this.isClassificationLevel3CustomsType(
                        classificationLevel3?.code
                    )
                ) {
                    await Promise.all(this.loadCustomsTypes());
                    const customsType: IUserDefinedCode = this.$store.state.jde.customsType.find(
                        (i: IUserDefinedCode) =>
                            i.code.trimEquals(itemMaster.customsType)
                    );
                    this.vm.customsTypeCode = customsType?.code;
                    this.vm.customsTypeLabel = customsType?.label;
                }

                if (
                    classificationLevel3?.code.trimEquals(
                        jdeCategories.classificationLevel3
                            .longShortAluminiumCapsules
                    ) === true ||
                    classificationLevel3?.code.trimEquals(
                        jdeCategories.classificationLevel3
                            .longShortHeatShrinkCapsules
                    ) === true ||
                    classificationLevel3?.code.trimEquals(
                        jdeCategories.classificationLevel3.longShortTinCapsules
                    ) === true
                ) {
                    await Promise.all(this.loadCapsulesData());
                    const capsuleType: IUserDefinedCode = this.$store.state.jde.capsuleTypes.find(
                        (i: IUserDefinedCode) =>
                            i.code.trimEquals(itemMaster.capType)
                    );
                    this.vm.capTypeCode = capsuleType?.code;
                    this.vm.capTypeLabel = capsuleType?.label;
                    const capsuleLength: IUserDefinedCode = this.$store.state.jde.capsuleLengths.find(
                        (i: IUserDefinedCode) =>
                            i.code.trimEquals(itemMaster.capLength)
                    );
                    this.vm.capLengthCode = capsuleLength?.code;
                    this.vm.capLengthLabel = capsuleLength?.label;
                }

                if (
                    this.isClassificationLevel3CO2EmissionFactor(
                        classificationLevel3?.code
                    ) &&
                    itemMaster.co2EmissionFactor !== null &&
                    !isNaN(Number(itemMaster.co2EmissionFactor))
                ) {
                    this.vm.co2EmissionFactor = Number(
                        itemMaster.co2EmissionFactor
                    );
                }

                if (
                    this.isClassificationLevel3Material(
                        classificationLevel3?.code
                    )
                ) {
                    await Promise.all(this.loadMaterialsData());
                    const material: IUserDefinedCode = this.$store.state.jde.materials.find(
                        (i: IUserDefinedCode) =>
                            i.code.trimEquals(itemMaster.material)
                    );
                    this.vm.materialCode = material?.code;
                    this.vm.materialLabel = material?.label;
                }

                if (
                    this.isClassificationLevel3Sourcing(
                        classificationLevel3?.code
                    )
                ) {
                    await Promise.all(this.loadSourcingsData());
                    const sourcing: IUserDefinedCode = this.$store.state.jde.sourcings.find(
                        (i: IUserDefinedCode) =>
                            i.code.trimEquals(itemMaster.sourcing)
                    );
                    this.vm.sourcingCode = sourcing?.code;
                    this.vm.sourcingLabel = sourcing?.label;
                }

                if (
                    this.isClassificationLevel3LevelPremiumness(
                        classificationLevel3?.code
                    )
                ) {
                    await Promise.all(this.loadLevelPremiumnessData());
                    const levelPremiumness: IUserDefinedCode = this.$store.state.jde.levelPremiumness.find(
                        (i: IUserDefinedCode) =>
                            i.code.trimEquals(itemMaster.levelPremiumness)
                    );
                    this.vm.levelPremiumnessCode = levelPremiumness?.code;
                    this.vm.levelPremiumnessLabel = levelPremiumness?.label;
                }

                if (
                    this.isClassificationLevel3Certification(
                        classificationLevel3?.code
                    )
                ) {
                    await Promise.all(this.loadCertificationsData());
                    const certification: IUserDefinedCode = this.$store.state.jde.certifications.find(
                        (i: IUserDefinedCode) =>
                            i.code.trimEquals(itemMaster.certification)
                    );
                    this.vm.certificationCode = certification?.code;
                    this.vm.certificationLabel = certification?.label;
                }

                if (this.isClassificationLevel3Decoration(classificationLevel3?.code)) {
                    await Promise.all(this.loadDecorationTypes());
                    const decorationType: IUserDefinedCode = this.$store.state.jde.decorationTypes.find(
                        (i: IUserDefinedCode) =>
                            i.code.trimEquals(itemMaster.decorationType)
                    );
                    this.vm.decorationTypeCode = decorationType?.code;
                    this.vm.decorationTypeLabel = decorationType?.label;
                }

                if (this.isClassificationLevel3LabelType(classificationLevel3?.code)) {
                    await Promise.all(this.loadLabelTypes());
                    const labelType: IUserDefinedCode = this.$store.state.jde.labelTypes.find((lt: IUserDefinedCode) =>
                        lt.code.trimEquals(itemMaster.labelType)
                    );
                    this.vm.labelTypeCode = labelType?.code;
                    this.vm.labelTypeLabel = labelType?.label;
                }
            }

            // purchasingFamily.
            if (itemMaster.purchasingFamily) {
                const purchasingFamily: IUserDefinedCode = this.purchasingFamily.find(
                    (i: IUserDefinedCode) =>
                        i.code.trimEquals(itemMaster.purchasingFamily)
                );
                this.vm.purchasingFamilyCode = purchasingFamily?.code;
                this.vm.purchasingFamilyLabel = purchasingFamily?.label;
            }

            // purchasingSubFamily.
            if (itemMaster.purchasingSubFamily) {
                const purchasingSubFamily: IUserDefinedCode = this.purchasingSubFamily.find(
                    (i: IUserDefinedCode) =>
                        i.code.trimEquals(itemMaster.purchasingSubFamily)
                );
                this.vm.purchasingSubFamilyCode = purchasingSubFamily?.code;
                this.vm.purchasingSubFamilyLabel = purchasingSubFamily?.label;
            }

            // originCountry.
            if (itemMaster.originCountry) {
                const originCountry: IUserDefinedCode = this.originCountries.find(
                    (i: IUserDefinedCode) =>
                        i.code.trimEquals(itemMaster.originCountry)
                );
                this.vm.originCountryCode = originCountry?.code;
                this.vm.originCountryLabel = originCountry?.label;
            }

            // length.
            if (itemMaster.length !== null && !isNaN(itemMaster.length)) {
                this.vm.length = itemMaster.length;
            }

            // width.
            if (itemMaster.width !== null && !isNaN(itemMaster.width)) {
                this.vm.width = itemMaster.width;
            }

            // height.
            if (itemMaster.height !== null && !isNaN(itemMaster.height)) {
                this.vm.height = itemMaster.height;
            }

            // weight.
            if (itemMaster.weight !== null && !isNaN(itemMaster.weight)) {
                this.vm.weight = itemMaster.weight;
            }

            // mainMeasureUnit.
            if (itemMaster.mainMeasureUnit && this.isCognacEnvironment) {
                const mainMeasureUnit: IUserDefinedCode = this.measureUnits.find(
                    (i: IUserDefinedCode) =>
                        i.code.trimEquals(itemMaster.mainMeasureUnit)
                );
                this.vm.mainMeasureUnitCode = mainMeasureUnit?.code;
                this.vm.mainMeasureUnitLabel = mainMeasureUnit?.label;
            }

            // purchaseMeasureUnit.
            if (itemMaster.purchaseMeasureUnit && this.isCognacEnvironment) {
                const purchaseMeasureUnit: IUserDefinedCode = this.measureUnits.find(
                    (i: IUserDefinedCode) =>
                        i.code.trimEquals(itemMaster.purchaseMeasureUnit)
                );
                this.vm.purchaseMeasureUnitCode = purchaseMeasureUnit?.code;
                this.vm.purchaseMeasureUnitLabel = purchaseMeasureUnit?.label;
            }

            // SupplyTimePeriod.
            if (itemMaster.supplyTimePeriod !== null &&
                !isNaN(itemMaster.supplyTimePeriod)) {
                this.vm.supplyTimePeriod = itemMaster.supplyTimePeriod;
            }

            // Pvc free.
            if (itemMaster.pvcFree !== null &&
                !isNaN(itemMaster.supplyTimePeriod)) {
                this.vm.supplyTimePeriod = itemMaster.supplyTimePeriod;
            }

            // numberOfUnitsOnPalletReceipted.
            if (itemMaster.numberOfUnitsOnPalletReceipted !== null && !isNaN(Number(itemMaster.numberOfUnitsOnPalletReceipted))) {
                this.vm.numberOfUnitsOnPalletReceipted = Number(itemMaster.numberOfUnitsOnPalletReceipted);
            }

            // numberOfUnitsPerPack.
            if (itemMaster.numberOfUnitsPerPack !== null && !isNaN(Number(itemMaster.numberOfUnitsPerPack))) {
                this.vm.numberOfUnitsPerPack = Number(itemMaster.numberOfUnitsPerPack);
            }
        }
    }

    public updateSupplier(supplier: IUserDefinedCode) {
        if (!this.vm.devSteps) {
            this.vm.devSteps = [];
        }

        const defaultDevStep: IRequestDevStep = this.vm.devSteps.find(
            ds => ds.defaultSupplier
        );
        if (supplier) {
            const supplierDevStepExists: IRequestDevStep = this.vm.devSteps.find(
                ds => ds.supplierCode.localeCompare(supplier.code) === 0
            );
            if (!defaultDevStep && !supplierDevStepExists) {
                const devStep = RequestService.createDevStep(
                    this.vm.requestId,
                    supplier.code,
                    supplier.label
                );
                devStep.defaultSupplier = true;
                devStep.requestDevStepStatus =
                    RequestStatus.DevelopmentDocumentPending;
                this.vm.devSteps.push(devStep);
            } else if (defaultDevStep) {
                const index: number = this.vm.devSteps.indexOf(defaultDevStep);
                this.vm.devSteps[index] = RequestService.createDevStep(
                    this.vm.requestId,
                    supplier.code,
                    supplier.label
                );
                this.vm.devSteps[index].defaultSupplier = true;
                this.vm.devSteps[index].requestDevStepStatus =
                    RequestStatus.DevelopmentDocumentPending;
            } else {
                if (defaultDevStep) {
                    defaultDevStep.defaultSupplier = false;
                }

                supplierDevStepExists.defaultSupplier = true;
            }
        } else {
            this.vm.devSteps = this.vm.devSteps.filter(
                ds => !ds.defaultSupplier
            );
        }
    }

    public updateClassificationLevel2(value: IUserDefinedCode) {
        if (
            value?.code?.trimEquals(
                jdeCategories.classificationLevel2.capsules
            ) === true
        ) {
            this.loadCapsuleQualitiesData();
        } else {
            this.vm.capQualityCode = null;
            this.vm.capQualityLabel = null;
        }
    }

    public updateClassificationLevel3(value: IUserDefinedCode) {
        if (
            value?.code?.trimEquals(
                jdeCategories.classificationLevel3.aluminiumClosures
            ) === true
        ) {
            this.loadClosureSize();
        } else {
            this.vm.closureSizeCode = null;
            this.vm.closureSizeLabel = null;
        }

        if (
            value?.code?.trimEquals(
                jdeCategories.classificationLevel3.bulkGlass
            ) === true
        ) {
            this.loadGlassData();
        } else {
            this.vm.glassHueCode = null;
            this.vm.glassHueLabel = null;
            this.vm.glassSize = null;
        }

        if (this.isClassificationLevel3CartonItem(value?.code)) {
            this.loadCartonItemData();
        } else {
            this.vm.cartonPrintingCode = null;
            this.vm.cartonPrintingLabel = null;
            this.vm.cartonExternalBoardCode = null;
            this.vm.cartonExternalBoardLabel = null;
            this.vm.cartonWeight = null;
            this.vm.cartonTypeCode = null;
            this.vm.cartonTypeLabel = null;
        }

        if (
            value?.code?.trimEquals(
                jdeCategories.classificationLevel3.longShortAluminiumCapsules
            ) === true ||
            value?.code?.trimEquals(
                jdeCategories.classificationLevel3.longShortHeatShrinkCapsules
            ) === true ||
            value?.code?.trimEquals(
                jdeCategories.classificationLevel3.longShortTinCapsules
            ) === true
        ) {
            this.loadCapsulesData();
        } else {
            this.vm.capLengthCode = null;
            this.vm.capLengthLabel = null;
            this.vm.capTypeCode = null;
            this.vm.capTypeLabel = null;
        }

        if (!this.isClassificationLevel3CO2EmissionFactor(value?.code)) {
            this.vm.co2EmissionFactor = null;
        }

        if (this.isClassificationLevel3Material(value?.code)) {
            this.loadMaterialsData();
        } else {
            this.vm.materialCode = null;
            this.vm.materialLabel = null;
        }

        if (this.isClassificationLevel3Sourcing(value?.code)) {
            this.loadSourcingsData();
        } else {
            this.vm.sourcingLabel = null;
            this.vm.sourcingCode = null;
        }

        if (!this.isClassificationLevel3Recycled(value?.code)) {
            this.vm.recycled = null;
        }

        if (this.isClassificationLevel3LevelPremiumness(value?.code)) {
            this.loadLevelPremiumnessData();
        } else {
            this.vm.levelPremiumnessCode = null;
            this.vm.levelPremiumnessLabel = null;
        }

        if (this.isClassificationLevel3Certification(value?.code)) {
            this.loadCertificationsData();
        } else {
            this.vm.certificationLabel = null;
            this.vm.certificationLabel = null;
        }

        if (
            this.isShippingCasesOrWoodenGiftBoxes(value?.code) &&
            this.isCognacEnvironment
        ) {
            this.vm.needPalletizationPlan = true;
        } else {
            this.vm.needPalletizationPlan = false;
            this.vm.grossWeightCU = null;
            this.vm.heightCU = null;
            this.vm.lengthCU = null;
            this.vm.weightCU = null;
            this.vm.widthCU = null;
        }

        if (this.classificationIsCapsuleQuality) {
            this.loadCapsuleQualitiesData();
        } else {
            this.vm.capQualityCode = null;
            this.vm.capQualityLabel = null;
        }

        if (this.isClassificationLevel3Decoration(value?.code)) {
            this.loadDecorationTypes();
        } else {
            this.vm.decorationTypeCode = null;
            this.vm.decorationTypeLabel = null;
        }

        if (this.isClassificationLevel3CustomsType(value?.code)) {
            this.loadCustomsTypes();
        } else {
            this.vm.customsTypeCode = null;
            this.vm.customsTypeLabel = null;
        }

        if (this.isClassificationLevel3LabelType(value?.code)) {
            this.loadLabelTypes();
        } else {
            this.vm.labelTypeCode = null;
            this.vm.labelTypeLabel = null;
        }
    }

    public async cancelRequest(): Promise<void> {
        if (!this.newRequest) {
            return await this.createOrUpdateRequest(RequestStatus.Canceled);
        } else {
            this.$router.push("/");
        }
    }

    public async submitDraftRequest(): Promise<void> {
        return await this.createOrUpdateRequest(RequestStatus.Draft);
    }

    public async submitRequest(): Promise<void> {
        const status: RequestStatus =
            this.vm.requestStatus >= RequestStatus.TechnicalData
                ? RequestStatus.Development
                : RequestStatus.RequestValidation;
        return await this.createOrUpdateRequest(status);
    }

    public async updateRequest(): Promise<void>
    {
        this.pendingAction = true;
        if (this.validateBeforeSubmit()) {
            const response: any = await this.$store.dispatch(
                "requests/updateRequestWithoutWorkflow",
                this.vm
            );
            if (response) {
                const request: IRequest = response.data;
                this.isEditable = false;
                this.$emit("update:isEditButtonVisible", true);
                this.$emit("updateRequest");
                this.vm.lastRequestUpdate = request.lastRequestUpdate;
            }
        } else {
            NotificationService.showError("Le formulaire contient des erreurs");
        }

        this.pendingAction = false;
    }

    public setDefaultNumberToNull(event: Event & { target: HTMLInputElement }) {
        if (event.target.value === "") {
            this.$set(this.vm, event.target.id, null);
        }
    }

    public async editRequest() {
        this.isEditable = true;
        this.$emit("update:isEditButtonVisible", false);
        this.loadData();
    }

    private async loadData(): Promise<void> {
        const promises: Promise<any>[] = [];
        if (!this.readonly) {
            if (!this.$store.state.app.usersLoaded) {
                promises.push(this.$store.dispatch("app/getUsers"));
            }

            if (this.$store.state.app.projectNames?.length < 1) {
                promises.push(this.$store.dispatch("app/getProjectNames"));
            }

            if (this.$store.state.app.itemOrigins?.length < 1) {
                promises.push(this.$store.dispatch("app/getItemOrigins"));
            }

            const supplyChainsToLoad = [
                SupplyChainItemType.Bottlings,
                SupplyChainItemType.Brands,
                SupplyChainItemType.Qualities,
                SupplyChainItemType.Level2Classifications,
                SupplyChainItemType.Level3Classifications,
                SupplyChainItemType.Packagings,
                SupplyChainItemType.Vintages,
                SupplyChainItemType.PurchasingFamily,
                SupplyChainItemType.PurchasingSubFamily,
                SupplyChainItemType.OriginCountries
            ];

            for (const s of supplyChainsToLoad) {
                if (
                    !(this.$store.state.jde.loadedSupplyChains as any).find(
                        (x: SupplyChainItemType) => x === s
                    )
                ) {
                    promises.push(
                        this.$store.dispatch(
                            "jde/getSupplyChains",
                            this.getJdeParameters(s)
                        )
                    );
                }
            }

            const purchasesToLoad = [
                PurchaseItemType.Buyers
            ];

            for (const p of purchasesToLoad) {
                if (
                    !(this.$store.state.jde.loadedPurchases as any).find(
                        (x: PurchaseItemType) => x === p
                    )
                ) {
                    promises.push(
                        this.$store.dispatch(
                            "jde/getPurchases",
                            this.getJdeParameters(p)
                        )
                    );
                }
            }

            if (this.$store.state.jde.marketValidators?.length < 1) {
                promises.push(
                    this.$store.dispatch(
                        "jde/getMarketValidators",
                        this.activeEnvironmentCode
                    )
                );
            }

            if (this.$store.state.jde.itemMasters?.length < 1) {
                promises.push(
                    this.$store.dispatch(
                        "jde/getItemMasters",
                        this.activeEnvironmentCode
                    )
                );
            }

            if (this.$store.state.jde.suppliers?.length < 1) {
                promises.push(
                    this.$store.dispatch(
                        "jde/getSuppliers",
                        this.activeEnvironmentCode
                    )
                );
            }
        }

        if (!this.readonlyTechnicalData) {
            promises.push(...this.loadPvcFree());

            if (this.classificationIsCapsuleQuality) {
                promises.push(...this.loadCapsuleQualitiesData());
            }

            if (this.classificationLevel3IsClosure) {
                promises.push(...this.loadClosureSize());
            }

            if (this.classificationLevel3IsGlass) {
                promises.push(...this.loadGlassData());
            }

            if (this.isClassificationLevel3CartonItem(this.vm.classificationLevel3Code)) {
                promises.push(...this.loadCartonItemData());
            }

            if (this.classificationLevel3IsCapsule) {
                promises.push(...this.loadCapsulesData());
            }

            if (this.isClassificationLevel3Sourcing(this.vm.classificationLevel3Code)) {
                promises.push(...this.loadSourcingsData());
            }

            if (this.isClassificationLevel3Certification(this.vm.classificationLevel3Code)) {
                promises.push(...this.loadCertificationsData());
            }

            if (this.isClassificationLevel3Material) {
                promises.push(...this.loadMaterialsData());
            }

            if (this.isClassificationLevel3Decoration(this.vm.classificationLevel3Code)) {
                promises.push(...this.loadDecorationTypes());
            }

            if (this.isClassificationLevel3LabelType(this.vm.classificationLevel3Code)) {
                promises.push(...this.loadLabelTypes());
            }

            if (this.isClassificationLevel3CustomsType(this.vm.classificationLevel3Code)) {
                promises.push(...this.loadCustomsTypes());
            }

            if (this.isClassificationLevel3LevelPremiumness(this.vm.classificationLevel3Code)) {
                promises.push(...this.loadLevelPremiumnessData());
            }
        }

        await Promise.all(promises);
    }

    private getJdeParameters<T>(value: T): IActionEnvironmentParameter<T> {
        return {
            environmentCode: this.activeEnvironmentCode,
            value
        };
    }

    private async createOrUpdateRequest(
        status: RequestStatus = null
    ): Promise<void> {
        this.pendingAction = true;
        if (this.validateBeforeSubmit()) {
            const currentStatus = this.vm.requestStatus;
            if (status) {
                this.vm.requestStatus = status;
            }

            const response: IRequest = await this.$store.dispatch(
                "requests/createOrUpdateRequest",
                this.vm
            );
            if (response) {
                this.$router.push("/");
            } else {
                this.vm.requestStatus = currentStatus;
            }
        } else {
            NotificationService.showError("Le formulaire contient des erreurs");
        }

        this.pendingAction = false;
    }

    private getDeveloper(model: IRequest): IUser {
        if (model?.developerId > 0) {
            return {
                userId: model.developerId,
                email: model.developerEmail,
                isB2C: null,
                name: model.developerName,
                uniqueId: null,
                userAssignments: null,
                locked: false,
                defaultEnvironmentId: null,
                defaultEnvironmentCode: null,
                defaultEnvironmentDisplayName: null
            };
        } else {
            return null;
        }
    }

    private getItemOrigin(model: IRequest): IItemOrigin {
        if (model?.itemOrigin > 0) {
            return {
                itemOriginId: model.itemOrigin,
                label: model.itemOriginDisplayName
            };
        } else {
            return null;
        }
    }

    private getMarketingManager(model: IRequest): IUser {
        if (model?.marketingManagerId > 0) {
            return {
                userId: model.marketingManagerId,
                email: model.marketingManagerEmail,
                isB2C: null,
                name: model.marketingManagerName,
                uniqueId: null,
                userAssignments: null,
                locked: false,
                defaultEnvironmentId: null,
                defaultEnvironmentCode: null,
                defaultEnvironmentDisplayName: null
            };
        } else {
            return null;
        }
    }

    private validateBeforeSubmit(): boolean {
        this.$v.vm.$touch();
        return !this.$v.vm.$invalid;
    }

    private loadClosureSize(): Promise<any>[] {
        return this.loadPurchasesData(PurchaseItemType.ClosureSizes);
    }

    private loadCustomsTypes(): Promise<any>[] {
        return this.loadSupplyChainData(SupplyChainItemType.CustomsTypes);
    }

    private loadGlassData(): Promise<any>[] {
        return this.loadPurchasesData(PurchaseItemType.GlassHues);
    }

    private loadMaterialsData(): Promise<any>[] {
        return this.loadPurchasesData(PurchaseItemType.Materials);
    }

    private loadCartonItemData(): Promise<any>[] {
        return this.loadPurchasesData(
            PurchaseItemType.CartonPrintings,
            PurchaseItemType.CartonExternalBoards,
            PurchaseItemType.Certifications,
            PurchaseItemType.CartonTypes
        );
    }

    private loadPvcFree(): Promise<any>[] {
        return this.loadPurchasesData(PurchaseItemType.PvcFree);
    }

    private loadCapsuleQualitiesData(): Promise<any>[] {
        return this.loadPurchasesData(PurchaseItemType.CapsuleQualities);
    }

    private loadCapsulesData(): Promise<any>[] {
        return this.loadPurchasesData(
            PurchaseItemType.CapsuleLengths,
            PurchaseItemType.CapsuleTypes
        );
    }

    private loadSourcingsData(): Promise<any>[] {
        return this.loadPurchasesData(PurchaseItemType.Sourcings);
    }

    private loadLevelPremiumnessData(): Promise<any>[] {
        return this.loadPurchasesData(PurchaseItemType.LevelPremiumness);
    }

    private loadCertificationsData(): Promise<any>[] {
        return this.loadPurchasesData(PurchaseItemType.Certifications);
    }

    private loadDecorationTypes(): Promise<any>[] {
        return this.loadPurchasesData(PurchaseItemType.DecorationTypes);
    }

    private loadLabelTypes(): Promise<any>[] {
        return this.loadPurchasesData(PurchaseItemType.LabelTypes);
    }

    private loadPurchasesData(...values: PurchaseItemType[]): Promise<any>[] {
        const promises: Promise<any>[] = [];
        if (values && values.length > 0) {
            for (const p of values) {
                if (
                    !(this.$store.state.jde.loadedPurchases as any).find(
                        (x: PurchaseItemType) => x === p
                    )
                ) {
                    promises.push(
                        this.$store.dispatch(
                            "jde/getPurchases",
                            this.getJdeParameters(p)
                        )
                    );
                }
            }
        }

        return promises;
    }

    private loadSupplyChainData(
        ...values: SupplyChainItemType[]
    ): Promise<any>[] {
        const promises: Promise<any>[] = [];
        if (values && values.length > 0) {
            for (const s of values) {
                if (
                    !(this.$store.state.jde.loadedSupplyChains as any).find(
                        (x: SupplyChainItemType) => x === s
                    )
                ) {
                    promises.push(
                        this.$store.dispatch(
                            "jde/getSupplyChains",
                            this.getJdeParameters(s)
                        )
                    );
                }
            }
        }

        return promises;
    }
}
