import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { IMailContent, IRequestStatus, IUser, IActionEnvironmentParameter } from "@/models";
import { validations } from "./validations";
import { IManageEmailsViewModel } from "./IManageEmailsViewModel";
import { NotificationService } from "@/services";

@Component({
    name: "manage-emails",
    validations,
})
export default class ManageEmails extends Vue {
    public vm: IManageEmailsViewModel[];
    public loadingData: boolean;

    public constructor() {
        super();

        this.vm = [];
        this.loadingData = false;
    }

    public get mailContents(): IMailContent[] {
        return this.$store.state.app.mailContents;
    }

    public get requestStatus(): IRequestStatus[] {
        return this.$store.state.app.requestStatus;
    }

    private get activeEnvironmentCode(): string {
        return this.$store.getters["app/activeEnvironment"]?.code;
    }

    // Hook.
    @Watch("mailContents")
    public onMailContentChanges(newVal: IMailContent[], oldVal: IMailContent[]): void {
        this.vm = this.createViewModel(newVal);
    }

    public async created(): Promise<void> {
        this.loadingData = true;
        await this.loadData();
        this.vm = this.createViewModel(this.mailContents);
        this.loadingData = false;
    }

    public async updateMailContent(mailContent: IMailContent, id: string, event: any): Promise<void> {
        if ((mailContent[id] as string).localeCompare(event.target.value) !== 0) {
            this.$set(mailContent, id, event.target.value);
            if (this.validateBeforeSubmit()) {
                const response: IUser = await this.$store.dispatch("app/updateMailContent", this.getJdeParameters(mailContent));
                if (response) {
                    NotificationService.showSuccess("Contenu de l'email sauvegardé");
                }
            } else {
                NotificationService.showError("Le formulaire contient des erreurs");
            }
        }
    }

    private getJdeParameters<T>(value: T): IActionEnvironmentParameter<T> {
        return {
            environmentCode: this.activeEnvironmentCode,
            value,
        };
    }

    private async loadData(): Promise<void> {
        const promises: Promise<any>[] = [];
        if (this.$store.state.app.mailContents?.length < 1) {
            promises.push(this.$store.dispatch("app/getMailContents", this.activeEnvironmentCode));
        }

        if (this.$store.state.app.requestStatus?.length < 1) {
            promises.push(this.$store.dispatch("app/getRequestStatus"));
        }

        await Promise.all(promises);
    }

    private createViewModel(mailContents: IMailContent[]): IManageEmailsViewModel[] {
        const vm:  IManageEmailsViewModel[] = [
            ...mailContents.map(m => ({
                ...m,
                requestStatusDisplayName: this.requestStatus.find(rs => rs.requestStatusId === m.requestStatus)?.label,
                approvedStatusDisplayName: this.requestStatus.find(rs => rs.requestStatusId === m.approvedStatus)?.label,
            })),
        ];

        vm.sort((a: IManageEmailsViewModel, b: IManageEmailsViewModel)  => a.requestStatus - b.requestStatus);
        return vm;
    }

    private validateBeforeSubmit(): boolean {
        this.$v.vm.$touch();
        return !this.$v.vm.$invalid;
    }
}
